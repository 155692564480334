import Vue from "vue";

Vue.mixin({
    methods: {
        capitalizeFirstLetter(str) {
            return str.toUpperCase();
        },
        HumanReadableDateFormat(date, onlyDate = false) {
            const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "June",
            "July", "Aug", "Sept", "Oct", "Nov", "Dec"
            ];
            let dt = new Date(date);
            if (dt == "Invalid Date") {
                return "-";
            }
            let day = ("0" + dt.getDate()).slice(-2);
            let month = dt.getMonth();
            let year = dt.getFullYear();
            let hour = ("0" + dt.getHours()).slice(-2);
            let minute = ("0" + dt.getMinutes()).slice(-2);
            let seconds = ("0" + dt.getSeconds()).slice(-2);
            if (onlyDate) {
                return day + " " + monthNames[month] + " " + year;
            } else {
                return day + " " + monthNames[month] + " " + year + " " + hour + ":" + minute + ":" + seconds;
            }
        },
        ReplaceString(str, split,join) {
            if (str) {
                return str.split(split).join(join);
            } else {
                return str;
            }
        },
        WhiteLabellingTitle() {
            return "Package Disabler";
        },
        GetServerBaseUrl() {
            return process.env.VUE_APP_API_BASE_URL
        },
        formatCurrency(amount) {
            let formatter = new Intl.NumberFormat('en-GB', {
                style: 'currency',
                currency: 'GBP',
                minimumFractionDigits: 2
            })
            return formatter.format(amount);
        },
        copyContent(content) {
            navigator.clipboard.writeText(content);
            this.$toast.success("text copied");
        },
        appNameFull(appName) {
            let fullAppNameArray = {"PD": "Package Disabler Subscription", "PDOT": "Package Disabler Onetime", "PDOW": "PD Owner APP Subscription", "PDOWOT": "PD Owner APP Onetime", "PC": "Power Cleaner Subscription", "PCOT": "Power Cleaner Onetime",
            "UB": "URL Blocker Subscription", "UBOT": "URL Blocker No Subscription", "KM": "Key Mapper Subscription", "KMOT": "Key Mapper No Subscription",
            "POB": "Power On Boot", "POBOT": "Power On Boot No Subscription", "SL": "Super Lock Subscription", "SLOT": "Super Lock Onetime"
            }
            return fullAppNameArray[appName];
        },
    }
})