<template>
    <div>
        <p><b>Email : {{ customer.email }}</b></p>
        <md-card>
            <md-card-content>
                <form ref="createCustomerForm">
                    <div class="row">
                        <div class="col">
                            <h5><b>Choose a product?</b></h5>
                            <md-radio v-for="price in prices" :key="price.id" v-model="priceId" :value="price._id"><p :style="{ color: 'black' }">{{ price.name }}</p></md-radio>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <md-field>
                                <label>Existing Qty</label>
                                <md-input type="number" v-model="existingQty" disabled></md-input>
                            </md-field>
                        </div>
                        <div class="col">
                            <md-field>
                                <label>Qty</label>
                                <md-input type="number" v-model="qty"></md-input>
                            </md-field>
                        </div>
                        <div class="col">
                            <md-field>
                                <label>Amount</label>
                                <md-input type="text" v-model="amount"></md-input>
                            </md-field>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <md-field>
                                <label>Note</label>
                                <md-input type="Text" v-model="note"></md-input>
                            </md-field>
                        </div>
                        <div class="col">
                            <md-button class="md-success" :disabled="isDisabled" @click="createPayment()">Save
                                <span v-if="isDisabled"><i class="fa fa-spinner fa-spin"></i></span>
                            </md-button>
                        </div>
                    </div>
                </form>
            </md-card-content>
        </md-card>
        <md-card>
            <md-card-content>
                <div class="md-layout">
                    <div class="md-layout-item md-size-100">
                        <div :style="{ 'display': 'flex' }">
                            <p><b>Email:</b> {{ customer.email }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>
                            <p><b>Total Purchased Devices:</b> {{ totalPurchasedDevices }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>
                            <p><b>Total Enrolled Devices:</b> {{ totalEnrolledDevices }}</p>
                        </div>
                    </div>
                    <div class="md-layout-item md-size-100">
                        <md-table v-if="tableData.length > 0 " v-model="tableData" table-header-color="green">
                            <md-table-row slot="md-table-row" slot-scope="{ item }">            
                                <md-table-cell md-label="Name">{{ item.productName }}</md-table-cell>
                                <md-table-cell md-label="Type">
                                    <p class="badge-primary" v-if="item.subscriptionType == 'recurring'">Recurring</p>
                                    <p class="badge-warning" v-else>Onetime</p>
                                    <small v-if="item.message"> <b>{{ item.message }}</b></small>
                                </md-table-cell>
                                <md-table-cell md-label="Qty">
                                  <b> {{ item.qty }} License </b>
                                  <br><span v-if="item.priceId" :class="'badge-'+item.priceId.identifier">{{ appNameFull(item.priceId.identifier) }}</span>
                                </md-table-cell>
                                <md-table-cell md-label="Amount">
                                  <b>€{{ item.totalAmount }}</b> 
                                  <span v-if="item.coupon" class="text-info">
                                      <!--<b v-if="item.discountAmount > 0"><br>Discount : €{{ item.discountAmount }} </b>-->
                                      <b><br>CouponCode : {{ item.coupon }} </b>
                                  </span>
                                </md-table-cell>
                                <md-table-cell md-label="Status">
                                    <p class="badge-success" v-if="item.status == 'Success'">{{ item.status }}</p>
                                    <p class="badge-danger" v-else-if="item.status == 'Failed'">{{ item.status }}</p>
                                    <p class="badge-primary" v-else>{{ item.status }}</p>
                                    <small v-if="item.refundBy"><b>Refund By : {{ item.refundBy }}</b></small>
                                </md-table-cell>
                                <md-table-cell md-label="Expiry At">
                                  <p v-if="item.expiryAt">{{ HumanReadableDateFormat(item.expiryAt, true) }}</p>
                                  <p v-else>-</p>
                                </md-table-cell>
                                <md-table-cell md-label="Created On">{{ HumanReadableDateFormat(item.created_at, true) }}</md-table-cell>
                                <md-table-cell md-label="Actions">
                                    <div>                    
                                        <md-button v-if="item.status == 'Success'" class="md-just-icon md-success md-simple" @click="openUpdateSubscriptionModal(item)"><md-icon title="Edit">edit</md-icon></md-button>&nbsp;
                                      <md-button v-if="item.status == 'Success' && item.isOfflinePayment == false" class="md-just-icon md-primary md-simple" @click="$router.push({ path: 'admin-customer-refund-payment', query: { id: item._id, customerId: customerId  }})"><md-icon title="Refund">assignment_return</md-icon></md-button>&nbsp;
                                    </div>
                                </md-table-cell>
                            </md-table-row>
                        </md-table>
                        <h4 v-else>No data found.</h4>
                    </div>
                </div>
            </md-card-content>
        </md-card>
        <template>
        <modal v-if="updateSubscriptionModal" @close="updateSubscriptionModal = false" class="large-modal">
          <template slot="header">
            <h4 class="modal-title">{{ currentUpdateSubscriptionDetail.productName }}</h4>
            <md-button
              style="height: 25px" class="md-simple md-just-icon md-round modal-default-button"
              @click="updateSubscriptionModal = false"><md-icon>clear</md-icon></md-button>
          </template>
          <template slot="body">
            <p><b>Product Name:</b> {{ currentUpdateSubscriptionDetail.productName }}</p>
            <form>
                <div class="row">
                    <div class="col">
                        <md-field>
                            <label>Purchased Qty</label>
                            <md-input type="number" :value="currentUpdateSubscriptionDetail.qty" disabled></md-input>
                        </md-field>
                    </div>
                    <div class="col">
                        <md-field>
                            <label>Update Qty</label>
                            <md-input type="number" v-model="updatedQty"></md-input>
                        </md-field>
                    </div>
                </div>
            </form>
            <md-table v-if="productWiseEnrolledDeviecs.length > 0 " v-model="productWiseEnrolledDeviecs" table-header-color="green">
                <md-table-row slot="md-table-row" slot-scope="{ item }">            
                    <md-table-cell md-label="Name">{{ item.deviceName }}</md-table-cell>
                    <md-table-cell md-label="Serial Number">{{ item.serialNumber }}</md-table-cell>
                    <md-table-cell md-label="Actions">
                        <div>
                            <md-button class="md-just-icon md-danger md-simple" @click="deleteDevice(item._id)"><md-icon>close</md-icon></md-button>
                        </div>
                    </md-table-cell>
                </md-table-row>
            </md-table>
          </template>
          <template slot="footer">
            <md-button class="md-success m-1" @click="updateSubscription()" :disabled="isDisabled">Update
                <span v-if="isDisabled"><i class="fa fa-spinner fa-spin"></i></span>
            </md-button>
            <md-button class="md-danger md-simple" @click="updateSubscriptionModal = false">Close</md-button>
          </template>
        </modal>
    </template>
    </div>
</template>
<script>
import PaymentService from '../../../services/SuperAdmin/PaymentService';
import CustomerService from "../../../services/SuperAdmin/CustomerService";
import { Modal } from "@/components";
import Swal from "sweetalert2";

export default {
  components: {
    Modal,
  },
  data() {
    return {
      isDisabled: false,
      tableData: [],
      customer: null,
      prices: [],
      customerId: this.$route.query.id,
      priceId: "",
      qty:"",
      amount: "",
      existingQty: 0,
      note:"",
      totalPurchasedDevices: 0,
      totalEnrolledDevices: 0,

      //Update Subscription Modal
      updateSubscriptionModal: false,
      currentUpdateSubscriptionDetail: null,
      updatedQty: 0,
      productWiseEnrolledDeviecs: [],
    };
  },
  methods: {
      customerDetail() {
        let data = { customerId: this.customerId };
        CustomerService.detail(data)
        .then((response) => {
            this.customer = response.data.data.customerDetail
            this.tableData = response.data.data.userSubscriptions;
            this.prices = response.data.data.prices;
            this.totalPurchasedDevices = response.data.data.totalPurchasedDevices;
            this.totalEnrolledDevices = response.data.data.totalEnrolledDevices;
            this.$toast.success(response.data.respones_msg);
        }).catch((e) => {
            this.isDisabled = false;
            this.$toast.error(e.response.data.respones_msg);
        });
      },
      subscriptionDetail() {
        this.isDisabled = true;
        let data = { customerId: this.customerId, priceId: this.priceId };
        PaymentService.paymentDetail(data)
        .then((response) => {
            this.existingQty = response.data.data.totalPurchasedDevices
            this.isDisabled = false;
            this.$toast.success(response.data.respones_msg);
        }).catch((e) => {
            this.isDisabled = false;
            this.$toast.error(e.response.data.respones_msg);
        });
      },
      createPayment() {
        this.isDisabled = true;
        let data = { customerId: this.customerId, priceId: this.priceId, qty: this.qty, amount: this.amount, note: this.note };
        PaymentService.createPayment(data)
        .then((response) => {
            this.isDisabled = false;
            this.$toast.success(response.data.respones_msg);
            this.customerDetail();
        }).catch((e) => {
            this.isDisabled = false;
            this.$toast.error(e.response.data.respones_msg);
        });
      },
      openUpdateSubscriptionModal(item) {
        this.currentUpdateSubscriptionDetail = item;
        this.updatedQty = item.qty;
        this.updateSubscriptionModal = true;
        this.enrolledDevicesByProduct(item.priceId.identifier)
      },
      enrolledDevicesByProduct(productIdentifier) {
        let data = { productIdentifier: productIdentifier,  customerId: this.customer._id };
        PaymentService.enrolledDevicesByProduct(data)
        .then((response) => {
            this.productWiseEnrolledDeviecs = response.data.data
            this.$toast.success(response.data.respones_msg);
        }).catch((e) => {
            this.$toast.error(e.response.data.respones_msg);
        });
      },
      deleteDevice(id) {
        let data =  {
          id: id
        }
        PaymentService.deleteDevice(data)
        .then((response) => {
            this.$toast.success(response.data.respones_msg);
            this.enrolledDevicesByProduct(this.currentUpdateSubscriptionDetail.priceId.identifier)
        })
        .catch((e) => {
            this.$toast.error(e.response.data.respones_msg);
        });
      },
      updateSubscription() {
        if (this.updatedQty == this.currentUpdateSubscriptionDetail.qty) {
            this.$toast.error("No change made.");
            return;
        }
        /*if (this.updatedQty > this.currentUpdateSubscriptionDetail.qty) {
            this.$toast.error("Qty can not be great than original qty.");
            return;
        }*/
        this.isDisabled = true;
        let data = {
            id: this.currentUpdateSubscriptionDetail._id,
            updatedQty: this.updatedQty
        }
        PaymentService.updateSubscription(data)
        .then((response) => {
            this.isDisabled = false;
            this.$toast.success(response.data.respones_msg);
            this.customerDetail();
            this.updateSubscriptionModal = false;
            this.currentUpdateSubscriptionDetail = null;
        }).catch((e) => {
            this.isDisabled = false;
            this.$toast.error(e.response.data.respones_msg);
        });
      }
  },
  watch: {
    priceId() {
        this.subscriptionDetail();
    }
  },
  beforeMount() {
    this.customerDetail();
  },
};
</script>
