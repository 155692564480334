import { render, staticRenderFns } from "./AuditLogs.vue?vue&type=template&id=29c62d80&scoped=true&"
import script from "./AuditLogs.vue?vue&type=script&lang=js&"
export * from "./AuditLogs.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "29c62d80",
  null
  
)

export default component.exports