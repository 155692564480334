import http from "../../http-common-super-admin";

class WebsiteService {
    contactusList() {
        return http.get("contactus/list");
    }
    newsletterList() {
        return http.get("newsletter/list");
    }
}

export default new WebsiteService();