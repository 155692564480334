import http from "../../http-common";

class DeviceService {
    listDeviceLogs(data) {
        return http.get("device/log/list", { params: data });
    }
    searchPlayStoreApps(data) {
        return http.get("device/playstore/search", { params: data });
    }
    logoutDevice(data) {
        return http.get("device/logout", { params: data });
    }
    restoreBackup(data) {
        return http.post("device/restore/pdDisabledApp", data);
    }
}

export default new DeviceService();