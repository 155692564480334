<template>
    <div>
        <p><b>Email : {{ customer.email }}</b></p>
        <md-card>
            <md-card-content>
                <form ref="createCustomerForm">
                    
                    <div class="row">
                        <div class="col">
                            <md-field>
                                <label>Name</label>
                                <md-input type="text" v-model="name"></md-input>
                            </md-field>
                        </div>
                        <div class="col">
                            <md-field>
                                <label>Password</label>
                                <md-input type="password" v-model="password"></md-input>
                            </md-field>
                        </div>
                        <div class="col">
                            <md-field>
                                <label>Confirm Password</label>
                                <md-input type="password" v-model="confirmPassword"></md-input>
                            </md-field>
                        </div>
                        <div class="col">
                            <md-button class="md-success" :disabled="isDisabled" @click="updateCustomer()">Save
                                <span v-if="isDisabled"><i class="fa fa-spinner fa-spin"></i></span>
                            </md-button>
                        </div>
                    </div>
                </form>
            </md-card-content>
        </md-card>
    </div>
</template>
<script>
import CustomerService from "../../../services/SuperAdmin/CustomerService";

export default {
  components: {
  },
  data() {
    return {
      isDisabled: false,
      customer: null,
      customerId: this.$route.query.id,
      name: "",
      password:"",
      confirmPassword:"",
    };
  },
  methods: {
      customerDetail() {
        let data = { customerId: this.customerId };
        CustomerService.detail(data)
        .then((response) => {
            this.customer = response.data.data.customerDetail;
            this.name = this.customer.name;
            this.$toast.success(response.data.respones_msg);
        }).catch((e) => {
            this.isDisabled = false;
            this.$toast.error(e.response.data.respones_msg);
        });
      },
      updateCustomer() {
        if (this.password) {
            if (this.password != this.confirmPassword) {
                return this.$toast.error("Password and confirm password mismatched");    
            }
        }
        this.isDisabled = true;
        let data = { name: this.name, customerId: this.customerId, password: this.password };
        CustomerService.updateCustomer(data)
        .then((response) => {
            this.isDisabled = false;
            this.$router.push("admin-customer-list");
            this.$toast.success(response.data.respones_msg);
        }).catch((e) => {
            this.isDisabled = false;
            this.$toast.error(e.response.data.respones_msg);
        });
      }
  },
  watch: {
  },
  beforeMount() {
    this.customerDetail();
  },
};
</script>
