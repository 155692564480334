<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-100">
      <md-card>
        <md-card-actions class="text-center">
        </md-card-actions>
        <md-card-content>
          <md-table
            :value="queriedData"
            class="paginated-table table-striped table-hover" 
            table-header-color="green"
            md-fixed-header
          >
          <md-table-toolbar>
              <md-field>
                <label for="pages">Per page</label>
                <md-select v-model="pagination.perPage" name="pages">
                  <md-option
                    v-for="item in pagination.perPageOptions"
                    :key="item"
                    :label="item"
                    :value="item"
                  >
                    {{ item }}
                  </md-option>
                </md-select>
              </md-field>
            </md-table-toolbar>
            <md-table-row slot="md-table-row" slot-scope="{ item }">
              <md-table-cell md-label="Name"><b>{{ item.exportName }}</b></md-table-cell>
              <md-table-cell md-label="Serial Number"><b>{{ item.serialNumber }}</b></md-table-cell>
              <md-table-cell md-label="Apps">
                <a href="javascript:void(0)" @click="$router.push({ path: 'export-detail', query: { id: item._id }})"><b>{{ item.disabledApplications.length }}</b></a>
              </md-table-cell>
              <md-table-cell md-label="Product"><span :class="'badge-'+item.appName">{{ appNameFull(item.appName) }}</span></md-table-cell>
              <md-table-cell md-label="Created On">{{ HumanReadableDateFormat(item.created_at, true) }}</md-table-cell>
                <md-table-cell md-label="Actions">
                  <button type="button" @click="openRestoreModal(item._id)" class="btn btn-success">Restore</button>
                  <!--<md-button class="md-just-icon md-success md-simple" @click="openRestoreModal(item._id)"><md-icon>settings_backup_restore</md-icon></md-button>&nbsp;-->
                  <md-button class="md-just-icon md-danger md-simple" @click="deleteExport(item._id)"><md-icon>delete</md-icon></md-button>
                </md-table-cell>
            </md-table-row>
          </md-table>
        </md-card-content>
        <md-card-actions md-alignment="space-between">
          <div class="">
            <p class="card-category">
              Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
            </p>
          </div>
          <pagination
            class="pagination-no-border pagination-success"
            v-model="pagination.currentPage"
            :per-page="pagination.perPage"
            :total="total"
          >
          </pagination>
        </md-card-actions>
      </md-card>
    </div>
    <template>
        <modal v-if="restoreModal" @close="restoreModal = false" class="large-modal">
          <template slot="header">
            <h4 class="modal-title">Restore Backup</h4>
            <md-button
              style="height: 25px" class="md-simple md-just-icon md-round modal-default-button"
              @click="restoreModal = false"><md-icon>clear</md-icon></md-button>
          </template>
          <template slot="body">
            <div>
              <h4><b>Select Device:</b></h4>
              <md-radio v-model="profileId" v-for="profile in pdProfiles" :value="profile._id" :key="profile._id">{{ profile.profileName }}</md-radio>
            </div>
          </template>
          <template slot="footer">
            <md-button class="md-success m-1" @click="restoreBackup()">Restore</md-button>
            <md-button class="md-danger md-simple" @click="restoreModal = false">Close</md-button>
          </template>
        </modal>
    </template>
  </div>
</template>
<script>
import UserDataService from "../services/UsersDataService";
import { Pagination } from "@/components";
import Swal from "sweetalert2";
import { Modal } from "@/components";
import ProfileService from "../services/Customer/ProfileService";
import DeviceService from "../services/Customer/DeviceService";

export default {
  components: {
    Pagination,
    Modal,
  },
  computed: {
    /***
     * Returns a page from the searched data or the whole data. Search is performed in the watch section below
     */
    queriedData() {
      let result = this.tableData;
      if (this.searchedData.length > 0) {
        result = this.searchedData;
      }
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.tableData.length;
    },
  },
  data() {
    return {
      isDisabled: false,
      tableData: [],
      user_profiles: [],
      search_profile_id:"",
      search_serial_number: "",
      searchBySource: "",
      searchByStatus:"",

      //Pagination
      pagination: {
        perPage: 25,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0,
      },
      searchedData: [],

      //Search Paramters
      search_by_type: "",
      search_by_sub_type: "",

      //Restore Backup
      restoreModal: false,
      restoreId: "",
      profileId:"",
      pdProfiles: [],
    };
  },
  methods: {
    getExportList() {
      UserDataService.getExportList()
        .then((response) => {
          this.tableData = response.data.data;
        })
        .catch((e) => {
          this.$toast.error(e);
        });
    },
    deleteExport(id) {
        let data =  {
          id: id
        }
        Swal.fire({
            title: "Are you sure?",
            html: '<p>Export backup will be removed from this list</p>',
            type: "warning",
            showCancelButton: true,
            confirmButtonClass: "md-button md-success",
            cancelButtonClass: "md-button md-danger",
            confirmButtonText: "Yes, delete it!",
            buttonsStyling: false,
        }).then((result) => {
            if (result.value) {
                UserDataService.deleteExport(data)
                .then((response) => {
                    this.$toast.success(response.data.respones_msg);
                    this.getExportList();
                })
                .catch((e) => {
                    this.$toast.error(e.response.data.respones_msg);
                }); 
            }
        });
    },
    openRestoreModal(id) {
      this.profileId = "";
      this.listProfile();
      this.restoreId = id;
      this.restoreModal = true;
    },
    listProfile() {
        let data = { appName: "PDALL" }
        ProfileService.listProfile(data)
          .then((response) => {
            this.pdProfiles = response.data.data;
          })
          .catch((e) => {
            this.$toast.error(e.response.data.respones_msg);
          });
    },
    restoreBackup() {
        let data = { profileId: this.profileId, restoreId: this.restoreId }
        DeviceService.restoreBackup(data)
          .then((response) => {
            this.restoreModal = false;
            this.$toast.success(response.data.respones_msg);
          })
          .catch((e) => {
            this.$toast.error(e.response.data.respones_msg);
          });
    },
  },
  beforeMount() {
    this.getExportList();
  },
};
</script>
<style scoped>
</style>
