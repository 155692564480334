<template>
    <div>
        <md-card>
            <md-card-content>
                <div class="row">
                  <div class="col">
                        <md-field>
                          <label>Question</label>
                          <md-input v-model="question" type="text"></md-input>
                        </md-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <vue-editor v-model="answer"></vue-editor>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <md-button class="md-success" :disabled="isDisabled" @click="addFaq()">Add</md-button>
                    </div>
                </div>
            </md-card-content>
        </md-card>
        <md-card>
            <md-card-content>
                <div class="md-layout">
                    <div class="md-layout-item md-size-100">
                        <md-table v-if="tableData.length > 0 " v-model="tableData" table-header-color="green">
                            <md-table-row slot="md-table-row" slot-scope="{ item }">            
                                <md-table-cell md-label="Question"> <b>{{ item.question }} </b></md-table-cell>
                                <md-table-cell md-label="Answer" v-html="item.answer"></md-table-cell>
                                <md-table-cell md-label="Status">
                                  <a href="javascript:void(0)" v-if="item.status == 'Active'" @click="changeFaqStatus(item._id, 'Inactive')"><p class="badge-success">{{ item.status }}</p></a>
                                  <a href="javascript:void(0)" v-else @click="changeFaqStatus(item._id, 'Active')"><p class="badge-danger">{{ item.status }}</p></a>
                                </md-table-cell>
                                <md-table-cell md-label="Created On">{{ HumanReadableDateFormat(item.created_at) }}</md-table-cell>
                                <md-table-cell md-label="Actions">
                                    <md-button class="md-just-icon md-primary md-simple" @click="$router.push({ path: 'admin-edit-faq', query: { id: item._id }})"><md-icon>edit</md-icon></md-button>
                                </md-table-cell>
                            </md-table-row>
                        </md-table>
                        <h4 v-else>No data found.</h4>
                    </div>
                </div>
            </md-card-content>
        </md-card>
    </div>
</template>
<script>
import FaqDataService from "../../../services/SuperAdmin/FaqDataService";
import { VueEditor } from "vue2-editor";

export default {
  components: {
    VueEditor
  },
  data() {
    return {
      isDisabled: false,
      tableData: [],
      answer:"",
      question: "",
    };
  },
  methods: {
      faqList() {
        FaqDataService.faqList()
          .then((response) => {
            this.tableData = response.data.data;
          })
          .catch((e) => {
            this.$toast.error(e.response.data.respones_msg);
          });
      },
      addFaq() {
        this.isDisabled = true;
        let data =  {
          question: this.question,
          answer: this.answer,
          status: "Active"
        }
        FaqDataService.addFaq(data)
          .then((response) => {
            this.isDisabled = false;
            this.$toast.success(response.data.respones_msg);
            this.question = "";
            this.answer = "";
            this.faqList();
          })
          .catch((e) => {
            this.isDisabled = false;
            this.$toast.error(e.response.data.respones_msg);
          }); 
      },
      changeFaqStatus(id, status) {
        let data =  {
          id: id,
          status: status
        }
        FaqDataService.changeFaqStatus(data)
          .then((response) => {
            this.$toast.success(response.data.respones_msg);
            this.faqList();
          })
          .catch((e) => {
            this.$toast.error(e.response.data.respones_msg);
          }); 
      }
  },
  beforeMount() {
    this.faqList();
  },
};
</script>