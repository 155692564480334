import http from "../../http-common-super-admin";

class PaymentService {
  paymentHistory(data) {
    return http.get("payments/history", { params: data });
  }
  sendFailedPaymentEmail(data) {
    return http.post("payments/fail/email", data);
  }
  paymentDetail(data) {
    return http.get("payments/subscription/detail", { params: data });
  }
  createPayment(data) {
    return http.post("payments/create", data);
  }
  upcomingExpirePaymentHistory(data) {
    return http.get("payments/upcoming-expire-history", { params: data });
  }
  getPaymentDashboardData(data) {
    return http.get("payments/dashboard", { params: data });
  }
  subscriptionDetail(data) {
    return http.get("payments/detail", { params: data });
  }
  refundPayment(data) {
    return http.post("payments/refund", data);
  }
  updateSubscription(data) {
    return http.post("payments/subscription/update", data);
  }
  enrolledDevicesByProduct(data) {
    return http.get("payments/deviceByProduct", { params: data });
  }
  deleteDevice(data) {
    return http.delete("payments/delete/device", { params: data });
  }
  couponList(data) {
    return http.get("payments/coupons", { params: data });
  }
  updateSettings(data) {
    return http.post("payments/save/setting", data);
  }
  errorList(data) {
    return http.get("payments/errors", { params: data });
  }
}

export default new PaymentService();