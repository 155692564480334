<template>
    <div>
        <h3 class="text-secondary"><b>Package Disabler</b></h3>
        <div class="row">
          <div class="col-sm-6" v-for="item in pdPlanDetails" :key="item._id">
            <div class="card">
              <div class="card-body" :class="{ disabled: item.totalPurchasedDevices == 0 }">
                <h5 class="card-title"><b>{{ item.name }}</b></h5>
                <small v-if="item.description">{{ item.description }} <br></small>
                <small class="text-info font-weight-bold"><a href="javascript:void(0)" @click="$router.push({ path: '/payment-history', query: { priceId: item._id } })">Total Devices : {{ item.totalPurchasedDevices }}</a></small><br>
                <small class="text-danger font-weight-bold">Expiry : <span v-if="item.expiry">{{ HumanReadableDateFormat(item.expiry, true) }}</span></small>
              </div>
              <hr>
              <div :style="{ display: 'flex', 'justify-content': 'center' }" v-if="item.type == 'recurring'">
                  <md-button class="md-primary mr-2" v-if="item.totalPurchasedDevices > 0" @click="$router.push({ path: '/update-subscription', query: { id: item._id } })">Update</md-button>
                  <md-button class="md-success mr-2" v-else @click="$router.push({ path: '/make-payment', query: { id: item._id } })">Subscribe</md-button>
              </div>
              <div v-else :style="{ display: 'flex', 'justify-content': 'center' }">
                <md-button class="md-primary mr-2" v-if="item.totalPurchasedDevices > 0" @click="$router.push({ path: '/make-payment', query: { id: item._id } })">Update</md-button>
                <md-button class="md-success mr-2" v-else @click="$router.push({ path: '/make-payment', query: { id: item._id } })">Buy</md-button>
              </div>
            </div>
          </div>
        </div>
        <h3 class="text-secondary"><b>Super Lock</b></h3>
        <div class="row">
          <div class="col-sm-6" v-for="item in upPlanDetails" :key="item._id">
            <div class="card">
              <div class="card-body" :class="{ disabled: item.totalPurchasedDevices == 0 }">
                <h5 class="card-title"><b>{{ item.name }}</b></h5>
                <small v-if="item.description">{{ item.description }} <br></small>
                <small class="text-info font-weight-bold"><a href="javascript:void(0)" @click="$router.push({ path: '/payment-history', query: { priceId: item._id } })">Total Devices : {{ item.totalPurchasedDevices }}</a></small><br>
                <small class="text-danger font-weight-bold">Expiry : <span v-if="item.expiry">{{ HumanReadableDateFormat(item.expiry, true) }}</span></small>
              </div>
              <hr>
              <div :style="{ display: 'flex', 'justify-content': 'center' }" v-if="item.type == 'recurring'">
                  <md-button class="md-primary mr-2" v-if="item.totalPurchasedDevices > 0" @click="$router.push({ path: '/update-subscription', query: { id: item._id } })">Update</md-button>
                  <md-button class="md-success mr-2" v-else @click="$router.push({ path: '/make-payment', query: { id: item._id } })">Subscribe</md-button>
              </div>
              <div v-else :style="{ display: 'flex', 'justify-content': 'center' }">
                <md-button class="md-primary mr-2" v-if="item.totalPurchasedDevices > 0" @click="$router.push({ path: '/make-payment', query: { id: item._id } })">Update</md-button>
                <md-button class="md-success mr-2" v-else @click="$router.push({ path: '/make-payment', query: { id: item._id } })">Buy</md-button>
              </div>
            </div>
          </div>
        </div>
        <h3 class="text-secondary"><b>Power Cleaner</b></h3>
        <div class="row">
          <div class="col-sm-6" v-for="item in pcPlanDetails" :key="item._id">
            <div class="card">
              <div class="card-body" :class="{ disabled: item.totalPurchasedDevices == 0 }">
                <h5 class="card-title"><b>{{ item.name }}</b></h5>
                <small v-if="item.description">{{ item.description }} <br></small>
                <small class="text-info font-weight-bold"><a href="javascript:void(0)" @click="$router.push({ path: '/payment-history', query: { priceId: item._id } })">Total Devices : {{ item.totalPurchasedDevices }}</a></small><br>
                <small class="text-danger font-weight-bold">Expiry : <span v-if="item.expiry">{{ HumanReadableDateFormat(item.expiry, true) }}</span></small>
              </div>
              <hr>
              <div :style="{ display: 'flex', 'justify-content': 'center' }" v-if="item.type == 'recurring'">
                  <md-button class="md-primary mr-2" v-if="item.totalPurchasedDevices > 0" @click="$router.push({ path: '/update-subscription', query: { id: item._id } })">Update</md-button>
                  <md-button class="md-success mr-2" v-else @click="$router.push({ path: '/make-payment', query: { id: item._id } })">Subscribe</md-button>
              </div>
              <div v-else :style="{ display: 'flex', 'justify-content': 'center' }">
                <md-button class="md-primary mr-2" v-if="item.totalPurchasedDevices > 0" @click="$router.push({ path: '/make-payment', query: { id: item._id } })">Update</md-button>
                <md-button class="md-success mr-2" v-else @click="$router.push({ path: '/make-payment', query: { id: item._id } })">Buy</md-button>
              </div>
            </div>
          </div>
        </div>
        <h3 class="text-secondary"><b>URL Blocker</b></h3>
        <div class="row">
          <div class="col-sm-6" v-for="item in ubPlanDetails" :key="item._id">
            <div class="card">
              <div class="card-body" :class="{ disabled: item.totalPurchasedDevices == 0 }">
                <h5 class="card-title"><b>{{ item.name }}</b></h5>
                <small v-if="item.description">{{ item.description }} <br></small>
                <small class="text-info font-weight-bold"><a href="javascript:void(0)" @click="$router.push({ path: '/payment-history', query: { priceId: item._id } })">Total Devices : {{ item.totalPurchasedDevices }}</a></small><br>
                <small class="text-danger font-weight-bold">Expiry : <span v-if="item.expiry">{{ HumanReadableDateFormat(item.expiry, true) }}</span></small>
              </div>
              <hr>
              <div :style="{ display: 'flex', 'justify-content': 'center' }" v-if="item.type == 'recurring'">
                  <md-button class="md-primary mr-2" v-if="item.totalPurchasedDevices > 0" @click="$router.push({ path: '/update-subscription', query: { id: item._id } })">Update</md-button>
                  <md-button class="md-success mr-2" v-else @click="$router.push({ path: '/make-payment', query: { id: item._id } })">Subscribe</md-button>
              </div>
              <div v-else :style="{ display: 'flex', 'justify-content': 'center' }">
                <md-button class="md-primary mr-2" v-if="item.totalPurchasedDevices > 0" @click="$router.push({ path: '/make-payment', query: { id: item._id } })">Update</md-button>
                <md-button class="md-success mr-2" v-else @click="$router.push({ path: '/make-payment', query: { id: item._id } })">Buy</md-button>
              </div>
            </div>
          </div>
        </div>
        <h3 class="text-secondary"><b>Key Mapper</b></h3>
        <div class="row">
          <div class="col-sm-6" v-for="item in kmPlanDetails" :key="item._id">
            <div class="card">
              <div class="card-body" :class="{ disabled: item.totalPurchasedDevices == 0 }">
                <h5 class="card-title"><b>{{ item.name }}</b></h5>
                <small v-if="item.description">{{ item.description }} <br></small>
                <small class="text-info font-weight-bold"><a href="javascript:void(0)" @click="$router.push({ path: '/payment-history', query: { priceId: item._id } })">Total Devices : {{ item.totalPurchasedDevices }}</a></small><br>
                <small class="text-danger font-weight-bold">Expiry : <span v-if="item.expiry">{{ HumanReadableDateFormat(item.expiry, true) }}</span></small>
              </div>
              <hr>
              <div :style="{ display: 'flex', 'justify-content': 'center' }" v-if="item.type == 'recurring'">
                  <md-button class="md-primary mr-2" v-if="item.totalPurchasedDevices > 0" @click="$router.push({ path: '/update-subscription', query: { id: item._id } })">Update</md-button>
                  <md-button class="md-success mr-2" v-else @click="$router.push({ path: '/make-payment', query: { id: item._id } })">Subscribe</md-button>
              </div>
              <div v-else :style="{ display: 'flex', 'justify-content': 'center' }">
                <md-button class="md-primary mr-2" v-if="item.totalPurchasedDevices > 0" @click="$router.push({ path: '/make-payment', query: { id: item._id } })">Update</md-button>
                <md-button class="md-success mr-2" v-else @click="$router.push({ path: '/make-payment', query: { id: item._id } })">Buy</md-button>
              </div>
            </div>
          </div>
        </div>
        <h3 class="text-secondary"><b>Power On Boot</b></h3>
        <div class="row">
          <div class="col-sm-6" v-for="item in pobPlanDetails" :key="item._id">
            <div class="card">
              <div class="card-body" :class="{ disabled: item.totalPurchasedDevices == 0 }">
                <h5 class="card-title"><b>{{ item.name }}</b></h5>
                <small v-if="item.description">{{ item.description }} <br></small>
                <small class="text-info font-weight-bold"><a href="javascript:void(0)" @click="$router.push({ path: '/payment-history', query: { priceId: item._id } })">Total Devices : {{ item.totalPurchasedDevices }}</a></small><br>
                <small class="text-danger font-weight-bold">Expiry : <span v-if="item.expiry">{{ HumanReadableDateFormat(item.expiry, true) }}</span></small>
              </div>
              <hr>
              <div :style="{ display: 'flex', 'justify-content': 'center' }" v-if="item.type == 'recurring'">
                  <md-button class="md-primary mr-2" v-if="item.totalPurchasedDevices > 0" @click="$router.push({ path: '/update-subscription', query: { id: item._id } })">Update</md-button>
                  <md-button class="md-success mr-2" v-else @click="$router.push({ path: '/make-payment', query: { id: item._id } })">Subscribe</md-button>
              </div>
              <div v-else :style="{ display: 'flex', 'justify-content': 'center' }">
                <md-button class="md-primary mr-2" v-if="item.totalPurchasedDevices > 0" @click="$router.push({ path: '/make-payment', query: { id: item._id } })">Update</md-button>
                <md-button class="md-success mr-2" v-else @click="$router.push({ path: '/make-payment', query: { id: item._id } })">Buy</md-button>
              </div>
            </div>
          </div>
        </div>
        <h3 class="text-secondary"><b>Power Chat</b></h3>
        <div class="row">
          <div class="col-sm-6" v-for="item in pChatPlanDetails" :key="item._id">
            <div class="card">
              <div class="card-body" :class="{ disabled: item.totalPurchasedDevices == 0 }">
                <h5 class="card-title"><b>{{ item.name }}</b></h5>
                <small v-if="item.description">{{ item.description }} <br></small>
                <small class="text-info font-weight-bold"><a href="javascript:void(0)" @click="$router.push({ path: '/payment-history', query: { priceId: item._id } })">Total Devices : {{ item.totalPurchasedDevices }}</a></small><br>
                <small class="text-danger font-weight-bold">Expiry : <span v-if="item.expiry">{{ HumanReadableDateFormat(item.expiry, true) }}</span></small>
              </div>
              <hr>
              <div :style="{ display: 'flex', 'justify-content': 'center' }" v-if="item.type == 'recurring'">
                  <md-button class="md-primary mr-2" v-if="item.totalPurchasedDevices > 0" @click="$router.push({ path: '/update-subscription', query: { id: item._id } })">Update</md-button>
                  <md-button class="md-success mr-2" v-else @click="$router.push({ path: '/make-payment', query: { id: item._id } })">Subscribe</md-button>
              </div>
              <div v-else :style="{ display: 'flex', 'justify-content': 'center' }">
                <md-button class="md-primary mr-2" v-if="item.totalPurchasedDevices > 0" @click="$router.push({ path: '/make-payment', query: { id: item._id } })">Update</md-button>
                <md-button class="md-success mr-2" v-else @click="$router.push({ path: '/make-payment', query: { id: item._id } })">Buy</md-button>
              </div>
            </div>
          </div>
        </div>
        <!--<h3  class="text-secondary"><b>Plans With Subscriptions</b></h3>
        <div class="row p-3 m-3">
            <div class="card mr-2" style="width: 15rem;" v-for="item in recurringPriceList" :key="item._id">
                <div class="card-body" :class="{ disabled: item.totalPurchasedDevices == 0 }">
                    <h6 class="card-title">{{ item.name }}</h6>
                    <small class="text-info font-weight-bold"><a href="javascript:void(0)" @click="$router.push({ path: '/payment-history', query: { priceId: item._id } })">Total Devices : {{ item.totalPurchasedDevices }}</a></small><br>
                    <small class="text-danger font-weight-bold">Expiry : <span v-if="item.expiry">{{ HumanReadableDateFormat(item.expiry, true) }}</span></small>
                </div>
                <div class="card-footer">
                    <div :style="{ display: 'flex' }">
                        <md-button class="md-primary mr-2" v-if="item.totalPurchasedDevices > 0" @click="$router.push({ path: '/update-subscription', query: { id: item._id } })">Update</md-button>
                        <md-button class="md-success mr-2" v-else @click="$router.push({ path: '/make-payment', query: { id: item._id } })">Subscribe</md-button>
                        <md-button class="md-secondary mr-2" v-if="item.totalPurchasedDevices > 0">Cancel</md-button>
                    </div>
                </div>
            </div>
        </div>
        <h3 class="text-secondary"><b>Plans Without Subscriptions</b></h3>
        <div class="row p-3 m-3">
            <div class="card mr-2" style="width: 15rem;" v-for="item in oneTimePriceList" :key="item._id">
                <div class="card-body" :class="{ disabled: item.totalPurchasedDevices == 0 }">
                    <h6 class="card-title">{{ item.name }}</h6>
                    <small class="text-info font-weight-bold"><a href="javascript:void(0)" @click="$router.push({ path: '/payment-history', query: { priceId: item._id } })">Total Devices : {{ item.totalPurchasedDevices }} </a></small><br>
                    <small class="text-danger font-weight-bold">Expiry : <span v-if="item.expiry">{{ HumanReadableDateFormat(item.expiry, true) }}</span></small>
                </div>
                <div class="card-footer">
                    <div :style="{ display: 'flex' }">
                        <md-button class="md-primary mr-2" v-if="item.totalPurchasedDevices > 0" @click="$router.push({ path: '/make-payment', query: { id: item._id } })">Update</md-button>
                        <md-button class="md-success mr-2" v-else @click="$router.push({ path: '/make-payment', query: { id: item._id } })">Buy</md-button>
                    </div>
                </div>
            </div>
        </div>-->
    </div>
</template>
<script>
import PaymentService from "../../../services/Customer/PaymentService";

export default {
  components: {
  },
  data() {
    return {
      isDisabled: false,
      oneTimePriceList: [],
      recurringPriceList: [],
      pdPlanDetails: [],
      pcPlanDetails: [],
      ubPlanDetails: [],
      kmPlanDetails: [],
      pChatPlanDetails: [],
      pobPlanDetails: [],
      upPlanDetails: [],
    };
  },
  methods: {
      listPrice() {
        PaymentService.listPrice()
          .then((response) => {
            let pdPlans = [], pcPlan = [], ubPlan = [], kmPlan = [], pChatPlan = [], pobPlanDetails = [], upPlanDetails = [];
            this.recurringPriceList = response.data.data.recurringPriceList;
            this.oneTimePriceList = response.data.data.oneTimePriceList;
            if (this.recurringPriceList.length > 0) {
              this.recurringPriceList.forEach(function (item) {
                  if (item.identifier == "PD") {
                    pdPlans.push(item);  
                  }
                  if (item.identifier == "PC") {
                    pcPlan.push(item);  
                  }
                  if (item.identifier == "UB") {
                    ubPlan.push(item);  
                  }
                  if (item.identifier == "KM") {
                    kmPlan.push(item);  
                  }
                  if (item.identifier == "POB") {
                    pobPlanDetails.push(item);  
                  }
                  if (item.identifier == "PCHAT") {
                    pChatPlan.push(item);  
                  }
                  if (item.identifier == "SL") {
                    upPlanDetails.push(item);  
                  }
              });
            }
            if (this.oneTimePriceList.length > 0) {
              this.oneTimePriceList.forEach(function (item) {
                  if (item.identifier == "PDOT") {
                    pdPlans.push(item);  
                  }
                  if (item.identifier == "PCOT") {
                    pcPlan.push(item);  
                  }
                  if (item.identifier == "UBOT") {
                    ubPlan.push(item);  
                  }
                  if (item.identifier == "KMOT") {
                    kmPlan.push(item);  
                  }
                  if (item.identifier == "POBOT") {
                    pobPlanDetails.push(item);  
                  }
                  if (item.identifier == "SLOT") {
                    upPlanDetails.push(item);  
                  }
              });
            }
            this.pdPlanDetails = pdPlans;
            this.pcPlanDetails = pcPlan;
            this.ubPlanDetails = ubPlan;
            this.kmPlanDetails = kmPlan;
            this.pChatPlanDetails = pChatPlan;
            this.pobPlanDetails = pobPlanDetails;
            this.upPlanDetails = upPlanDetails;
            this.$toast.success(response.data.respones_msg);
          })
          .catch((e) => {
            console.log({e});
            this.$toast.error(e.response.data.respones_msg);
          });
      },
  },
  beforeMount() {
    this.listPrice();
  },
};
</script>
<style scoped>
.disabled{
  opacity: 0.5;
}
.card-footer{
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>