import { param } from "jquery";
import http from "../../http-common";

class ProfileService {
    listProfile(data) {
        return http.get("profile/list", { params: data });
    }
    profileDetail(data) {
        return http.get("profile/detail", { params: data });
    }
    profileDeviceDetail(data) {
        return http.get("profile/device/detail", { params: data });
    }
    updateProfile(data) {
        return http.post("profile/update", data);
    }
    updateApplication(data) {
        return http.post("profile/device/application/update", data);
    }
    syncApplications(data) {
        return http.get("profile/device/application/sync", { params: data });
    }
    applyPolicy(data) {
        return http.get("profile/apply", { params: data });
    }

    createProfileApplication(data) {
        return http.post("profile/application/create", data);
    }
    profileApplicationsList(data) {
        return http.get("profile/applications", { params: data });
    }
    updateProfileApplication(data) {
        return http.post("profile/application/update", data);
    }
    deleteProfileApplication(data) {
        return http.delete("profile/application/delete", { params: data });
    }

    addProfileFiles(data) {
        return http.post("profile/file/add", data);
    }
    deleteProfileFile(data) {
        return http.post("profile/file/delete", data);
    }
    updatePcSetting(data) {
        return http.post("profile/update/pc/setting", data);
    }

    addProfileBlockUrl(data) {
        return http.post("profile/blockUrl/add", data);
    }
    deleteProfileBlockUrl(data) {
        return http.post("profile/blockUrl/delete", data);
    }

    addProfileKeyMapper(data) {
        return http.post("profile/keyMapper/add", data);
    }
    deleteProfileKeyMapper(data) {
        return http.post("profile/keyMapper/delete", data);
    }

    updatePOBSetting(data) {
        return http.post("profile/update/pob/setting", data);
    }
    updateKioskSetting(data) {
        return http.post("profile/update/kiosk/setting", data);
    }
}

export default new ProfileService();