<template>
    <div>
        <div class="md-layout">
            <div class="md-layout-item md-size-100">
                <div style="float: left">
                    <h4>
                        <p class="text-primary" v-if="profileData"> {{ profileData.name }} </p>
                    </h4>
                </div>
                <div style="float: right">
                    <md-button class="md-success mr-2" :disabled="isDisabled" @click="applyPolicy()">
                        Apply<span v-if="isDisabled"><i class="fa fa-spinner fa-spin"></i></span>
                    </md-button>
                </div>
                <tabs :tab-name="['Applications', 'Cleaner Apps', 'Setting', 'Key Mapper', 'URL', 'Kiosk', 'Power On Boot', 'Device', 'Pin']" color-button="warning">
                    <template slot="tab-pane-1">
                        <div class="md-layout">
                          <div class="md-layout-item md-size-100">
                              <div style="float: right">                            
                                <md-button class="md-warning mr-2" :disabled="isDisabled" @click="syncApplications()">
                                Sync<span v-if="isDisabled"><i class="fa fa-spinner fa-spin"></i></span>
                                </md-button>
                              </div>
                          </div>
                          <div class="md-layout">
                              <div class="md-layout-item md-size-33">
                                  <md-field>
                                    <md-input type="text" class="box-textbox" placeholder="Package Name" v-model="searchPackageName"></md-input>
                                  </md-field>
                              </div>
                              <div class="md-layout-item md-size-33">
                                <md-field>
                                  <md-select class="box-textbox" placeholder="Status" v-model="searchApplicationStatus">
                                      <md-option value="Enabled">Enabled</md-option>
                                      <md-option value="Disabled">Disabled</md-option>
                                  </md-select>
                                </md-field>
                              </div>
                              <div class="md-layout-item md-size-33">
                                <md-field>
                                  <md-select class="box-textbox" placeholder="Install Status" v-model="searchApplicationInstallStatus">
                                      <md-option value="Enabled">Installed</md-option>
                                      <md-option value="Disabled">Uninstalled</md-option>
                                  </md-select>
                                </md-field>
                              </div>
                          </div>
                          <div class="md-layout">
                              <div class="md-layout-item md-size-33">
                                  <md-switch v-model="showSystemApp">Show System App</md-switch>
                              </div>
                              <div class="md-layout-item md-size-33">
                                  <md-button class="md-primary" :disabled="isDisabled"  @click="filterApplications()">Search <span v-if="isDisabled"><i class="fa fa-spinner fa-spin"></i></span></md-button> 
                              </div>
                          </div>
                          <div class="md-layout-item md-size-100">
                              <md-card v-if="applications.length > 0 ">
                                <md-table 
                                   :value="queriedData" 
                                   class="paginated-table table-striped table-hover"  table-header-color="green" md-fixed-header>
                                    <md-table-toolbar>
                                      <md-field>
                                          <label for="pages">Per page</label>
                                          <md-select v-model="pagination.perPage" name="pages">
                                          <md-option
                                              v-for="item in pagination.perPageOptions"
                                              :key="item"
                                              :label="item"
                                              :value="item"
                                          >
                                              {{ item }}
                                          </md-option>
                                          </md-select>
                                      </md-field>
                                    </md-table-toolbar>
                                    <md-table-row slot="md-table-row" slot-scope="{ item }">
                                        <md-table-cell md-label="App Disabled">
                                          <input type="checkbox" @change="updateApplication(item.package_name)" :id="item.package_name" v-if="item.app_disable" checked />
                                          <input type="checkbox" @change="updateApplication(item.package_name)" :id="item.package_name" v-else />
                                        </md-table-cell>
                                        <md-table-cell md-label="Icon">
                                            <img :src="item.appIcon" style="height: 50px;width: 50px;" /> 
                                        </md-table-cell>
                                        <md-table-cell md-label="Package Name">
                                          {{ item.app_name }} <br /> {{ item.package_name }}
                                        </md-table-cell>
                                        <md-table-cell md-label="App Version" :style="{ 'text-align': 'center' }">{{ item.version_number }}</md-table-cell>
                                        <md-table-cell md-label="App Status">{{ item.app_status }}</md-table-cell>
                                    </md-table-row>
                                </md-table>
                                <md-card-actions md-alignment="space-between">
                                    <div class="">
                                        <p class="card-category">
                                        Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
                                        </p>
                                    </div>
                                    <pagination
                                        class="pagination-no-border pagination-success"
                                        v-model="pagination.currentPage"
                                        :per-page="pagination.perPage"
                                        :total="total"
                                    >
                                    </pagination>
                                </md-card-actions>
                            </md-card>
                            <h4 v-else>No data found.</h4>
                            </div>
                        </div>
                    </template>
                    <template slot="tab-pane-2">
                        <tabs :tab-name="['Applications', 'Files', 'Settings']" color-button="warning">
                          <template slot="tab-pane-1">
                            <PcProfileApplicationsTab  :profileId="profileId" />
                          </template>
                          <template slot="tab-pane-2">
                              <ProfileFilesTab  :profileId="profileId" />
                          </template>
                          <template slot="tab-pane-3">
                              <PcSettingTab  :profileId="profileId" :profileDetail="profileData" />
                          </template>
                        </tabs>
                    </template>
                    <template slot="tab-pane-3">
                        <div style="float: right">
                          <md-button class="md-success" :disabled="isDisabled" @click="updateProfile()">
                            Save<span v-if="isDisabled"><i class="fa fa-spinner fa-spin"></i></span></md-button>
                        </div>
                        <br><span><b>Profile Counter : {{ this.profileData.profileCounter}}</b></span>
                        <br><small class="text-danger"><b>Works with Samsung devices only</b></small>
                        <div class="row">
                          <div class="col-md-4">
                            <md-switch v-model="disableFactoryReset">Disable Factory Reset</md-switch>
                            <md-switch v-model="disableHomeKey">Disable Home Key</md-switch>
                            <md-switch v-model="disableBackKey">Disable Back Key</md-switch>
                            <md-switch v-model="disableRecentsKey">Disable Recents Key</md-switch>
                            <md-switch v-model="disablePowerKey">Disable Power Key</md-switch>
                            <md-switch v-model="disbableBixbyPackages">Disable Bixby Packages</md-switch>
                            <md-switch v-model="disableFirmwareUpgrades">Disable Firmware Upgrades</md-switch>
                          </div>
                          <div class="col-md-4">
                            <md-switch v-model="disableVolumeUpKey">Disable Volume Up Key</md-switch>
                            <md-switch v-model="disableVolumeDownKey">Disable Volume Down Key</md-switch>
                            <md-switch v-model="DisableOSUpdates">Disable OS Updates</md-switch>
                            <md-switch v-model="DisableBluetooth"> Disable Bluetooth</md-switch>
                            <md-switch v-model="disableWiFi">Disable WiFi</md-switch>
                            <md-switch v-model="disableCarboardAppsOnGearVR">Disable Carboard Apps On Gear VR</md-switch>
                          </div>
                          <div class="col-md-4">
                            <md-switch v-model="disableGPS">Disable GPS</md-switch>
                            <md-switch v-model="disableMultiWindow">Disable Multi Window</md-switch>
                            <md-switch v-model="disableUsbDebugging">Disable Usb Debugging</md-switch>
                            <md-switch v-model="disableUnknownSource">Disable Unknown Source</md-switch>
                            <md-switch v-model="disableOverTheAir">Disable OVER-THE AIR</md-switch>
                            <md-switch v-model="disableStatusBar">Disable Status Bar</md-switch>
                          </div>
                        </div>
                    </template>
                    <template slot="tab-pane-4">
                        <KeyMappingTab  :profileId="profileId" />
                    </template>
                    <template slot="tab-pane-5">
                        <ProfileUrlTab  :profileId="profileId" />
                    </template>
                    <template slot="tab-pane-6">
                        <KioskTab  :profileId="profileId" :profileDetail="profileData" />
                    </template>
                    <template slot="tab-pane-7">
                        <POBTab  :profileId="profileId" :profileDetail="profileData" />
                    </template>
                    <template slot="tab-pane-8">
                        <div style="float: right">
                          <md-button class="md-success" :disabled="isDisabled" @click="logoutDevice()">
                            Logout<span v-if="isDisabled"><i class="fa fa-spinner fa-spin"></i></span></md-button>
                        </div>
                        <DeviceTab  :profileId="profileId" />    
                    </template>
                    <template slot="tab-pane-9">
                        <div style="float: right">
                          <md-button class="md-success" :disabled="isDisabled" @click="updateProfile()">
                            Save<span v-if="isDisabled"><i class="fa fa-spinner fa-spin"></i></span></md-button>
                        </div>
                        <div class="md-layout">
                            <div class="md-layout-item md-size-100">
                              <br><md-field>
                                <label>Pin</label>
                                <md-input type="number" v-model="pin"></md-input>
                            </md-field>
                            </div>
                        </div>
                    </template>
                </tabs>
            </div>
        </div>
    </div>
</template>
<script>
import ProfileService from "../../../services/Customer/ProfileService";
import DeviceService from "../../../services/Customer/DeviceService";
import { Tabs } from "@/components";
import { Pagination } from "@/components";
import DeviceTab  from '@/components/Custom/DeviceTab';
import PcProfileApplicationsTab  from '@/components/Custom/PcProfileApplicationsTab';
import ProfileFilesTab  from '@/components/Custom/ProfileFilesTab';
import KeyMappingTab  from '@/components/Custom/KeyMappingTab';
import ProfileUrlTab  from '@/components/Custom/ProfileUrlTab';
import PcSettingTab  from '@/components/Custom/PcSettingTab';
import POBTab  from '@/components/Custom/POBTab';
import KioskTab from '@/components/Custom/KioskTab';

export default {
  components: {
    Tabs,
    Pagination,
    DeviceTab,
    PcProfileApplicationsTab,
    ProfileFilesTab,
    KeyMappingTab,
    ProfileUrlTab,
    PcSettingTab,
    POBTab,
    KioskTab
  },
  computed: {
        queriedData() {
          let result = this.applications;
          if (this.searchedData.length > 0) {
              result = this.searchedData;
          }
          return result.slice(this.from, this.to);
        },
        to() {
          let highBound = this.from + this.pagination.perPage;
          if (this.total < highBound) {
              highBound = this.total;
          }
          return highBound;
        },
        from() {
          return this.pagination.perPage * (this.pagination.currentPage - 1);
        },
        total() {
          return this.searchedData.length > 0
              ? this.searchedData.length
              : this.applications.length;
        },
  },
  data() {
    return {
      isDisabled: false,
      profileId: this.$route.query.profile_id,
      profileData: null,
      deviceDetail: null,
      applications: [],
      applicationArrayBackup: [],

      //Application Tab
      searchPackageName: "",
      searchApplicationStatus:"",
      searchApplicationInstallStatus: "",
      showSystemApp:false,

      //Pagination
      pagination: {
            perPage: 25,
            currentPage: 1,
            perPageOptions: [5, 10, 25, 50],
            total: 0,
        },
      searchedData: [],

      //Advance Settings
      disableFactoryReset: false,
      disableHomeKey: false,
      disableBackKey: false,
      disableRecentsKey: false,
      disablePowerKey: false,
      disableVolumeUpKey: false,
      disableVolumeDownKey: false,
      DisableOSUpdates: false,
      DisableBluetooth: false,
      disableWiFi: false,
      disableGPS: false,
      disableMultiWindow: false,
      disableUsbDebugging: false,
      disableUnknownSource: false,
      disableOverTheAir: false,
      disbableBixbyPackages: false,
      disableCarboardAppsOnGearVR: false,
      disableStatusBar: false,
      disableFirmwareUpgrades: false, 

      //Pin
      pin: "",

    };
  },
  methods: {
      profileDetail() {
        let data = { profileId: this.profileId }
        ProfileService.profileDetail(data)
          .then((response) => {
            this.profileData = response.data.data;
            this.pin = this.profileData.pin;
            if (this.profileData.advancedSettings) {
              this.disableFactoryReset = this.profileData.advancedSettings.disableFactoryReset;
              this.disableHomeKey = this.profileData.advancedSettings.disableHomeKey;
              this.disableBackKey = this.profileData.advancedSettings.disableBackKey;
              this.disableRecentsKey = this.profileData.advancedSettings.disableRecentsKey;
              this.disablePowerKey = this.profileData.advancedSettings.disablePowerKey;
              this.disableVolumeUpKey = this.profileData.advancedSettings.disableVolumeUpKey;
              this.disableVolumeDownKey = this.profileData.advancedSettings.disableVolumeDownKey;
              this.DisableOSUpdates = this.profileData.advancedSettings.DisableOSUpdates;
              this.DisableBluetooth = this.profileData.advancedSettings.DisableBluetooth;
              this.disableWiFi = this.profileData.advancedSettings.disableWiFi;
              this.disableGPS = this.profileData.advancedSettings.disableGPS;
              this.disableMultiWindow = this.profileData.advancedSettings.disableMultiWindow;
              this.disableUsbDebugging = this.profileData.advancedSettings.disableUsbDebugging;
              this.disableUnknownSource = this.profileData.advancedSettings.disableUnknownSource;

              this.disableOverTheAir = this.profileData.advancedSettings.disableOverTheAir;
              this.disbableBixbyPackages = this.profileData.advancedSettings.disbableBixbyPackages;
              this.disableCarboardAppsOnGearVR = this.profileData.advancedSettings.disableCarboardAppsOnGearVR;
              this.disableStatusBar = this.profileData.advancedSettings.disableStatusBar;
              this.disableFirmwareUpgrades = this.profileData.advancedSettings.disableFirmwareUpgrades;
            }
            //this.$toast.success(response.data.respones_msg);
          })
          .catch((e) => {
            this.$toast.error(e.response.data.respones_msg);
          });
      },
      profileDeviceDetail() {
        let data = { profileId: this.profileId, packageName: this.searchPackageName,
          applicationStatus: this.searchApplicationStatus, applicationInstallStatus: this.searchApplicationInstallStatus, showSystemApp: this.showSystemApp }
        ProfileService.profileDeviceDetail(data)
          .then((response) => {
            this.deviceDetail = response.data.data;
            let applications = this.deviceDetail.applications;
            
            /*const res = applications.reduce((a, c) => {
              if (!a.find(v => v['package_name'] === c['package_name'])) {
                a.push(c);
              }
              return a;
            }, []);
            
            this.applications = res; //this.deviceDetail.applications;*/
            this.applications = this.deviceDetail.applications;
            
            this.applicationArrayBackup = this.applications;
            this.filterApplications();1
            //this.$toast.success(response.data.respones_msg);
          })
          .catch((e) => {
            this.$toast.error(e.response.data.respones_msg);
          });
      },
      updateProfile() {
        let data = { 
          profileId: this.profileId,
          pin: this.pin,
          advancedSettings: {
            disableFactoryReset: this.disableFactoryReset,
            disableHomeKey: this.disableHomeKey,
            disableBackKey: this.disableBackKey,
            disableRecentsKey: this.disableRecentsKey,
            disablePowerKey: this.disablePowerKey,
            disableVolumeUpKey: this.disableVolumeUpKey,
            disableVolumeDownKey: this.disableVolumeDownKey,
            DisableOSUpdates: this.DisableOSUpdates,
            DisableBluetooth: this.DisableBluetooth,
            disableWiFi: this.disableWiFi,
            disableGPS: this.disableGPS,
            disableMultiWindow: this.disableMultiWindow,
            disableUsbDebugging: this.disableUsbDebugging,
            disableUnknownSource: this.disableUnknownSource,
            disableOverTheAir: this.disableOverTheAir,
            disbableBixbyPackages: this.disbableBixbyPackages,
            disableCarboardAppsOnGearVR: this.disableCarboardAppsOnGearVR,
            disableStatusBar: this.disableStatusBar,
            disableFirmwareUpgrades: this.disableFirmwareUpgrades,
          }
        };
        ProfileService.updateProfile(data)
          .then((response) => {
            this.$toast.success(response.data.respones_msg);
          })
          .catch((e) => {
            this.$toast.error(e.response.data.respones_msg);
            if (e.response.data.redirectToPaymentPage == true) {
              this.$router.push("plans");
            }
          });
      },
      updateApplication(packageName) {
        let data = {
          packageName: packageName,
          profileId: this.profileId,
          app_disable: (document.getElementById(packageName).checked) ? true : false
        };
        ProfileService.updateApplication(data)
          .then((response) => {
            this.$toast.success(response.data.respones_msg);
          })
          .catch((e) => {
            this.$toast.error(e.response.data.respones_msg);
            if (e.response.data.redirectToPaymentPage == true) {
              this.$router.push("plans");
            }
          });
      },
      syncApplications() {
        this.isDisabled = true;
        let data = { 
          profileId: this.profileId,
        };
        ProfileService.syncApplications(data)
          .then((response) => {
            this.isDisabled = false;
            this.profileDeviceDetail();
            this.profileDetail();
            this.$toast.success(response.data.respones_msg);
          })
          .catch((e) => {
            this.isDisabled = false;
            this.$toast.error(e.response.data.respones_msg);
            if (e.response.data.redirectToPaymentPage == true) {
              this.$router.push("plans");
            }
          });
      },
      applyPolicy() {
        this.isDisabled = true;
        let data = { 
          profileId: this.profileId,
        };
        ProfileService.applyPolicy(data)
          .then((response) => {
            this.isDisabled = false;
            this.profileDetail();
            this.$toast.success(response.data.respones_msg);
          })
          .catch((e) => {
            this.isDisabled = false;
            this.$toast.error(e.response.data.respones_msg);
            if (e.response.data.redirectToPaymentPage == true) {
              this.$router.push("plans");
            }
          });
      },
      filterApplications() {
        this.applications = this.applicationArrayBackup;
        if (this.searchPackageName) {
          this.applications = this.applications.filter(i => i.package_name.toLowerCase().indexOf(this.searchPackageName.toLowerCase()) > -1 || i.app_name.toLowerCase().indexOf(this.searchPackageName.toLowerCase()) > -1)          
        }
        if (this.searchApplicationStatus) {
          this.applications = this.applications.filter(i => i.app_disable == (this.searchApplicationStatus != "Enabled") ? true : false)          
        }
        if (this.showSystemApp == false) {
          this.applications = this.applications.filter(i => i.application_type != 1 ? true : false)          
        }
      },
      logoutDevice() {
        this.isDisabled = true;
        let data = { 
          serialNumber: this.profileData.name,
        };
        DeviceService.logoutDevice(data)
          .then((response) => {
            this.isDisabled = false;
            this.$toast.success(response.data.respones_msg);
          })
          .catch((e) => {
            this.isDisabled = false;
            this.$toast.error(e.response.data.respones_msg);
            if (e.response.data.redirectToPaymentPage == true) {
              this.$router.push("plans");
            }
          });
      }
  },
  beforeMount() {
    this.profileDetail();
    this.profileDeviceDetail();
  },
};
</script>
<style>
.tab-content {
  display: unset !important;
}
.md-switch .md-switch-label {
  color: unset !important;
}
</style>