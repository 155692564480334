<template>
  <div>
    <md-card>
        <md-card-content>
            <div class="row">
                <div class="col">
                    <md-field>
                        <label>Soruce</label>
                        <md-select v-model="filterByStatus">
                            <md-option value="">All Records</md-option>
                            <md-option value="true">Success</md-option>
                            <md-option value="false">Error</md-option>
                        </md-select>
                    </md-field>
                </div>
                <div class="col">
                    <md-button class="md-success" @click="getDashboardData()" :disabled="isDisabled">
                      Search<span v-if="isDisabled"><i class="fa fa-spinner fa-spin"></i></span></md-button>
                </div>
            </div>
        </md-card-content>
    </md-card>
    <div class="md-layout">
      <div class="md-layout-item md-medium-size-50 md-xsmall-size-100 md-size-33">
          <stats-card header-color="blue">
            <template slot="header">
              <div class="card-icon">
                <md-icon>login</md-icon>
              </div>
              <p class="category">App Login</p>
              <h3 class="title">
                <a href="javascript:void(0)" :style="{ color: 'blue !important' }" @click="$router.push({ path: 'admin-dashboard-history', query: { type: 'Login', source: 'App', hasError: filterByStatus }})"><span v-if="dashboardData">{{ dashboardData.todayAppLoginCount }}</span></a>
              </h3>
            </template>
          </stats-card>
        </div>
        <div class="md-layout-item md-medium-size-50 md-xsmall-size-100 md-size-33">
          <stats-card header-color="blue">
            <template slot="header">
              <div class="card-icon">
                <md-icon>login</md-icon>
              </div>
              <p class="category">Web Login</p>
              <h3 class="title">
                <a href="javascript:void(0)" :style="{ color: 'blue !important' }" @click="$router.push({ path: 'admin-dashboard-history', query: { type: 'Login', source: 'Web', hasError: filterByStatus }})"><span v-if="dashboardData">{{ dashboardData.todayWebLoginCount }}</span></a>
              </h3>
            </template>
          </stats-card>
        </div>
    </div>
    <div class="md-layout">
      <div class="md-layout-item md-size-50 md-small-size-100">
        <chart-card
          header-animation="false"
          :chart-data="weekWiseAppLoginBarChart.data"
          :chart-options="weekWiseAppLoginBarChart.options"
          :chart-responsive-options="weekWiseAppLoginBarChart.responsiveOptions"
          chart-type="Bar"
          header-icon
          chart-inside-content
          no-footer
          background-color="green"
        >
        <template slot="chartInsideHeader">
            <div class="card-icon">
              <md-icon>insert_chart</md-icon>
            </div>
            <h4 class="title">
              App Login
              <small> - Week Wise</small>
            </h4>
          </template>
        </chart-card>
      </div>
      <div class="md-layout-item md-size-50 md-small-size-100">
        <chart-card
          header-animation="false"
          :chart-data="weekWiseWebLoginBarChart.data"
          :chart-options="weekWiseWebLoginBarChart.options"
          :chart-responsive-options="weekWiseWebLoginBarChart.responsiveOptions"
          chart-type="Bar"
          header-icon
          chart-inside-content
          no-footer
          background-color="green"
        >
        <template slot="chartInsideHeader">
            <div class="card-icon">
              <md-icon>insert_chart</md-icon>
            </div>
            <h4 class="title">
              Web Login
              <small> - Week Wise</small>
            </h4>
          </template>
        </chart-card>
      </div>
    </div>
    <div class="md-layout">
      <div class="md-layout-item md-size-50 md-small-size-100">
        <chart-card
          header-animation="false"
          :chart-data="monthWiseAppLoginBarChart.data"
          :chart-options="monthWiseAppLoginBarChart.options"
          :chart-responsive-options="monthWiseAppLoginBarChart.responsiveOptions"
          chart-type="Bar"
          header-icon
          chart-inside-content
          no-footer
          background-color="green"
        >
        <template slot="chartInsideHeader">
            <div class="card-icon">
              <md-icon>insert_chart</md-icon>
            </div>
            <h4 class="title">
              App Login
              <small> - Month Wise</small>
            </h4>
          </template>
        </chart-card>
      </div>
      <div class="md-layout-item md-size-50 md-small-size-100">
        <chart-card
          header-animation="false"
          :chart-data="monthWiseWebLoginBarChart.data"
          :chart-options="monthWiseWebLoginBarChart.options"
          :chart-responsive-options="monthWiseWebLoginBarChart.responsiveOptions"
          chart-type="Bar"
          header-icon
          chart-inside-content
          no-footer
          background-color="green"
        >
        <template slot="chartInsideHeader">
            <div class="card-icon">
              <md-icon>insert_chart</md-icon>
            </div>
            <h4 class="title">
              Web Login
              <small> - Month Wise</small>
            </h4>
          </template>
        </chart-card>
      </div>
    </div>
  </div>
</template>
<script>
import {
  StatsCard,
  ChartCard,
} from "@/components";
import UserDataService from "../../services/SuperAdmin/UsersDataService";

export default {
  components: {
    StatsCard,
    ChartCard,
  },
  data() {
    return {
      isDisabled: false,
      dashboardData: null,
      monthWiseWebLoginBarChart: null,
      monthWiseAppLoginBarChart: null,
      weekWiseWebLoginBarChart: null,
      weekWiseAppLoginBarChart: null,

      filterByStatus:"",
    };
  },
  methods: {
    async getDashboardData() {
      this.isDisabled = true;
      var data = { hasError : this.filterByStatus };
      await UserDataService.getDashboardData(data)
        .then((response) => { 
            this.isDisabled = false;
            this.dashboardData = response.data.data;
        })
        .catch((e) => {
          this.isDisabled = false;
          if (e.response.data.is_logout == 1) {
            localStorage.setItem('super_admin_token', "");            
              this.$router.push('/super-admin/login');
            }
          this.$toast.error(e.response.data.respones_msg);
        });
    },
    async getMonthWiseGraphData() {
      this.isDisabled = true;
      let daysArray = [];
      const weekday = ["Sun","Mon","Tue","Wed","Thur","Fri","Sat"];
      const d = new Date();
      var wanteddays = 8;
      var temp = d.getDay()
      while(wanteddays!=0){
          if (temp <0)
          temp = 6;
          daysArray.push(weekday[temp])
          temp = temp-1;
          wanteddays = wanteddays -1;
      }
      var data = { hasError : this.filterByStatus };
      await UserDataService.getMonthWiseGraphData(data)
        .then((response) => { 
            this.isDisabled = false;
            this.monthWiseWebLoginBarChart = {
            data: {
              labels: ["Jan", "Feb", "March", "Apr", "May", "June", "July", "Aug", "Sept", "Oct", "Nov", "Dec"],
              series: [
                response.data.data.monthWiseWebLoginCount,
              ],
            },
            options: {
              height: "230px",
              seriesBarDistance: 10,
              axisX: {
                showGrid: false,
              },
            },
            responsiveOptions: [
              [
                "screen and (max-width: 640px)",
                {
                  seriesBarDistance: 5,
                  axisX: {
                    labelInterpolationFnc: function (value) {
                      return value[0];
                    },
                  },
                },
              ],
            ],
            }
            this.monthWiseAppLoginBarChart = {
            data: {
              labels: ["Jan", "Feb", "March", "Apr", "May", "June", "July", "Aug", "Sept", "Oct", "Nov", "Dec"],
              series: [
                response.data.data.monthWiseAppLoginCount,
              ],
            },
            options: {
              height: "230px",
              seriesBarDistance: 10,
              axisX: {
                showGrid: false,
              },
            },
            responsiveOptions: [
              [
                "screen and (max-width: 640px)",
                {
                  seriesBarDistance: 5,
                  axisX: {
                    labelInterpolationFnc: function (value) {
                      return value[0];
                    },
                  },
                },
              ],
            ],
            }
        })
        .catch((e) => {
          this.isDisabled = false;
          if (e.response.data.is_logout == 1) {
            localStorage.setItem('super_admin_token', "");            
              this.$router.push('/super-admin/login');
            }
          this.$toast.error(e.response.data.respones_msg);
        });
    },
    async getWeekWiseGraphData() {
      this.isDisabled = true;
      let daysArray = [];
      const weekday = ["Sun","Mon","Tue","Wed","Thur","Fri","Sat"];
      const d = new Date();
      var wanteddays = 8;
      var temp = d.getDay()
      while(wanteddays!=0){
          if (temp <0)
          temp = 6;
          daysArray.push(weekday[temp])
          temp = temp-1;
          wanteddays = wanteddays -1;
      }
      var data = { hasError : this.filterByStatus };
      await UserDataService.getWeekWiseGraphData(data)
        .then((response) => { 
            this.isDisabled = false;
            this.weekWiseWebLoginBarChart = {
            data: {
              labels: daysArray,
              series: [
                response.data.data.weekWiseWebLoginCount,
              ],
            },
            options: {
              height: "230px",
              seriesBarDistance: 10,
              axisX: {
                showGrid: false,
              },
            },
            responsiveOptions: [
              [
                "screen and (max-width: 640px)",
                {
                  seriesBarDistance: 5,
                  axisX: {
                    labelInterpolationFnc: function (value) {
                      return value[0];
                    },
                  },
                },
              ],
            ],
            }
            this.weekWiseAppLoginBarChart = {
            data: {
              labels: daysArray,
              series: [
                response.data.data.weekWiseAppLoginCount,
              ],
            },
            options: {
              height: "230px",
              seriesBarDistance: 10,
              axisX: {
                showGrid: false,
              },
            },
            responsiveOptions: [
              [
                "screen and (max-width: 640px)",
                {
                  seriesBarDistance: 5,
                  axisX: {
                    labelInterpolationFnc: function (value) {
                      return value[0];
                    },
                  },
                },
              ],
            ],
            }
        })
        .catch((e) => {
          this.isDisabled = false;
          if (e.response.data.is_logout == 1) {
            localStorage.setItem('super_admin_token', "");            
              this.$router.push('/super-admin/login');
            }
          this.$toast.error(e.response.data.respones_msg);
        });
    },
  },
  beforeMount() {
    this.getDashboardData();  
    this.getMonthWiseGraphData();  
    this.getWeekWiseGraphData();  
  },
};
</script>

<style>
.fs-12{
  font-size: 12px;
}
.md-card-actions{
  padding: unset !important;
}
</style>
