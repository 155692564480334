<template>
    <div>
        <md-card>
            <md-card-content>
                <ul>
                    <li><b>For Folder Deletetion : /Download/</b></li>
                    <li><b>For Delete All files in Directory : /Download/*</b></li>
                    <li><b>Single File Delete : /Download/abc.png</b></li>
                </ul>
                <div class="md-layout">
                    <div class="md-layout-item md-size-50">
                        <md-field>
                            <md-input type="text" placeholder="File Path to Delete" v-model="filePath"></md-input>
                        </md-field>
                    </div>
                    <div class="md-layout-item md-size-30">
                        <md-button class="md-primary" :disabled="isDisabled" @click="addProfileFiles()">Add</md-button> 
                    </div>
                </div>
                <div class="md-layout">
                    <div class="md-layout-item md-size-50">
                        <ul>
                            <li v-for="file in profileFileData" :key="file">{{ file }}  <a href="javascript:void(0)" @click="deleteProfileFile(file)"><md-icon :style="{ color: '#f44336 !important' }">delete</md-icon></a> </li>
                        </ul>
                    </div>
                </div>
            </md-card-content>
        </md-card>
    </div>
</template>
<script>
import Swal from "sweetalert2";
import ProfileService from "../../services/Customer/ProfileService";

export default {
  name: 'ProfileFilesTab',
  props: {
    profileId: String,
  },
  data() {
    return {
        filePath:"",
        profileFileData: [],
    }
  },
  methods: {
    profileDetail() {
        let data = { profileId: this.profileId }
        ProfileService.profileDetail(data)
          .then((response) => {
            this.profileFileData = response.data.data.profileFiles;
            //this.$toast.success(response.data.respones_msg);
          })
          .catch((e) => {
            this.$toast.error(e.response.data.respones_msg);
          });
    },
    addProfileFiles() {
        let data = { 
          profileId: this.profileId,
          filePath: this.filePath
        };
        ProfileService.addProfileFiles(data)
          .then((response) => {
            this.filePath = "";
            this.profileDetail();
            this.$toast.success(response.data.respones_msg);
          })
          .catch((e) => {
            this.$toast.error(e.response.data.respones_msg);
            if (e.response.data.redirectToPaymentPage == true) {
              this.$router.push("plans");
            }
          });
    },
    deleteProfileFile(filePath) {
        let data = { 
          profileId: this.profileId,
          filePath: filePath
        };
        ProfileService.deleteProfileFile(data)
          .then((response) => {
            this.profileDetail();
            this.$toast.success(response.data.respones_msg);
          })
          .catch((e) => {
            this.$toast.error(e.response.data.respones_msg);
            if (e.response.data.redirectToPaymentPage == true) {
              this.$router.push("plans");
            }
          });
    }
  },
  beforeMount() {
    this.profileDetail();
  },
}
</script>