<template>
    <div>
        <md-card>
            <md-card-content>
                <form ref="createVideoForm">
                    <div class="row">
                        <div class="col" >
                            <md-field>
                                <label>Source</label>
                                <md-select v-model="source">
                                    <md-option value="">All</md-option>
                                    <md-option value="Web">Web</md-option>
                                    <md-option value="App">App</md-option>
                                </md-select>
                            </md-field>
                        </div>
                        <div class="col">
                            <md-field>
                                <label>Type</label>
                                <md-select v-model="type">
                                    <md-option value="">All</md-option>
                                    <md-option value="Login">Login</md-option>
                                </md-select>
                            </md-field>
                        </div>
                        <div class="col">
                            <md-field>
                                <label>Status</label>
                                <md-select v-model="hasError">
                                    <md-option value="">All</md-option>
                                    <md-option value="true">Success</md-option>
                                    <md-option value="false">Error</md-option>
                                </md-select>
                            </md-field>
                        </div>
                        <div class="col">
                            <md-field>
                                <label>Email</label>
                                <md-input v-model="email" type="text"></md-input>
                            </md-field>
                        </div>
                        <div class="col">
                            <md-button class="md-success" :disabled="isDisabled" @click="getDashboardHistory()">Search</md-button>
                        </div>
                    </div>
                </form>
            </md-card-content>
        </md-card>
        <md-card>
            <md-card-content>
                <div class="md-layout">
                    <div class="md-layout-item md-size-100">
                        <md-table :value="queriedData" class="paginated-table table-striped table-hover" 
                            table-header-color="green" md-fixed-header>
                            <md-table-toolbar>
                                <md-field>
                                    <label for="pages">Per page</label>
                                    <md-select v-model="pagination.perPage" name="pages">
                                    <md-option
                                        v-for="item in pagination.perPageOptions"
                                        :key="item"
                                        :label="item"
                                        :value="item"
                                    >
                                        {{ item }}
                                    </md-option>
                                    </md-select>
                                </md-field>
                            </md-table-toolbar>
                            <md-table-row slot="md-table-row" slot-scope="{ item }">            
                                <md-table-cell md-label="Name"> <a href="javascript:void(0)" @click="ByPassUserLogin(item.created_by)">{{ item.created_by }}</a> </md-table-cell>
                                <md-table-cell md-label="Source"><b> {{ item.source }} </b> </md-table-cell>
                                <md-table-cell md-label="Has Error">
                                    <span class="badge-danger" v-if="item.hasError">Error</span>
                                    <span class="badge-success" v-else>Success</span>
                                </md-table-cell>
                                <md-table-cell md-label="Message"><b> {{ item.message }} </b> </md-table-cell>
                                <md-table-cell md-label="Created On">{{ HumanReadableDateFormat(item.created_at) }}</md-table-cell>
                            </md-table-row>
                        </md-table>
                    </div>
                </div>
            </md-card-content>
            <md-card-actions md-alignment="space-between">
                <div class="">
                    <p class="card-category">
                    Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
                    </p>
                </div>
                <pagination
                    class="pagination-no-border pagination-success"
                    v-model="pagination.currentPage"
                    :per-page="pagination.perPage"
                    :total="total"
                >
                </pagination>
            </md-card-actions>
        </md-card>
    </div>
</template>
<script>
import UserDataService from "../../services/SuperAdmin/UsersDataService";
import CustomerService from "../../services/SuperAdmin/CustomerService";
import { Pagination } from "@/components";

export default {
  components: {
    Pagination,
  },
  computed: {
    /***
     * Returns a page from the searched data or the whole data. Search is performed in the watch section below
     */
    queriedData() {
      let result = this.tableData;
      if (this.searchedData.length > 0) {
        result = this.searchedData;
      }
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.tableData.length;
    },
  },
  data() {
    return {
      isDisabled: false,
      tableData: [],
      email: "",
      type:this.$route.query.type,
      from_date: new Date().toISOString().split('T')[0],
      to_date: new Date().toISOString().split('T')[0],
      zeroSuccessPayment: false,
      source: this.$route.query.source,
      hasError:this.$route.query.hasError,
      
      //Pagination
      pagination: {
        perPage: 25,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0,
      },
      searchedData: [],
    };
  },
  methods: {
      getDashboardHistory() {
        let data = {  source: this.source, type: this.type, email: this.email, hasError: this.hasError  }
        UserDataService.getDashboardHistory(data)
          .then((response) => {
            this.tableData = response.data.data;
            this.$toast.success(response.data.respones_msg);
          })
          .catch((e) => {
            this.$toast.error(e.response.data.respones_msg);
          });
      },
      ByPassUserLogin(email) {
        let data = {
            email: email,
        }
        CustomerService.ByPassUserLogin(data)
        .then((response) => {
            localStorage.setItem('token', response.data.data.token);
            let routeData = this.$router.resolve({name: 'dashboard'});
            window.open(routeData.href, '_blank');
        })
        .catch((e) => {
          this.$toast.error(e.response.data.respones_msg);
        });
    },
  },
  beforeMount() {
    this.getDashboardHistory();
  },
};
</script>
