<template>
    <div>
        <!--<div class="alert alert-danger" role="alert" v-if="paymentDetail.subscriptionType != 'one_time'">
            Please initalize payment refund from stripe, from this page refund will be manage for our internal system
        </div>-->
        <p><b>Email : {{ paymentDetail.email }}</b></p>
        <p><b>Product Name : {{ paymentDetail.productName }}</b></p>
        <md-card>
            <md-card-content>
                <form>
                    <div class="row">
                        <div class="col">
                            <h5><b>Choose a device?</b></h5>
                            <md-checkbox v-for="device in devices" :key="device.id" v-model="refundDevices" :value="device._id"><p :style="{ color: 'black' }">{{ device.deviceName }}</p></md-checkbox>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <md-field>
                                <label>Refund Qty</label>
                                <md-input type="number" disabled v-model="refundQty"></md-input>
                            </md-field>
                        </div>
                        <div class="col">
                            <md-field>
                                <label>Paid Amount</label>
                                <md-input type="number" disabled  :value="paymentDetail.totalAmount"></md-input>
                            </md-field>
                        </div>
                        <div class="col">
                            <md-field>
                                <label>Refund Amount</label>
                                <md-input type="number" :disabled="paymentDetail.subscriptionType == 'recurring'" v-model="refundAmount"></md-input>
                            </md-field>
                        </div>
                        <div class="col">
                            <md-button class="md-success" :disabled="isDisabled" @click="refundPayment()">Refund
                                <span v-if="isDisabled"><i class="fa fa-spinner fa-spin"></i></span>
                            </md-button>
                        </div>
                    </div>
                </form>
            </md-card-content>
        </md-card>
    </div>
</template>
<script>
import PaymentService from "../../../services/SuperAdmin/PaymentService";

export default {
  components: {
  },
  data() {
    return {
      isDisabled: false,
      id: this.$route.query.id,
      customerId: this.$route.query.customerId,
      paymentDetail: null,
      devices: [],
      refundDevices: [],
      refundQty: 0,
      refundAmount: 0,
    };
  },
  methods: {
      getPaymentDetail() {
        let data = { id: this.id };
        PaymentService.subscriptionDetail(data)
        .then((response) => {
            this.paymentDetail = response.data.data
            this.refundQty = this.paymentDetail.qty;
            this.refundAmount = response.data.proratedRefundAmount;
            /*if (this.paymentDetail.subscriptionType == "recurring") {
                this.refundAmount = response.data.proratedRefundAmount;
            } else {
                this.refundAmount = this.paymentDetail.totalAmount;
            }*/
            this.devices = response.data.devices
            this.$toast.success(response.data.respones_msg);
        }).catch((e) => {
            this.isDisabled = false;
            this.$toast.error(e.response.data.respones_msg);
        });
      },
      refundPayment() {
        /*if (this.devices.length > 0 && this.refundDevices.length == 0) {
            this.$toast.error("Please select device to refund");
            return;
        }
        if (this.devices.length >= this.refundQty) {
            if (this.refundQty != this.refundDevices.length) {
                this.$toast.error("Please make sure devices according to refund qty");
                return;          
            }
        }*/
        this.isDisabled = true;
        let data = {
            id: this.paymentDetail._id,
            refundQty: this.refundQty,
            refundAmount: this.refundAmount,
            refundDevices: this.refundDevices
        }
        PaymentService.refundPayment(data)
        .then((response) => {
            this.isDisabled = false;
            this.$router.push({ path: '/admin-customer-subscription-create', query: { id: this.customerId }});
            this.$toast.success(response.data.respones_msg);
        }).catch((e) => {
            this.isDisabled = false;
            this.$toast.error(e.response.data.respones_msg);
        });
      }
  },
  beforeMount() {
    this.getPaymentDetail();
  },
};
</script>
