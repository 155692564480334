<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-100">
    <p><b>Export Name: </b>{{ exportDetail.exportName }}</p>
      <md-card>
        <md-card-content>
            <div class="row">
                <div class="col-4" v-for="app in exportDetail.disabledApplications" :key="app">{{ app }} - <a href="javascript:void(0)" @click="deleteApplication(app)"><md-icon class="text-danger">delete</md-icon></a></div>
            </div>
        </md-card-content>
      </md-card>
    </div>
  </div>
</template>
<script>
import UserDataService from "../services/UsersDataService";

export default {
  components: {
    
  },
  data() {
    return {
      isDisabled: false,
      id: this.$route.query.id,
      exportDetail: null,
    };
  },
  methods: {
    getExportDetail() {
        let data = { id: this.id };
        UserDataService.getExportDetail(data)
        .then((response) => {
          this.exportDetail = response.data.data;
        }).catch((e) => {
          this.$toast.error(e);
        });
    },
    deleteApplication(app) {
        let data = { id: this.id, app: app };
        UserDataService.deleteApplication(data)
        .then((response) => {
          this.getExportDetail();
        }).catch((e) => {
          this.$toast.error(e);
        });
    }
  },
  beforeMount() {
    this.getExportDetail();
  },
};
</script>
<style scoped>
</style>
