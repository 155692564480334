<template>
    <div>
        <div style="float: right">
          <md-button class="md-primary" @click="downloadApk()">Download APK</md-button>
          <md-button class="md-warning" :disabled="isDisabled" @click="syncUsers()">Sync
            <span v-if="isDisabled"><i class="fa fa-spinner fa-spin"></i></span>
          </md-button>
          <md-button class="md-success" @click="$router.push({ path: 'power-chat-user-create'})">Create User</md-button>
        </div>
        <md-card>
            <md-card-content>
                <div class="md-layout">
                    <div class="md-layout-item md-size-100" v-if="tableData.length == 0">
                      <p>
                        <b>Power Chat</b> is an instant messaging and voice-over-IP service owned by Package Disabler. It allows users to send text, voice messages and video messages and share images, documents and other content.
                        <a href="https://www.packagedisabler.com/power-chat">Read More</a>
                      </p>
                      <ul>
                        <li>
                          <b>Privacy/Security</b>
                          <ul>
                            <li>Full End to End encryption</li>
                            <li>Data security compliant</li>
                            <li>No Metadata used</li>
                            <li>APP PIN lock</li>
                          </ul>
                        </li>
                        <li>
                          <b>Guarantee Anonymity</b>
                          <ul>
                            <li>Virtual user name support</li>
                            <li>Ability to add only known contacts</li>
                            <li>Voice modulation</li>
                          </ul>
                        </li>
                        <li>
                          <b>Safe storage</b>
                          <ul>
                            <li>All media can be stored in application data store</li>
                            <li>No access to media from file system</li>
                            <li>Password lock for Safe store</li>
                          </ul>
                        </li>
                        <li>
                          <b>Cloud based user management </b>
                          <ul>
                            <li>Create/manage users</li>
                            <li>QR code based enrolment</li>
                            <li>Purchase licenses as and when needed</li>
                          </ul>
                        </li>
                      </ul>
                    </div>
                    <div class="md-layout-item md-size-100" v-else>
                        <md-table v-if="tableData.length > 0 " 
                          v-model="tableData" 
                          class="md-container-custom" table-header-color="green">
                            <md-table-row slot="md-table-row" slot-scope="{ item }">            
                                <md-table-cell md-label="Name">
                                  {{ item.fcmData.name }}
                                  <span class="badge-danger" v-if="item.isTrialUser == true">Trial</span>
                                </md-table-cell>
                                <md-table-cell md-label="User ID">{{ item.fcmData.user_id }}</md-table-cell>
                                <md-table-cell md-label="Created On">{{ HumanReadableDateFormat(item.created_at) }}</md-table-cell>
                                <md-table-cell md-label="Actions">
                                <div style="di`splay: block ruby;">
                                  <md-button class="md-just-icon md-primary m-0 md-simple" @click="openQrCodeModal(item._id, item.fcmData.name, 'login')"><md-icon title="Login QR Code">qr_code</md-icon></md-button>
                                  <md-button class="md-just-icon md-primary m-0 md-simple" @click="openQrCodeModal(item._id, item.fcmData.name, 'userAdd')"><md-icon title="Add User QR Code">person_add</md-icon></md-button>&nbsp;
                                  <md-button class="md-just-icon md-danger m-0 md-simple" @click="deleteFcmUser(item._id)"><md-icon>close</md-icon></md-button>&nbsp;
                                </div>
                              </md-table-cell>`
                            </md-table-row>
                        </md-table>
                        <h4 v-else>No data found.</h4>
                    </div>
                </div>
            </md-card-content>
        </md-card>
        <!-- Models -->
      <template>
          <modal v-if="qrCodeModal" @close="qrCodeModal = false">
            <template slot="header">
              <h4 class="modal-title">QR Code For {{ QrCodeName }}</h4>
              <md-button
                style="height: 25px" class="md-simple md-just-icon md-round modal-default-button"
                @click="qrCodeModal = false"><md-icon>clear</md-icon></md-button>
            </template>
            <template slot="body">
              <img :src="QrCodeUrl" />
            </template>
            <template slot="footer">
              <md-button class="md-danger md-simple" @click="qrCodeModal = false">Close</md-button>
            </template>
          </modal>
      </template>
    </div>
</template>
<script>
import FcmService from "../../services/Customer/FcmService";
import { Modal } from "@/components";
import Swal from "sweetalert2";

export default {
  components: {
    Modal
  },
  data() {
    return {
      isDisabled: false,
      tableData: [],
      qrCodeModal: false,
      QrCodeUrl: "",
      QrCodeName: "",

      //Sorting
      currentSort: "created_at",
      currentSortOrder: "asc",
    };
  },
  methods: {
      customSort(value) {
        return value.sort((a, b) => {
          const sortBy = this.currentSort;
          if (this.currentSortOrder === "desc") {
            return a[sortBy].localeCompare(b[sortBy]);
          }
          return b[sortBy].localeCompare(a[sortBy]);
        });
      },
      listUsers() {
        this.isDisabled = true;
        FcmService.listUsers()
          .then((response) => {
            this.isDisabled = false;
            this.tableData = response.data.data;
            this.$toast.success(response.data.respones_msg);
          })
          .catch((e) => {
            this.isDisabled = false;
            this.$toast.error(e.response.data.respones_msg);
          });
      },
      openQrCodeModal(id,name, type) {
        if (type == "login") {
          this.QrCodeUrl = process.env.VUE_APP_API_BASE_URL + "upload/qrCode/fcm/" + id + ".png"
        } else {
          this.QrCodeUrl = process.env.VUE_APP_API_BASE_URL + "upload/qrCode/fcm/user-add/" + id + ".png"
        }
        this.QrCodeName = name;
        this.qrCodeModal = true;
      },
      deleteFcmUser(id) {
        Swal.fire({
          title: "Are you sure?",
          text: `You won't be able to revert this!`,
          type: "warning",
          showCancelButton: true,
          confirmButtonClass: "md-button md-success",
          cancelButtonClass: "md-button md-danger",
          confirmButtonText: "Yes, delete it!",
          buttonsStyling: false,
        }).then((result) => {
          if (result.value) {
            FcmService.deleteFcmUser({ id:id })
            .then((response) => {
              this.listUsers();
              this.$toast.success(response.data.respones_msg);
            })
            .catch((e) => {
              this.$toast.error(e.response.data.respones_msg);
            });
          }
        });
      },
      syncUsers() {
        this.isDisabled = true;
        FcmService.syncUsers()
          .then((response) => {
            this.isDisabled = false;
            this.listUsers();
            this.$toast.success(response.data.respones_msg);
          })
          .catch((e) => {
            this.isDisabled = false;
            this.$toast.error(e.response.data.respones_msg);
          });
      },
      downloadApk() {
        window.location = "https://www.packagedisabler.com/PCHAT.apk"
      }
  },
  beforeMount() {
    this.listUsers();
  },
};
</script>