<template>
    <div>
        <div style="float: right">
          <md-button class="md-success" :disabled="isDisabled" @click="createCustomer()">Save
            <span v-if="isDisabled"><i class="fa fa-spinner fa-spin"></i></span>
          </md-button>
        </div>
        <md-card>
            <md-card-content>
                <form ref="createCustomerForm">
                    <div class="row">
                        <div class="col">
                            <md-field>
                                <label>Name</label>
                                <md-input v-model="name" type="text"></md-input>
                            </md-field>
                        </div>
                        <div class="col">
                            <md-field>
                                <label>User ID</label>
                                <md-input v-model="userId" type="text"></md-input>
                            </md-field>
                        </div>
                        <div class="col">
                            <md-field>
                                <label>Password</label>
                                <md-input v-model="password" type="password"></md-input>
                            </md-field>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <md-switch v-model="addToGlobalPull">Add To Global Company</md-switch>
                        </div>
                    </div>
                </form>
            </md-card-content>
        </md-card>
    </div>
</template>
<script>
import FcmService from "../../services/Customer/FcmService";

export default {
  components: {
  },
  data() {
    return {
      isDisabled: false,
      name: "",
      password: "",
      //companyName: "",
      disabledCompany: false,
      userId:"",
      userExist: null,
      addToGlobalPull: true,
    };
  },
  methods: {
      createCustomer() {
        this.isDisabled = true;
        let data = {
            name: this.name,
            password: this.password,
            //company: this.companyName,
            user_id: this.userId,
            addToGlobalPull: this.addToGlobalPull
        }
        FcmService.createUsers(data)
        .then((response) => {
            this.isDisabled = false;
            this.$toast.success(response.data.respones_msg);
            this.$router.push("power-chat-users");
        })
        .catch((e) => {
            this.isDisabled = false;
            this.$toast.error(e.response.data.respones_msg);
            if (e.response.data.redirectToPaymentPage) {
                if (e.response.data.hasOwnProperty('isSubscriptionUpdate')) {
                    return this.$router.push({ path: 'update-subscription', query: { id: process.env.VUE_APP_STRIPE_PCHAT_PRICE_SUBSCRIPTION }})    
                }
                let qty = (e.response.data.hasOwnProperty('requiredPurchasedQty')) ? e.response.data.requiredPurchasedQty : 1
                this.$router.push({ path: 'make-payment', query: { id: process.env.VUE_APP_STRIPE_PCHAT_PRICE_SUBSCRIPTION, qty: qty }})
            }
        });
    },
    userDetail() {
        this.isDisabled = true;
        FcmService.userDetail()
        .then((response) => {
            this.isDisabled = false;
            this.userExist = response.data.data;
            if (this.userExist) {
                this.companyName = this.userExist.fcmData.company
                this.disabledCompany = true;
            }
            this.$toast.success(response.data.respones_msg);
        }).catch((e) => {
            this.isDisabled = false;
            this.$toast.error(e.response.data.respones_msg);
        });
    },
  },
  beforeMount() {
    this.createCustomer();
    //this.userDetail();
  },
};
</script>
