<template>
    <div>
        <md-card>
            <md-card-content>
                <div style="float: right">
                    <md-button class="md-success" :disabled="isDisabled" @click="updatePOBSetting()">
                    Save<span v-if="isDisabled"><i class="fa fa-spinner fa-spin"></i></span></md-button>
                </div>
                <br><span><b>Profile Counter : {{ this.profileDetail.profileCounter }}</b></span>
                <div class="row">
                    <div class="col-md-4">
                        <md-switch v-model="enablePowerOnBoot">Enabled Power On Boot</md-switch>
                    </div>
                    <div class="col-md-4">
                        <md-switch v-model="shutdownOnDisconnect">Shutdown On Disconnect</md-switch>
                    </div>
                </div>
                <h4 class="text-primary"><b>Schedule Shutdown</b></h4>
                <div class="row">
                    <div class="col-md-4">
                        <md-radio v-model="scheduleType" value="Daily"><p :style="{ color: 'black' }">Daily</p></md-radio>
                        <md-radio v-model="scheduleType" value="Weekly"><p :style="{ color: 'black' }">Weekly</p></md-radio>
                    </div>
                </div>
                <div class="row" v-if="scheduleType == 'Weekly'">
                    <div class="col">
                        <md-checkbox v-model="days" value="Monday"><p :style="{ color: 'black' }">Monday</p></md-checkbox>
                        <md-checkbox v-model="days" value="Tuesday"><p :style="{ color: 'black' }">Tuesday</p></md-checkbox>
                        <md-checkbox v-model="days" value="Wednesday"><p :style="{ color: 'black' }">Wednesday</p></md-checkbox>
                        <md-checkbox v-model="days" value="Thursday"><p :style="{ color: 'black' }">Thursday</p></md-checkbox>
                        <md-checkbox v-model="days" value="Friday"><p :style="{ color: 'black' }">Friday</p></md-checkbox>
                        <md-checkbox v-model="days" value="Saturday"><p :style="{ color: 'black' }">Saturday</p></md-checkbox>
                        <md-checkbox v-model="days" value="Sunday"><p :style="{ color: 'black' }">Sunday</p></md-checkbox>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                      <b>Time</b><input type="time" class="form-control" v-model="scheduleTime">
                    </div>
                </div>
            </md-card-content>
        </md-card>
    </div>
</template>
<script>
import ProfileService from "../../services/Customer/ProfileService";

export default {
  name: 'POBTab',
  props: {
    profileId: String,
    profileDetail: Object,
  },
  data() {
    return {
        enablePowerOnBoot: this.profileDetail.enablePowerOnBoot,
        shutdownOnDisconnect: this.profileDetail.shutdownOnDisconnect,
        scheduleType: (this.profileDetail.scheduleShutdown) ? this.profileDetail.scheduleShutdown.scheduleType : "",
        scheduleTime: (this.profileDetail.scheduleShutdown) ? this.profileDetail.scheduleShutdown.scheduleTime : "",
        days: (this.profileDetail.scheduleShutdown) ? this.profileDetail.scheduleShutdown.days : [],
    }
  },
  methods: {
    updatePOBSetting() {
        let data = { 
          profileId: this.profileId,
          enablePowerOnBoot: this.enablePowerOnBoot,
          shutdownOnDisconnect: this.shutdownOnDisconnect,
          scheduleShutdown: {
            scheduleType: this.scheduleType,
            scheduleTime: this.scheduleTime,
            days: this.days,
          }
        };
        ProfileService.updatePOBSetting(data)
          .then((response) => {
            this.$toast.success(response.data.respones_msg);
          })
          .catch((e) => {
            this.$toast.error(e.response.data.respones_msg);
            if (e.response.data.redirectToPaymentPage == true) {
              this.$router.push("plans");
            }
          });
      },
  },
  beforeMount() {
  },
}
</script>