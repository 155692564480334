import http from "../../http-common-super-admin";

class CustomerService {
  list(data) {
    return http.get("customers/list", { params: data });
  }
  ByPassUserLogin(data) {
    return http.post("customers/bypass-login", data);
  }
  createCustomer(data) {
    return http.post("customers/create", data);
  }
  detail(data) {
    return http.get("customers/detail", { params: data });
  }
  updateCustomer(data) {
    return http.post("customers/update", data);
  }
  powerChatUsers(data) {
    return http.get("customers/fcm/list", { params: data });
  }
  bloatwareApplicationList(data) {
    return http.get("customers/bloatware/application/list", { params: data });
  }
  exportList(data) {
    return http.get("customers/exports/list", { params: data });
  }
  getAuditLogs(data) {
    return http.get("customers/audit/list", { params: data });
  }
  resetDeviceCount(data) {
    return http.post("customers/reset/deletedDeviceDate", data);
  }
  updateWhatsappCount(data) {
    return http.post("customers/updateWhatsappCount", data);
  }
}

export default new CustomerService();