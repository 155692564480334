<template>
    <div>
        <md-card>
            <md-card-content>
                <div style="float: right">
                    <md-button class="md-success" :disabled="isDisabled" @click="updateKioskSetting()">
                    Save<span v-if="isDisabled"><i class="fa fa-spinner fa-spin"></i></span></md-button>
                </div>
                <br><span><b>Profile Counter : {{ this.profileDetail.profileCounter }}</b></span>
                <div class="row">
                    <div class="col">
                        <md-radio v-model="kioskType" value="None"><p :style="{ color: 'black' }">None</p></md-radio>
                        <md-radio v-model="kioskType" value="Single App"><p :style="{ color: 'black' }">Single App</p></md-radio>
                        <md-radio v-model="kioskType" value="Multi App"><p :style="{ color: 'black' }">Multi App</p></md-radio>
                        <md-radio v-model="kioskType" value="Browser"><p :style="{ color: 'black' }">Browser</p></md-radio>
                        <md-radio v-model="kioskType" value="Signage"><p :style="{ color: 'black' }">Signage</p></md-radio>
                    </div>
                </div>

                <div v-if="kioskType == 'Single App' || kioskType == 'Multi App'">
                  <div class="md-layout"> 
                    <div class="md-layout-item md-size-30">
                        <md-field>
                            <md-input type="text" placeholder="Search Applications to Add" v-model="searchPackageName"></md-input>
                        </md-field>
                    </div>
                    <div class="md-layout-item md-size-30">
                        <md-button class="md-primary" :disabled="isDisabled" @click="searchPlayStoreApps()">Search</md-button> 
                    </div>
                  </div>
                  <div class="md-layout">
                    <div class="app-listing-copy" v-if="playStoreApp">
                      <ul id="product-directory">
                          <!-- Play Store Application Append -->
                          <li v-for="app in playStoreApp" :key="app.appId">
                              <img :src="app.icon">
                              <a class="badge-icon" href="javascript:void(0);" :style="{ color: 'black' }" @click="createProfileApplication(app)">
                                  <i class="fa fa-plus" aria-hidden="true"></i>
                              </a>
                              <div class="app-listing-text">
                                  <span class="apptext" :title="app.package_name"><small><b>{{app.title }}</b>  ({{ app.appId }})</small></span>
                              </div>
                          </li>
                      </ul>
                    </div>
                  </div>
              </div>

              <div class="md-layout">
                <div class="md-layout-item md-size-100 kiosk-div">
                    
                    <!-- Single App Kiosk-->  
                    <div class="md-layout-item md-size-100" v-if="kioskType == 'Single App'">
                      <div v-if="profileApplications.length > 0">
                        <h4><b>Select one application to lockdown your devices.</b></h4>
                        <ul id="product-directory">
                          <li class="li-class" v-for="app in profileApplications" :key="app._id">
                            <input type="radio" class="radio-d-none" name="singleAppKioskPackageName" v-model="singleAppKioskPackageName" :value="app.appPackageName" :id="app._id" />
                            <label class="label" :for="app._id"><img :src="app.appDetails.icon" class="app-icon-single" /></label>
                            <span class="apptext">
                              <small><b>{{ app.appDetails.title }}</b></small>
                            </span> 
                          </li>
                        </ul>
                      </div>
                    </div>

                    <!-- Multi App Kiosk-->
                    <div class="md-layout-item md-size-100" v-if="kioskType == 'Multi App'">
                      <div class="md-layout">
                        <div class="md-layout-item md-size-100">
                          <p><small class="text-danger"><b>Note: Listed application will be force installed.</b></small></p>
                        </div>
                            <div v-if="profileApplications.length > 0">
                              <ul id="product-directory">
                                <li class="li-class" v-for="app in profileApplications" :key="app._id">
                                    <label class="label" :for="app._id"><img :src=app.appDetails.icon class="app-icon-single" /></label>
                                    <a class="badge-icon" href="javascript:void(0);" style="color:black;background:#f1c7c5;" @click="deleteProfileApplication(app._id)">
                                      <i class="fa fa-window-close" aria-hidden="true"></i>
                                    </a>
                                    <span class="apptext">
                                      <small><b>{{ app.appDetails.title }}</b></small>
                                    </span>
                                </li>
                              </ul>
                        </div>
                      </div>  
                    </div>

                    <!-- Browser App Kiosk-->
                    <div class="md-layout-item md-size-100" v-if="kioskType == 'Browser'">
                      <div class="md-layout">
                        <div class="md-layout-item md-size-100">
                            <md-field>
                              <label>Website Url</label>
                              <md-input type="text" v-model="browserKioskWebsiteUrl"></md-input>
                            </md-field>
                        </div>
                      </div>
                      <div class="md-layout">
                        <div class="md-layout-item md-size-50">
                            <md-field>
                              <label>Screen Timeout 
                                <div class="btn btn-primary tooltip"><i class="fa fa-info-circle" aria-hidden="true"></i>
                                    <div class="bottom">
                                        <p>Pass number in <b>Seconds</b>.</p>
                                    </div>
                                </div>
                              </label>
                              <md-input type="number" v-model="browserKioskScreenTimeout"></md-input>
                            </md-field>
                        </div>
                        <div class="md-layout-item md-size-50">
                            <md-field>
                              <label>Screen Brightness
                                <div class="btn btn-primary tooltip"><i class="fa fa-info-circle" aria-hidden="true"></i>
                                    <div class="bottom">
                                        <p>Pass number in <b>0 to 255</b>.</p>
                                    </div>
                                </div>
                              </label>
                              <md-input type="number" v-model="browserKioskScreenBrightness" max="255"></md-input>
                            </md-field>
                        </div>
                      </div>
                      <div class="md-layout">
                        <div class="md-layout-item md-size-50">
                            <md-field>
                              <label>Screen Orientation</label>
                              <md-select v-model="browserKioskScreenOrientation">
                                <md-option value="Auto">Auto</md-option>
                                <md-option value="Portrait">Portrait</md-option>
                                <md-option value="Landscape">Landscape</md-option>
                              </md-select>
                            </md-field>
                        </div>
                        <div class="md-layout-item md-size-50">
                            <md-checkbox class="custom-checkbox" v-model="browserKioskShowAddressBar">Show Address Bar</md-checkbox>
                        </div>
                      </div>
                    </div>

                    <!-- Signage Kiosk-->
                    <div class="md-layout-item md-size-100" v-if="kioskType == 'Signage'">
                      <div class="md-layout">
                        <div class="md-layout-item md-size-50">
                            <md-field>
                              <label>Screen Timeout
                                <div class="btn btn-primary tooltip"><i class="fa fa-info-circle" aria-hidden="true"></i>
                                    <div class="bottom">
                                        <p>Pass number in <b>Seconds</b>.</p>
                                    </div>
                                </div>
                              </label>
                              <md-input type="number" v-model="signageKioskScreenTimeout"></md-input>
                            </md-field>
                        </div>
                        <div class="md-layout-item md-size-50">
                            <md-field>
                              <label>Screen Brightness
                                <div class="btn btn-primary tooltip"><i class="fa fa-info-circle" aria-hidden="true"></i>
                                    <div class="bottom">
                                        <p>Pass number in <b>0 to 255</b>.</p>
                                    </div>
                                </div>
                              </label>
                              <md-input type="number" v-model="signageKioskScreenBrightness"></md-input>
                            </md-field>
                        </div>
                      </div>
                      <div class="md-layout">
                        <div class="md-layout-item md-size-50">
                            <md-field>
                              <label>Screen Orientation</label>
                              <md-select v-model="signageKioskScreenOrientation">
                                <md-option value="Auto">Auto</md-option>
                                <md-option value="Portrait">Portrait</md-option>
                                <md-option value="Landscape">Landscape</md-option>
                              </md-select>
                            </md-field>
                        </div>
                      </div><br>
                      <div class="md-layout">
                        <div v-for="(textField, i) in textFields" :key="i" style="width: 50%" >
                          <div class="md-layout-item text-fields-row">
                            <md-field>
                              <label>{{ textField.label1 }}</label>
                              <md-input type="text" v-model="textField.value1" name="video_list[]" ></md-input>
                            </md-field>
                            <md-button class="md-just-icon md-danger" @click="deleteTextBox(i)">
                                <i class="fa fa-trash" aria-hidden="true"></i>
                            </md-button>
                          </div>
                        </div>
                      </div>
                      <md-button class="md-primary" @click="addTextBox">Add Video</md-button>
                    </div>
                </div>
              </div>
            </md-card-content>
        </md-card>
    </div>
</template>
<script>
import ProfileService from "../../services/Customer/ProfileService";
import DeviceService from "../../services/Customer/DeviceService";
import Swal from "sweetalert2";

export default {
  name: 'KioskTab',
  props: {
    profileId: String,
    profileDetail: Object,
  },
  data() {
    return {
        isDisabled: false,
        kioskType: this.profileDetail.kioskType,
        searchPackageName:"",
        playStoreApp: [],
        profileApplications: [],
        singleAppKioskPackageName: this.profileDetail.singleAppKioskPackageName,

        //Browser Kiosk
        browserKioskWebsiteUrl: this.profileDetail.browserKioskWebsiteUrl,
        browserKioskScreenTimeout: this.profileDetail.browserKioskScreenTimeout,
        browserKioskScreenBrightness: this.profileDetail.browserKioskScreenBrightness,
        browserKioskScreenOrientation: this.profileDetail.browserKioskScreenOrientation,
        browserKioskShowAddressBar: this.profileDetail.browserKioskShowAddressBar,

        //Sinage Kiosk
        textFields: [],
        signageKioskScreenTimeout: this.profileDetail.signageKioskScreenTimeout,
        signageKioskScreenBrightness: this.profileDetail.signageKioskScreenBrightness,
        signageKioskScreenOrientation: this.profileDetail.signageKioskScreenOrientation,
    }
  },
  methods: {
      searchPlayStoreApps() {
        this.isDisabled = true;
        let data = { app_name: this.searchPackageName }
        DeviceService.searchPlayStoreApps(data)
          .then((response) => {
            this.isDisabled = false;
            this.playStoreApp = response.data.data;
            this.$toast.success(response.data.respones_msg);
          })
          .catch((e) => {
            this.isDisabled = false;
            this.$toast.error(e.response.data.respones_msg);
          });
      },
      createProfileApplication(app) {
        let data = { profileId: this.profileId, appPackageName: app.appId, appIcon: app.icon, status: null, appDetails: app, productType: "Super Lock" }
        ProfileService.createProfileApplication(data)
          .then((response) => {
            this.$toast.success(response.data.respones_msg);
            this.profileApplicationsList();
            this.playStoreApp = [];
            this.searchPackageName = "";
          })
          .catch((e) => {
            this.$toast.error(e.response.data.respones_msg);
            if (e.response.data.redirectToPaymentPage == true) {
              this.$router.push("plans");
            }
          });
      },
      profileApplicationsList() {
        let data = { profileId: this.profileId, productType: "Super Lock" }
        ProfileService.profileApplicationsList(data)
          .then((response) => {
            this.profileApplications = response.data.data;
            if (this.profileDetail.signageVideoLists.length > 0) {
              this.profileDetail.signageVideoLists.forEach(video_url => {
                this.textFields.push({ 
                  label1: "Video Url", 
                  value1: video_url,
                });
              })
          }
          })
          .catch((e) => {
            this.$toast.error(e.response.data.respones_msg);
          });
      },
      updateKioskSetting() {
        let videoLists = $("input[name='video_list[]']")
          .map(function(){
              if($(this).val()) {
                return $(this).val();
              }
            }
          ).get();
        let data = { 
          profileId: this.profileId,
          kioskType: this.kioskType,
          singleAppKioskPackageName: this.singleAppKioskPackageName,

          browserKioskWebsiteUrl: this.browserKioskWebsiteUrl,
          browserKioskScreenTimeout: this.browserKioskScreenTimeout,
          browserKioskScreenBrightness: this.browserKioskScreenBrightness,
          browserKioskScreenOrientation: this.browserKioskScreenOrientation,
          browserKioskShowAddressBar: this.browserKioskShowAddressBar,

          signageVideoLists: videoLists,
          signageKioskScreenTimeout: this.signageKioskScreenTimeout,
          signageKioskScreenBrightness: this.signageKioskScreenBrightness,
          signageKioskScreenOrientation: this.signageKioskScreenOrientation,
        };
        ProfileService.updateKioskSetting(data)
          .then((response) => {
            this.$toast.success(response.data.respones_msg);
          })
          .catch((e) => {
            this.$toast.error(e.response.data.respones_msg);
            if (e.response.data.redirectToPaymentPage == true) {
              this.$router.push("plans");
            }
          });
      },
      deleteProfileApplication(id) {
        Swal.fire({
            title: "Are you sure?",
            html: '<p>Application will be removed from this list</p>',
            type: "warning",
            showCancelButton: true,
            confirmButtonClass: "md-button md-success",
            cancelButtonClass: "md-button md-danger",
            confirmButtonText: "Yes, delete it!",
            buttonsStyling: false,
        }).then((result) => {
            if (result.value) {
                let data = { profileId: this.profileId, id: id }
                ProfileService.deleteProfileApplication(data)
                .then((response) => {
                    this.$toast.success(response.data.respones_msg);
                    this.profileApplicationsList();
                })
                .catch((e) => {
                    this.$toast.error(e.response.data.respones_msg);
                    if (e.response.data.redirectToPaymentPage == true) {
                    this.$router.push("plans");
                    }
                });
            }
        });
      },
      addTextBox () {
        this.textFields.push({ 
          label1: "Video Url", 
          value1: "",
        })
     },
     deleteTextBox (index) {
         this.textFields.splice(index, 1)
     },
  },
  beforeMount() {
    this.profileApplicationsList();
  },
}
</script>
<style>
.ul-class {
    list-style-type: none;
    white-space: nowrap;
    overflow: auto;
    width: 100%;
}

.li-class {
  display: inline-block;
  text-align: center;
}
#product-directory {
  padding: unset;
  box-sizing: border-box;
}
#product-directory ul {
  padding-left: 0;
  box-sizing: border-box;
}
#product-directory li {
  list-style: none;
  box-sizing: border-box;
  display: inline-block;
  width: 140px;
  margin: 5px;
  position: relative;            
  border: 1px solid #d3d3d3;
  align-content: center;
  display: inline-block; 
  vertical-align: top;
}
#product-directory li img {
  max-width: 65%;
  height: 85px;
  margin: 0 auto;
  position: relative;
  align-self: center;
  margin:0 auto;
  display:block;
}
#product-directory li span {
  display: block;
  text-align: center;
  font-size: 12px;
  font-weight: bold;
  /*background-color: #6a6969;*/
  padding: 5px;
  color: black;
  min-height: 34px;
  word-break: break-all;
  height: 75px;
}

.app-listing-copy {
  overflow: auto;white-space: nowrap;
}
.app-listing-text {
  width: 135px !important;word-wrap: break-word !important;white-space: normal;
}
.radio-d-none {
  display: none;
}

.app-icon-single {
    height: 50px !important;
    width: 50px !important;
    transition-duration: 0.2s;
    transform-origin: 50% 50%;
}
.label {
  border: 1px solid #fff;
  padding: 10px;
  display: block;
  position: relative;
  margin: 10px;
  cursor: pointer;
  text-align: center;
}

.label:before {
  background-color: white;
  color: white;
  content: " ";
  display: block;
  border-radius: 50%;
  border: 1px solid grey;
  position: absolute;
  top: -5px;
  left: -5px;
  width: 25px;
  height: 25px;
  text-align: center;
  line-height: 28px;
  transition-duration: 0.4s;
  transform: scale(0);
}

.label img {
  height: 75px;
  width: 75px;
  transition-duration: 0.2s;
  transform-origin: 50% 50%;
}

:checked + .label {
  border-color: #ddd;
}

:checked + .label:before {
  content: "✓";
  background-color: grey;
  transform: scale(1);
}

:not(:checked) + .label:before {
  content: "";
  background-color: #ffffff;
  transform: scale(0.5);
}

:checked + .label img {
  transform: scale(0.9);
  /* box-shadow: 0 0 5px #333; */
  z-index: -1;
}
.d-block {
  display: block;
}
</style>