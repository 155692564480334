import http from "../../http-common-super-admin";

class FaqDataService {
    addFaq(data) {
        return http.post("faq/create", data);
    }
    faqList() {
        return http.get("faq/list");
    }
    faqDetail(data) {
        return http.get("faq/detail", { params: data });
    }
    changeFaqStatus(data) {
        return http.post("faq/update", data);
    }
}

export default new FaqDataService();