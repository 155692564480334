<template>
    <md-card>
        <md-card-content>
            <md-field>
                <label>Name</label>
                <md-input v-model="name" type="text"></md-input>
            </md-field>
            <md-field>
                <label>Identifier</label>
                <md-input v-model="identifier" type="text"></md-input>
            </md-field>
            <md-field>
                <label>Description</label>
                <md-input v-model="description" type="text"></md-input>
            </md-field>
            <md-field>
                <label for="movie">Status</label>
                <md-select v-model="status" name="status">
                    <md-option value="Active">Active</md-option>
                    <md-option value="Inactive">Inactive</md-option>
                </md-select>
            </md-field>
            <md-field>
                <label>APK Link</label>
                <md-input v-model="apkLink" type="text"></md-input>
            </md-field><br>
            <div v-for="(textField, i) in textFields" :key="i">
                <div class="md-layout">
                    <div class="md-layout-item md-size-80">
                        <md-field>
                            <label>{{ textField.label1 }}</label>
                            <md-input name="featuresList[]" v-model="textField.value1" type="text"></md-input>
                        </md-field>
                    </div>
                    <div class="md-layout-item md-size-10" :style="{ 'padding-top': '15px' }">
                        <md-field>
                            <input type="checkbox" name="featuresListCheckBox[]" :id="'checkbox' + i" :checked="textField.checked == true" />
                            <!--<md-checkbox name="featuresListCheckBox[]" v-model="textField.value2" :id="'checkbox' + i"></md-checkbox>-->
                        </md-field>
                    </div>
                    <div class="md-layout-item md-size-10" :style="{ 'padding-top': '10px' }">
                        <md-button class="md-just-icon md-danger md-simple" @click="deleteTextBox(i)">
                            <i class="fa fa-trash" aria-hidden="true"></i>
                        </md-button>
                    </div>
                </div>
            </div>
            <md-button class="md-primary" @click="addTextBox">Add Features</md-button>
        </md-card-content>
        <md-card-actions md-alignment="right">
          <md-button class="md-success" @click="updatePrice()">Update</md-button>
        </md-card-actions>
    </md-card>
</template>
<script>
import PriceDataService from "../../../services/SuperAdmin/PriceDataService";
export default {
    data() {
        return {
            isDisabled: false,
            name: "",
            status: "",
            textFields: [
                { 
                label1: "Features 1", 
                value1: "",
                label2: "CheckBox 1", 
                value2: "",
                }
            ],
            featuresList: [],
            priceId:this.$route.query.id,
            priceDetail: null,
            apkLink: "",
            identifier:"",
            description:"",
        };
    },
    methods: {
        addTextBox () {
            this.textFields.push({ 
            label1: "Features " + (this.textFields.length + 1) , 
            value1: "",
            })
        },
        
        deleteTextBox (index) {
            this.textFields.splice(index, 1)
        },

        updatePrice() {
            let featuresList = $("input[name='featuresList[]']")
              .map(function(i){
                    if($(this).val()) {
                        let checked;
                        if($("#checkbox"+i).prop('checked') == true){
                            checked = true;
                        } else {
                            checked = false;
                        }
                        return {text: $(this).val(), checked: checked };
                    }
                }
                ).get();
            let data = {
                id: this.priceId,
                features: featuresList,
                name: this.name,
                status: this.status,
                apkLink: this.apkLink,
                identifier: this.identifier,
                description: this.description
            }
            PriceDataService.updatePrice(data)
            .then((response) => {
                this.$toast.success(response.data.respones_msg);
                this.$router.push({ path: 'admin-pricing-list'})
            }).catch((e) => {
                this.$toast.error(e.response.data.respones_msg);
            });
        },

        getPriceDetail() {
            let data = { id: this.priceId }
            PriceDataService.priceDetail(data)
            .then((response) => {
                this.priceDetail = response.data.data;
                this.name = response.data.data.name;
                this.apkLink = response.data.data.apkLink;
                this.status = response.data.data.status;
                this.identifier = response.data.data.identifier;
                this.description = response.data.data.description;
                if (response.data.data.features.length > 0) {
                    this.textFields = [];
                    response.data.data.features.forEach(feature => {
                        this.textFields.push({ 
                            label1: "Features", 
                            value1: feature.text,
                            checked: feature.checked
                        });
                    })
                }
            })
            .catch((e) => {
                this.$toast.error(e.response.data.respones_msg);
            });
        }
    },
    beforeMount() {
        this.getPriceDetail();
    },
}
</script>
