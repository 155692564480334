import http from "../http-common";

class UserDataService {
  getAll() {
    return http.get("admin/users/tutorials");
  }

  get(id) {
    return http.get(`/tutorials/${id}`);
  }

  register(data) {
    return http.post("/users/register", data);
  }

  login(data) {
    return http.post("/users/login", data);
  }

  VerifyEmail(data) {
    return http.post("/users/verify/email", data);
  }

  getUserDetail(data) {
    return http.get("/users/detail", data);
  }

  ChangePassword(data) {
    return http.post("/users/change-password", data);
  }

  UpdateTwoStepVerification(data) {
    return http.post("/users/update-security-setting", data);
  }

  UpdateProfile(data) {
    return http.post("/users/update", data);
  }

  SendForgetPasswordLink(data) {
    return http.post("/users/send-forget-password-link", data);
  }

  ResetPassword(data) {
    return http.post("/users/reset-password", data);
  }

  VerifyOtp(data) {
    return http.post("/users/verify-otp", data);
  }

  Logout() {
    return http.get("/users/logout");
  }

  GetAuditList(type, sub_type) {
    return http.get("/users/audit/list?type="+type+"&sub_type="+sub_type);
  }
  GetAuditList(data) {
    return http.get("/users/audit/list", { params: data });
  }
  downloadSdkList(data) {
    return http.get("/users/download/sdk", { params: data });
  }
  getExportList() {
    return http.get("/users/export/list");
  }
  deleteExport(data) {
    return http.delete("/users/export/delete", { params: data });
  }
  getExportDetail(data) {
    return http.get("/users/export/detail", { params: data});
  }
  deleteApplication(data) {
    return http.delete("/users/export/application/delete", { params: data});
  }
  
  getPaymentMethods() {
    return http.get("/users/payment-method/list");
  }
  setAsDefaultPaymentMethod(data) {
    return http.post("/users/payment-method/default", data);
  }
  addPaymentMethod(data) {
    return http.post("/users/payment-method/attach", data);
  }
}

export default new UserDataService();