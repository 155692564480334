<template>
  <div>
    <section class="h-100 gradient-form">
      <div class="container py-5 h-100">
        <div class="row d-flex justify-content-center align-items-center h-100">
          <div class="col-xl-11">
            <div class="card rounded-3 text-black">
              <div class="row g-0">
                <div class="col-lg-6 d-flex align-items-center">
                  <div class="card-body mx-md-4 gradient-custom-2">
                    <div id="paymentLink" >
                      <md-radio v-model="productType" value="PD"><p :style="{ color: '#db0000' }">Package Disabler</p></md-radio>
                      <md-radio v-model="productType" value="PC"><p :style="{ color: '#db0000' }">Power Cleaner</p></md-radio>
                      <md-radio v-model="productType" value="UB"><p :style="{ color: '#db0000' }">URL Blocker</p></md-radio>
                      <md-radio v-model="productType" value="KM"><p :style="{ color: '#db0000' }">Key Mapper</p></md-radio>
                      <md-radio v-model="productType" value="POB"><p :style="{ color: '#db0000' }">Power on Boot</p></md-radio>
                    </div>
                    <div v-if="productType == 'PD'">
                      <div>
                        <h4 class="mb-4" :style="{ color: 'black' }"><b>Not a member?</b></h4>
                        <p class="small mb-0">Bloatware killer with premium features and cloud based controls. Yearly subscription for remote management, software updates and premium support.</p>
                        <div class="text-center">
                          <a :href="PDSubscriptionPaymentPage" :style="{ color: 'unset' }" type="button" class="btn btn-primary">Buy Now</a>
                        </div>
                      </div><hr>
                      <div>
                        <p class="small mb-0">Bloatware killer with premium features for 3 years. No yearly payments. Standard support included.</p>
                        <div class="text-center">
                          <a :href="PDNonSubscriptionPaymentPage" :style="{ color: 'unset' }" type="button" class="btn btn-primary">Buy Now</a>
                        </div>
                      </div>
                    </div>
                    <div v-if="productType == 'PC'">
                      <div>
                        <h4 class="mb-4" :style="{ color: 'black' }"><b>Not a member?</b></h4>
                        <p class="small mb-0">Quickly and easily remove junk, reclaim space, monitor your system, clear cookies, uninstall apps and more. Yearly subscription for remote management, software updates and support.</p>
                        <div class="text-center">
                          <a :href="PCSubscriptionPaymentPage" :style="{ color: 'unset' }" type="button" class="btn btn-primary">Buy Now</a>
                        </div>
                      </div><hr>
                      <div>
                        <p class="small mb-0">Quickly and easily remove junk, reclaim space, monitor your system, clear cookies, uninstall apps and more for 3 years. No Yearly payment. Standard support.</p>
                        <div class="text-center">
                          <a :href="PCNonSubscriptionPaymentPage" :style="{ color: 'unset' }" type="button" class="btn btn-primary">Buy Now</a>
                        </div>
                      </div>
                    </div>
                    <div v-if="productType == 'UB'">
                      <div>
                        <h4 class="mb-4" :style="{ color: 'black' }"><b>Not a member?</b></h4>
                        <p class="small mb-0">A specialized application that blocks millions of listed URL on Samsung devices. Blocks all know phishing, adult, adware, malware, spyware, ransomware sites. Support for Custom URL block list.</p>
                        <div class="text-center">
                          <a :href="UBSubscriptionPaymentPage" :style="{ color: 'unset' }" type="button" class="btn btn-primary">Buy Now</a>
                        </div>
                      </div><hr>
                      <div>
                        <p class="small mb-0">A specialized application that blocks millions of listed URL on Samsung devices. Blocks all know phishing, adult, adware, malware, spyware, ransomware sites. Support for Custom URL block list.</p>
                        <div class="text-center">
                          <a :href="UBNonSubscriptionPaymentPage" :style="{ color: 'unset' }" type="button" class="btn btn-primary">Buy Now</a>
                        </div>
                      </div>
                    </div>
                    <div v-if="productType == 'KM'">
                      <div>
                        <h4 class="mb-4" :style="{ color: 'black' }"><b>Not a member?</b></h4>
                        <p class="small mb-0">Map any application on any hardware key of device for quick launch. NO ROOTING REQUIRED. Single app solution, no plugin needed</p>
                        <div class="text-center">
                          <a :href="KMSubscriptionPaymentPage" :style="{ color: 'unset' }" type="button" class="btn btn-primary">Buy Now</a>
                        </div>
                      </div><hr>
                      <div>
                        <p class="small mb-0">Map any application on any hardware key of device for quick launch. NO ROOTING REQUIRED. Single app solution, no plugin needed</p>
                        <div class="text-center">
                          <a :href="KMNonSubscriptionPaymentPage" :style="{ color: 'unset' }" type="button" class="btn btn-primary">Buy Now</a>
                        </div>
                      </div>
                    </div>
                    <div v-if="productType == 'POB'">
                      <div>
                        <h4 class="mb-4" :style="{ color: 'black' }"><b>Not a member?</b></h4>
                        <p class="small mb-0">Automatically Device boots on power with premium features and cloud based controls. Yearly subscription.</p>
                        <div class="text-center">
                          <a :href="POBSubscriptionPaymentPage" :style="{ color: 'unset' }" type="button" class="btn btn-primary">Buy Now</a>
                        </div>
                      </div><hr>
                      <div>
                        <p class="small mb-0">Automatically Device boots on power with premium features for 3 years. No yearly payments. Standard support included.</p>
                        <div class="text-center">
                          <a :href="POBNonSubscriptionPaymentPage" :style="{ color: 'unset' }" type="button" class="btn btn-primary">Buy Now</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="card-body mx-md-4">

                    <div class="text-center login-logo">
                      <img :src="logo"
                        style="width: 150px;" alt="Package Disabler">
                    </div>

                    <form>
                      <p></p>

                      <div class="form-outline">
                        <input type="email" id="form2Example11" class="form-control"
                          placeholder="Email Address" @keyup.enter="Login()" v-model="email" />
                        <label class="form-label" for="form2Example11">Email</label>
                      </div>

                      <div class="form-outline">
                        <input type="password" id="form2Example22" placeholder="Password"
                        @keyup.enter="Login()" class="form-control" v-model="password" />
                        <label class="form-label" for="form2Example22">Password</label>
                      </div>

                      <div class="text-center pt-1 pb-1">
                        <button class="btn btn-primary btn-block fa-lg mb-3" type="button" @click="Login()" :disabled="isDisabled">Login</button>
                        <a class="text-muted" href="javascript:void(0)" @click="$router.push('/forget-password')">Forgot password?</a>
                      </div>
                    </form>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
  <!--<div class="md-layout text-center">
    <div class="md-layout-item md-size-33 md-medium-size-50 md-small-size-70 md-xsmall-size-100">
    <div class="alert alert-success alert-dismissible fade show" role="alert" v-if="this.$route.params.show_email_div">
      <strong>Congratulation!</strong> A message with a confirmation link has been sent to your email address. Please open the link to activate your account.
    </div><br>
      <login-card header-color="green">
        <h4 slot="title" class="title">Welcome to {{ WhiteLabellingTitle() }}</h4>
        <md-field class="md-form-group" slot="inputs">
          <md-icon>email</md-icon>
          <label>Email...</label>
          <md-input v-model="email" type="email"  id="email" @keyup.enter="Login()"></md-input>
        </md-field>
        <md-field class="md-form-group" slot="inputs">
          <md-icon>lock_outline</md-icon>
          <label>Password...</label>
          <md-input v-model="password" type="password" id="password" @keyup.enter="Login()"></md-input>
        </md-field>
        <div slot="footer">
          <md-button class="md-success" v-on:click="Login()" :disabled="isDisabled">
            Login
          </md-button>  
          <hr>
          <a class="fs-12" href="javascript:void(0)" @click="$router.push('/forget-password')">Forget Password?</a>
        </div>
      </login-card>
    </div> 
  </div>-->
</template>
<script>
import { LoginCard } from "@/components";
import UserDataService from "../../../services/UsersDataService";
export default {
  components: {
    //LoginCard,
  },
  props: {
    logo: {
      type: String,
      default: "./img/logo-light.png",
    },
  },
  data() {
    return {
      isDisabled: false,
      email: null,
      password: null,
      PDSubscriptionPaymentPage: process.env.VUE_APP_PD_WEBSITE_BASE_URL+"/payment?id="+process.env.VUE_APP_STRIPE_PD_PRICE_SUBSCRIPTION,
      PDNonSubscriptionPaymentPage: process.env.VUE_APP_PD_WEBSITE_BASE_URL+"/payment?id="+process.env.VUE_APP_STRIPE_PD_PRICE_ONETIME,
      productType:"PD",
      PCSubscriptionPaymentPage: process.env.VUE_APP_PD_WEBSITE_BASE_URL+"/payment?id="+process.env.VUE_APP_STRIPE_PC_PRICE_SUBSCRIPTION,
      PCNonSubscriptionPaymentPage: process.env.VUE_APP_PD_WEBSITE_BASE_URL+"/payment?id="+process.env.VUE_APP_STRIPE_PC_PRICE_ONETIME,
      UBSubscriptionPaymentPage: process.env.VUE_APP_PD_WEBSITE_BASE_URL+"/payment?id="+process.env.VUE_APP_STRIPE_UB_PRICE_SUBSCRIPTION,
      UBNonSubscriptionPaymentPage: process.env.VUE_APP_PD_WEBSITE_BASE_URL+"/payment?id="+process.env.VUE_APP_STRIPE_UB_PRICE_ONETIME,
      KMSubscriptionPaymentPage: process.env.VUE_APP_PD_WEBSITE_BASE_URL+"/payment?id="+process.env.VUE_APP_STRIPE_KM_PRICE_SUBSCRIPTION,
      KMNonSubscriptionPaymentPage: process.env.VUE_APP_PD_WEBSITE_BASE_URL+"/payment?id="+process.env.VUE_APP_STRIPE_KM_PRICE_ONETIME,
      POBSubscriptionPaymentPage: process.env.VUE_APP_PD_WEBSITE_BASE_URL+"/payment?id="+process.env.VUE_APP_STRIPE_POB_PRICE_SUBSCRIPTION,
      POBNonSubscriptionPaymentPage: process.env.VUE_APP_PD_WEBSITE_BASE_URL+"/payment?id="+process.env.VUE_APP_STRIPE_POB_PRICE_ONETIME,
    };
  },
  methods: {
    Login() {
      this.isDisabled = true;
      var data = {
        email: this.email,
        password: this.password,
        userAgent: navigator.userAgent
      };
      UserDataService.login(data)
        .then((response) => {
          this.isDisabled = false;
          if (response.data.enable_two_step_email_verification) {
              this.$toast.success(response.data.respones_msg);
              this.$router.push({ path: '/verify-otp', query: { email: this.email }});
          } else {
            this.$toast.success(response.data.respones_msg);
            this.email = null;
            this.password = null;
            localStorage.setItem('token', response.data.data.token);
            document.location = "/dashboard";
          }
          //this.$router.push("dashboard");
        })
        .catch((e) => {
          this.isDisabled = false;
          this.$toast.error(e.response.data.respones_msg);
          if (e.response.data.hasOwnProperty('userExist') && e.response.data.userExist == true) {
            if (confirm("This email belongs to old Package Disabler System, click ok to redirect to new site") == true) {
                window.location = "https://cloud.packagedisabler.com/pmc/login";
            }
          }
        });
    },
    VerifyEmail(iv, encryptedData) {
      var data = {
        iv: iv,
        encryptedData: encryptedData,
      };
      UserDataService.VerifyEmail(data)
        .then((response) => {
          this.$toast.success(response.data.respones_msg);
        })
        .catch((e) => {
          this.$toast.error(e.response.data.respones_msg);
        });
    }
  },
  beforeMount() {
    if (this.$route.query.iv && this.$route.query.encryptedData) {
      this.VerifyEmail(this.$route.query.iv,this.$route.query.encryptedData);
    }
  },
  mounted: function () {
    this.$nextTick(function () {
      setTimeout("$('#email').val('')",1000);
      setTimeout("$('#password').val('')",1000);
    })
  }
};
</script>

<style>
.fs-12{
  font-size: 16px !important;
  font-weight: 400;
  color: mediumblue !important;
}
.md-card-actions{
  padding: unset !important;
}

.small {
  color: black;
}

.gradient-custom-2 {
/* fallback for old browsers */
background: #90b5fc; /*#fccb90;*/

/* Chrome 10-25, Safari 5.1-6 */
/*background: -webkit-linear-gradient(to right, #ee7724, #d8363a, #dd3675, #b44593);*/
background: #90b5fc;

/* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
/*background: linear-gradient(to right, #ee7724, #d8363a, #dd3675, #b44593);*/
background: #90b5fc;
}

@media only screen and (max-width: 600px) {
  .login-logo {
    display: none;
  }
}

@media (min-width: 768px) {
.gradient-form {
height: 100vh !important;
}
}
@media (min-width: 769px) {
.gradient-custom-2 {
border-top-right-radius: .3rem;
border-bottom-right-radius: .3rem;
}
}

#paymentLink {
    display: flex;
}
@media only screen and (max-width: 600px) {
 #paymentLink > .md-radio {
    margin: 5px 5px 5px 0;
  }
  #paymentLink{
    display: unset;
  }
}
</style>
