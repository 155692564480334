<template>
    <div>
        <div style="float: right">
          <md-button class="md-success" @click="$router.push({ path: 'admin-blog-create'})">Create Blog</md-button>
        </div>
        <md-card>
            <md-card-content>
                <div class="md-layout">
                    <div class="md-layout-item md-size-100">
                        <md-table :value="queriedData" class="paginated-table table-striped table-hover" 
                            table-header-color="green" md-fixed-header>
                            <md-table-toolbar>
                                <md-field>
                                    <label for="pages">Per page</label>
                                    <md-select v-model="pagination.perPage" name="pages">
                                    <md-option
                                        v-for="item in pagination.perPageOptions"
                                        :key="item"
                                        :label="item"
                                        :value="item"
                                    >
                                        {{ item }}
                                    </md-option>
                                    </md-select>
                                </md-field>
                            </md-table-toolbar>
                            <md-table-row slot="md-table-row" slot-scope="{ item }">            
                                <md-table-cell md-label="Title">{{ item.title }} </md-table-cell>
                                <md-table-cell md-label="Description">{{ item.shortDescription }} </md-table-cell>
                                <md-table-cell md-label="Created On">{{ HumanReadableDateFormat(item.created_at) }}</md-table-cell>
                                <md-table-cell md-label="Actions">
                                    <div>  
                                      <md-button class="md-just-icon md-success md-simple" @click="$router.push({ path: 'admin-blog-create', query: { id: item._id }})"><md-icon title="Edit">edit</md-icon></md-button>&nbsp;                  
                                      <md-button class="md-just-icon md-danger md-simple" @click="deleteBlog(item._id)"><md-icon>close</md-icon></md-button>
                                    </div>
                                </md-table-cell>
                            </md-table-row>
                        </md-table>
                    </div>
                </div>
            </md-card-content>
            <md-card-actions md-alignment="space-between">
                <div class="">
                    <p class="card-category">
                    Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
                    </p>
                </div>
                <pagination
                    class="pagination-no-border pagination-success"
                    v-model="pagination.currentPage"
                    :per-page="pagination.perPage"
                    :total="total"
                >
                </pagination>
            </md-card-actions>
        </md-card>
    </div>
</template>
<script>
import BlogService from "../../../services/SuperAdmin/BlogService";
import { Pagination } from "@/components";
import Swal from "sweetalert2";

export default {
  components: {
    Pagination,
  },
  data() {
    return {
      isDisabled: false,
      tableData: [],
      //Pagination
      pagination: {
        perPage: 25,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0,
      },
      searchedData: [],
    };
  },
  computed: {
    /***
     * Returns a page from the searched data or the whole data. Search is performed in the watch section below
     */
    queriedData() {
      let result = this.tableData;
      if (this.searchedData.length > 0) {
        result = this.searchedData;
      }
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.tableData.length;
    },
  },
  methods: {
      blogList() {
        BlogService.list()
          .then((response) => {
            this.tableData = response.data.data;
            this.$toast.success(response.data.respones_msg);
          })
          .catch((e) => {
            this.$toast.error(e.response.data.respones_msg);
          });
      },
      deleteBlog(id) {
        let data =  {
          id: id
        }
        Swal.fire({
            title: "Are you sure?",
            html: '<p>Blog will be removed from this list</p>',
            type: "warning",
            showCancelButton: true,
            confirmButtonClass: "md-button md-success",
            cancelButtonClass: "md-button md-danger",
            confirmButtonText: "Yes, delete it!",
            buttonsStyling: false,
        }).then((result) => {
            if (result.value) {
                BlogService.delete(data)
                .then((response) => {
                    this.$toast.success(response.data.respones_msg);
                    this.blogList();
                })
                .catch((e) => {
                    this.$toast.error(e.response.data.respones_msg);
                }); 
            }
        });
    },
  },
  beforeMount() {
    this.blogList();
  },
};
</script>
