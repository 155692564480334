import http from "../../http-common";

class FcmService {
    listUsers(data = null) {
        return http.get("fcm/user/list", { params: data });
    }
    createUsers(data) {
        return http.post("fcm/user/create", data);
    }
    deleteFcmUser(data = null) {
        return http.delete("fcm/user/delete", { params: data });
    }
    userDetail(data = null) {
        return http.get("fcm/user/detail", { params: data });
    }
    syncUsers(data = null) {
        return http.get("fcm/user/sync", { params: data });
    }
}

export default new FcmService();