<template>
    <div>
        <div class="md-layout">
            <div class="md-layout-item md-size-100">
                <br>
                <div class="md-layout">
                    <div class="md-layout-item md-size-50 md-small-size-100">
                    <md-card>
                        <md-card-content>
                        <div class="w3-responsive">
                            <table class="ws-table-all">
                            <tbody>
                                <tr>
                                <th>Serial Number</th>
                                <td>{{ deviceDetail.serialNumber }}</td>
                                </tr>
                                <tr>
                                <th>Device Name</th>
                                <td>{{ deviceDetail.deviceName }}</td>
                                </tr>
                                <tr>
                                <th>App Name</th>
                                <td>{{ deviceDetail.appName }}</td>
                                </tr>
                                <tr>
                                <th>App Version</th>
                                <td>{{ deviceDetail.appVersionCode }}</td>
                                </tr>
                                <tr>
                                <th>Device Counter</th>
                                <td>{{ this.profileData.profileCounter}}</td>
                                </tr>
                                <tr>
                                <th>App Installation Date</th>
                                <td>{{ HumanReadableDateFormat(deviceDetail.appInstallationDate, true) }}</td>
                                </tr>
                            </tbody>
                            </table>
                        </div>
                        </md-card-content>
                    </md-card>
                    </div>
                    <div class="md-layout-item md-size-50 md-small-size-100">
                    <md-card>
                        <md-card-content>
                        <div class="w3-responsive">
                            <table class="ws-table-all">
                            <tbody>
                                <tr>
                                <th>Battery Level</th>
                                <td>{{ deviceDetail.batteryLevel }}</td>
                                </tr>
                                <tr>
                                <th>Device Model</th>
                                <td>{{ deviceDetail.deviceModel }}</td>
                                </tr>
                                <tr>
                                <th>Local Ip Address</th>
                                <td>{{ deviceDetail.localIpAddress }}</td>
                                </tr>
                                <tr>
                                <th>Mac Address</th>
                                <td>{{ deviceDetail.macAddress }}</td>
                                </tr>
                                <tr>
                                <th>OS Version</th>
                                <td>{{ deviceDetail.osVersion }}</td>
                                </tr>
                                <tr>
                                <th>Location</th>
                                <td>{{ deviceDetail.location }}</td>
                                </tr>
                            </tbody>
                            </table>
                        </div>
                        </md-card-content>
                    </md-card>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import ProfileService from "../../services/Customer/ProfileService";

export default {
  name: 'DeviceTab',
  props: {
    profileId: String,
  },
  data() {
    return {
        profileData: {},
        deviceDetail: null,
    }
  },
  methods: {
    profileDetail() {
        let data = { profileId: this.profileId }
        ProfileService.profileDetail(data)
          .then((response) => {
            this.profileData = response.data.data;
            this.profileUrlData = response.data.data.blockUrl;
            //this.$toast.success(response.data.respones_msg);
          })
          .catch((e) => {
            this.$toast.error(e.response.data.respones_msg);
          });
    },
    profileDeviceDetail() {
        let data = { profileId: this.profileId }
        ProfileService.profileDeviceDetail(data)
          .then((response) => {
            this.deviceDetail = response.data.data;
            //this.$toast.success(response.data.respones_msg);
          })
          .catch((e) => {
            this.$toast.error(e.response.data.respones_msg);
          });
      },
  },
  beforeMount() {
    this.profileDetail();
    this.profileDeviceDetail();
  },
}
</script>