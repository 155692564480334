<template>
    <div>
        <md-card>
            <md-card-content>
                <div class="md-layout">
                    <div class="md-layout-item md-size-100">
                        <md-table v-if="tableData.length > 0 " v-model="tableData" table-header-color="green">
                            <md-table-row slot="md-table-row" slot-scope="{ item }">            
                                <md-table-cell md-label="Name">
                                  {{ item.productName }}
                                  <span v-if="item.paymentMethod && item.paymentMethod.type == 'paypal'" class="text-primary"><br><b>Payment Method: 
                                    <i class="fab fa-paypal"></i> 
                                  </b>
                                  </span>
                                  <span v-else-if="item.paymentMethod && item.paymentMethod.type == 'wechat_pay'" class="text-primary"><br><b>Payment Method: 
                                    WeChatPay
                                  </b>
                                  </span>
                                  <span v-else-if="item.paymentMethod && item.paymentMethod.type == 'alipay'" class="text-primary"><br><b>Payment Method: 
                                    Alipay
                                  </b>
                                  </span>
                                  <span v-else-if="item.paymentMethod && item.paymentMethod.card" class="text-primary"><br><b>Payment Method: 
                                    <i class="far fa-credit-card fa-lg" v-if="item.paymentMethod.card.wallet && item.paymentMethod.card.wallet.type == null"></i> 
                                    <i class="fab fa-google-pay fa-lg" v-else-if="item.paymentMethod.card.wallet && item.paymentMethod.card.wallet.type == 'google_pay'"></i>
                                    <i class="fab fa-cc-apple-pay fa-lg" v-else-if="item.paymentMethod.card.wallet && item.paymentMethod.card.wallet.type == 'apple_pay'"></i>
                                    <label v-else-if="item.paymentMethod.card.wallet && item.paymentMethod.card.wallet.type == 'link'">link</label>
                                    <label v-else>{{ item.paymentMethod.type }}</label>
                                  </b>
                                  </span>
                                  <span v-else class="text-primary"><br><b>Payment Method: 
                                    <i class="far fa-credit-card fa-lg"></i>
                                  </b>
                                  </span>
                                </md-table-cell>
                                <md-table-cell md-label="Type">
                                    <p class="badge-primary" v-if="item.subscriptionType == 'recurring'">Recurring</p>
                                    <p class="badge-warning" v-else>Onetime</p>
                                    <small v-if="item.subscriptionReason"> <b>{{ item.subscriptionReason }}</b></small>
                                </md-table-cell>
                                <md-table-cell md-label="Qty">
                                  <b> {{ item.qty }} License </b>
                                  <br><span v-if="item.priceId" :class="'badge-'+item.priceId.identifier">{{ appNameFull(item.priceId.identifier) }}</span>
                                </md-table-cell>
                                <md-table-cell md-label="Amount">
                                  <b>€{{ item.totalAmount }}</b> 
                                  <span v-if="item.coupon" class="text-info">
                                      <!--<b v-if="item.discountAmount > 0"><br>Discount : €{{ item.discountAmount }} </b>-->
                                      <b><br>CouponCode : {{ item.coupon }} </b>
                                  </span>
                                </md-table-cell>
                                <md-table-cell md-label="Status">
                                    <p class="badge-success" v-if="item.status == 'Success'">{{ item.status }}</p>
                                    <p class="badge-danger" v-else-if="item.status == 'Failed'">{{ item.status }}</p>
                                    <p class="badge-primary" v-else>{{ item.status }}</p>
                                </md-table-cell>
                                <md-table-cell md-label="Expiry At">
                                  <p v-if="item.expiryAt">
                                    {{ HumanReadableDateFormat(item.expiryAt, true) }}
                                    <span v-if="new Date(item.expiryAt) < today" class="badge-danger">Expired</span>
                                  </p>
                                  <p v-else>-</p>
                                </md-table-cell>
                                <md-table-cell md-label="Created On">{{ HumanReadableDateFormat(item.created_at) }}</md-table-cell>
                            </md-table-row>
                        </md-table>
                        <h4 v-else>No data found.</h4>
                    </div>
                </div>
            </md-card-content>
        </md-card>
    </div>
</template>
<script>
import PaymentService from "../../../services/Customer/PaymentService";

export default {
  components: {
  },
  data() {
    return {
      isDisabled: false,
      tableData: [],
      priceId: this.$route.query.priceId,
      today: new Date(),
    };
  },
  methods: {
      paymentHistory() {
        let data = { priceId: this.priceId }
        PaymentService.paymentHistory(data)
          .then((response) => {
            this.tableData = response.data.data;
            this.$toast.success(response.data.respones_msg);
          })
          .catch((e) => {
            this.$toast.error(e.response.data.respones_msg);
          });
      },
  },
  beforeMount() {
    this.paymentHistory();
  },
};
</script>
