<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-100">
      <md-card>
        <md-card-content>
            <div class="alert alert-warning" role="alert" v-if="customerDetail.isResellerModeEnabled && !customerDetail.isResellerModeRequestApprove">
                We have received your request, we are currently reviewing it. We will inform you soon about the status of the request.
            </div>
            <ul>
                <li>You can earn more money by becoming a reseller in the package disabler.</li>
                <li>We will give you a coupon code when you become a reseller.</li>
                <li>You can share that coupon code with your friends and family.</li>
                <li>If any customer purchases using your coupon code, they will get <b>10% discount and you will get cashback on the amount they have paid.</b></li>
            </ul>
            <table class="table">
                <thead>
                    <tr>
                        <th scope="col">#</th>
                        <th scope="col">Plan</th>
                        <th scope="col">Minimum Referral</th>
                        <th scope="col">Maximum Referral</th>
                        <th scope="col">Earn Percentage(%)</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th scope="row">1</th>
                        <td>Silver</td>
                        <td>10</td>
                        <td>100</td>
                        <td>10%</td>
                    </tr>
                    <tr>
                        <th scope="row">2</th>
                        <td>Gold</td>
                        <td>101</td>
                        <td>500</td>
                        <td>12%</td>
                    </tr>
                    <tr>
                        <th scope="row">3</th>
                        <td>Platinium</td>
                        <td>501</td>
                        <td>1000</td>
                        <td>15%</td>
                    </tr>
                    <tr>
                        <th scope="row">4</th>
                        <td>Premium</td>
                        <td>1001</td>
                        <td>ᨖ</td>
                        <td>20%</td>
                    </tr>
                </tbody>
            </table><br>
            <div v-if="!customerDetail.isResellerModeEnabled">
                <div class="form-check">
                    <input class="form-check-input" v-model="acceptTerms" type="checkbox" value="" id="flexCheckDefault">
                    <label class="form-check-label" for="flexCheckDefault">
                        Read & Accept Terms and condition
                    </label>
                </div>
                <md-button class="md-success mr-2" :disabled="isDisabled || !acceptTerms" @click="enableResellerModule()">
                    Enable It<span v-if="isDisabled"><i class="fa fa-spinner fa-spin"></i></span>
                </md-button>
            </div>
        </md-card-content>
      </md-card>
    </div>
  </div>
</template>
<script>
import UserDataService from "../../services/UsersDataService";

export default {
  components: {
    
  },
  data() {
    return {
      isDisabled: false,
      acceptTerms: false,
      customerDetail: null,
    };
  },
  methods: {
    getCustomerDetail() {
      UserDataService.getUserDetail({})
        .then((response) => {
          this.customerDetail = response.data.data;
        }).catch((e) => {
          this.$toast.error(e.response.data.respones_msg);
        });
    },
    enableResellerModule() {
      this.isDisabled = true;
      let data = { isResellerModeEnabled: true };
      UserDataService.UpdateProfile(data)
        .then((response) => {
          this.isDisabled = false;
          this.getCustomerDetail();
          this.$toast.success(response.data.respones_msg);
        }).catch((e) => {
          this.isDisabled = false;
          this.$toast.error(e.response.data.respones_msg);
        });
    },
  },
  beforeMount() {
    this.getCustomerDetail()
  },
};
</script>
