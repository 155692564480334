<template>
    <div>
      <div style="float: right">
        <md-button class="md-warning" :disabled="isDisabled" @click="exportData()">Export</md-button>
      </div>
        <md-card>
            <md-card-content>
                <form>
                    <div class="row">
                        <div class="col">
                            <md-field>
                                <label>Bloatware</label>
                                <md-select v-model="isBloatware">
                                    <md-option value="">All</md-option>
                                    <md-option value="Yes">Yes</md-option>
                                    <md-option value="No">No</md-option>
                                </md-select>
                            </md-field>
                        </div>
                        <div class="col">
                            <md-button class="md-success" :disabled="isDisabled" @click="bloatwareApplicationList()">Search<span v-if="isDisabled"><i class="fa fa-spinner fa-spin"></i></span></md-button>
                        </div>
                    </div>
                </form>
            </md-card-content>
        </md-card>
        <md-card>
            <md-card-content>
                <div class="md-layout">
                    <div class="md-layout-item md-size-100">
                        <md-table :value="queriedData" class="paginated-table table-striped table-hover" 
                            table-header-color="green" md-fixed-header>
                            <md-table-toolbar>
                                <md-field>
                                    <label for="pages">Per page</label>
                                    <md-select v-model="pagination.perPage" name="pages">
                                    <md-option
                                        v-for="item in pagination.perPageOptions"
                                        :key="item"
                                        :label="item"
                                        :value="item"
                                    >
                                        {{ item }}
                                    </md-option>
                                    </md-select>
                                </md-field>
                            </md-table-toolbar>
                            <md-table-row slot="md-table-row" slot-scope="{ item }">     
                                <md-table-cell md-label="Package Name"><b> {{ item.packageName }}</b> </md-table-cell>
                                <md-table-cell md-label="Is Bloatware"><b> {{ item.isBloatware }}</b> </md-table-cell>
                                <md-table-cell md-label="Reason"><b> {{ item.reason }}</b> </md-table-cell>
                                <md-table-cell md-label="Created By"><b> {{ item.created_by }}</b> </md-table-cell>
                                <md-table-cell md-label="Created On">{{ HumanReadableDateFormat(item.created_at) }}</md-table-cell>
                            </md-table-row>
                        </md-table>
                    </div>
                </div>
            </md-card-content>
            <md-card-actions md-alignment="space-between">
                <div class="">
                    <p class="card-category">
                    Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
                    </p>
                </div>
                <pagination
                    class="pagination-no-border pagination-success"
                    v-model="pagination.currentPage"
                    :per-page="pagination.perPage"
                    :total="total"
                >
                </pagination>
            </md-card-actions>
        </md-card>
    </div>
</template>
<script>
import CustomerService from "../../../services/SuperAdmin/CustomerService";
import { Pagination } from "@/components";

export default {
  components: {
    Pagination,
  },
  computed: {
    /***
     * Returns a page from the searched data or the whole data. Search is performed in the watch section below
     */
    queriedData() {
      let result = this.tableData;
      if (this.searchedData.length > 0) {
        result = this.searchedData;
      }
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.tableData.length;
    },
  },
  data() {
    return {
      isDisabled: false,
      tableData: [],
      isBloatware: "",

      //Pagination
      pagination: {
        perPage: 25,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0,
      },
      searchedData: [],
    };
  },
  methods: {
      bloatwareApplicationList() {
        let data = {
          isBloatware: this.isBloatware
        }
        CustomerService.bloatwareApplicationList(data)
          .then((response) => {
            this.tableData = response.data.data;
            this.$toast.success(response.data.respones_msg);
          })
          .catch((e) => {
            this.$toast.error(e.response.data.respones_msg);
          });
      },
      async exportData() {
        if (this.tableData.length > 0) {
          let exportData = [];
          for await (let data of this.tableData) {
            exportData.push([ data.packageName, data.isBloatware, data.reason, data.created_by, data.created_at]);
          }
          this.DownloadSampleImportFile(exportData);
        } else {
          this.$toast.error("No data to export");
        }
      },
      DownloadSampleImportFile(exportData = "") {
        let csv = 'Package Name,Is Bloatware,Reason,Created BY,Created On\n';
        if (exportData) {
          exportData.forEach((row) => {
              csv += row.join(',');
              csv += "\n";
          });
        }
        const anchor = document.createElement('a');
        anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv);
        anchor.target = '_blank';
        if (exportData) {
          anchor.download = 'BloatwareAppList.csv';
        }
        anchor.click();
      },
  },
  beforeMount() {
    this.bloatwareApplicationList();
  },
};
</script>
