<template>
    <div>
        <div class="container">
            <div class="row">
                <div class="col" v-if="pdPriceDetail">
                    <div class="card" style="width: 18rem;">
                        <div class="card-body">
                            <h5 class="card-title text-center"><b>Package Disabler</b></h5>
                        </div>
                        <img :src="pdPriceDetail.apkLinkQrCode" class="card-img-top" alt="">
                        <br><a :href="pdPriceDetail.apkLink" class="text-center">Click here to download APK</a>
                    </div>
                </div>
                <div class="col" v-if="pcPriceDetail">
                    <div class="card" style="width: 18rem;">
                        <div class="card-body">
                            <h5 class="card-title text-center"><b>Power Cleaner</b></h5>
                        </div>
                        <img :src="pcPriceDetail.apkLinkQrCode" class="card-img-top" alt="">
                        <br><a :href="pcPriceDetail.apkLink" class="text-center">Click here to download APK</a>
                    </div>
                </div>
                <div class="col" v-if="kmPriceDetail">
                    <div class="card" style="width: 18rem;">
                        <div class="card-body">
                            <h5 class="card-title text-center"><b>Key Mapper</b></h5>
                        </div>
                        <img :src="kmPriceDetail.apkLinkQrCode" class="card-img-top" alt="">
                        <br><a :href="kmPriceDetail.apkLink" class="text-center">Click here to download APK</a>
                    </div>
                </div>
                <div class="col" v-if="ubPriceDetail">
                    <div class="card" style="width: 18rem;">
                        <div class="card-body">
                            <h5 class="card-title text-center"><b>Url Blocker</b></h5>
                        </div>
                        <img :src="ubPriceDetail.apkLinkQrCode" class="card-img-top" alt="">
                        <br><a :href="ubPriceDetail.apkLink" class="text-center">Click here to download APK</a>
                    </div>
                </div>
                <div class="col" v-if="pobPriceDetail">
                    <div class="card" style="width: 18rem;">
                        <div class="card-body">
                            <h5 class="card-title text-center"><b>Power On Boot</b></h5>
                        </div>
                        <img :src="pobPriceDetail.apkLinkQrCode" class="card-img-top" alt="">
                        <br><a :href="pobPriceDetail.apkLink" class="text-center">Click here to download APK</a>
                    </div>
                </div>
                <div class="col" v-if="powerChatPriceDetail">
                    <div class="card" style="width: 18rem;">
                        <div class="card-body">
                            <h5 class="card-title text-center"><b>Power Chat</b></h5>
                        </div>
                        <img :src="powerChatPriceDetail.apkLinkQrCode" class="card-img-top" alt="">
                        <br><a :href="powerChatPriceDetail.apkLink" class="text-center">Click here to download APK</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import UserDataService from "../../services/UsersDataService";
export default {
  components: {
    
  },
  data() {
    return {
      pdPriceDetail: null,
      pcPriceDetail: null,
      ubPriceDetail: null,
      kmPriceDetail: null,
      powerChatPriceDetail: null,
      pobPriceDetail:null,
    };
  },
  methods: {
      downloadSdkList() {
        var data = { appName : "PD"};
        UserDataService.downloadSdkList(data)
            .then((response) => {
                this.pdPriceDetail = response.data.pdPriceDetail
                this.pcPriceDetail = response.data.pcPriceDetail;
                this.ubPriceDetail = response.data.ubPriceDetail;
                this.kmPriceDetail = response.data.kmPriceDetail;
                this.pobPriceDetail = response.data.pobPriceDetail;
                this.powerChatPriceDetail = response.data.powerChatPriceDetail;
            })
            .catch((e) => {
            if (e.response.data.is_logout == 1) {
                localStorage.setItem('token', "");
                this.$router.push('login');
            }
            this.$toast.error(e.response.data.respones_msg);
            });
        },
  },
  beforeMount() {
    this.downloadSdkList();
  },
};
</script>
