<template>
    <div>
        <md-card>
            <md-card-content>
                <div id="toolbar-chat" ></div>
                <div class="row">
                  <div class="col">
                        <md-field>
                          <label>Question</label>
                          <md-input v-model="question" type="text"></md-input>
                        </md-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <vue-editor v-model="answer"></vue-editor>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <md-button class="md-success" :disabled="isDisabled" @click="updateFaq()">Update</md-button>
                    </div>
                </div>
            </md-card-content>
        </md-card>
    </div>
</template>
<script>
import FaqDataService from "../../../services/SuperAdmin/FaqDataService";
import { VueEditor } from "vue2-editor";

export default {
  components: {
    VueEditor
  },
  data() {
    return {
      isDisabled: false,
      tableData: [],
      faqId:this.$route.query.id,
      answer:"",
      question: "",
      status: "",
    };
  },
  methods: {
      editFaq() {        
        let data = { id: this.faqId };
        FaqDataService.faqDetail(data)
          .then((response) => {
            this.answer = response.data.data.answer;
            this.question = response.data.data.question;
            this.status = response.data.data.status;
          })
          .catch((e) => {
            this.$toast.error(e.response.data.respones_msg);
          });
      },
      updateFaq() {
        let data =  {
          id: this.faqId,
          status: this.status,
          question: this.question,
          answer: this.answer,
        }
        FaqDataService.changeFaqStatus(data)
          .then((response) => {
            this.$toast.success(response.data.respones_msg);
            this.$router.push({ path: 'admin-faq'})
          })
          .catch((e) => {
            this.$toast.error(e.response.data.respones_msg);
          });
      }
  },
  beforeMount() {
    this.editFaq();
  },
};
</script>