import DashboardLayout from "@/pages/Dashboard/Layout/DashboardLayout.vue";
import AuthLayout from "@/pages/Dashboard/Pages/AuthLayout.vue";
import SuperAdminAuthLayout from "@/pages/Dashboard/Pages/SuperAdminAuthLayout.vue";
import SuperAdminDashboardLayout from "@/pages/Dashboard/Layout/SuperAdmin/SuperAdminDashboardLayout.vue";

// Dashboard pages
import Dashboard from "@/pages/Dashboard/Dashboard.vue";

// Pages
import User from "@/pages/Dashboard/Pages/UserProfile.vue";
import Pricing from "@/pages/Dashboard/Pages/Pricing.vue";
import TimeLine from "@/pages/Dashboard/Pages/TimeLinePage.vue";
import RtlSupport from "@/pages/Dashboard/Pages/RtlSupport.vue";
import Login from "@/pages/Dashboard/Pages/Login.vue";
import Register from "@/pages/Dashboard/Pages/Register.vue";
import Lock from "@/pages/Dashboard/Pages/Lock.vue";

// Components pages
import Buttons from "@/pages/Dashboard/Components/Buttons.vue";
import GridSystem from "@/pages/Dashboard/Components/GridSystem.vue";
import Panels from "@/pages/Dashboard/Components/Panels.vue";
import SweetAlert from "@/pages/Dashboard/Components/SweetAlert.vue";
import Notifications from "@/pages/Dashboard/Components/Notifications.vue";
import Icons from "@/pages/Dashboard/Components/Icons.vue";
import Typography from "@/pages/Dashboard/Components/Typography.vue";

// Forms pages
import RegularForms from "@/pages/Dashboard/Forms/RegularForms.vue";
import ExtendedForms from "@/pages/Dashboard/Forms/ExtendedForms.vue";
import ValidationForms from "@/pages/Dashboard/Forms/ValidationForms.vue";
import Wizard from "@/pages/Dashboard/Forms/Wizard.vue";

// TableList pages
import RegularTables from "@/pages/Dashboard/Tables/RegularTables.vue";
import ExtendedTables from "@/pages/Dashboard/Tables/ExtendedTables.vue";
import PaginatedTables from "@/pages/Dashboard/Tables/PaginatedTables.vue";

// Maps pages
import GoogleMaps from "@/pages/Dashboard/Maps/GoogleMaps.vue";
import FullScreenMap from "@/pages/Dashboard/Maps/FullScreenMap.vue";
import VectorMaps from "@/pages/Dashboard/Maps/VectorMaps.vue";

// Calendar
import Calendar from "@/pages/Dashboard/Calendar.vue";
// Charts
import Charts from "@/pages/Dashboard/Charts.vue";
import Widgets from "@/pages/Dashboard/Widgets.vue";

//Login User Profile Routes
import ChangePassword from "@/pages/Auth/ChangePassword.vue";
import UpdateProfile from "@/pages/Auth/UpdateProfile.vue";
import AuditLogs from "@/pages/Auth/AuditLogs.vue";
import ExportList from "@/pages/ExportList.vue";
import ExportDetail from "@/pages/ExportDetail.vue";
import BecomeReseller from "@/pages/Reseller/BecomeReseller.vue";
import FcmUsersList from "@/pages/Fcm/UsersList.vue";
import FcmUsersCreate from "@/pages/Fcm/UsersCreate.vue";

// Forget Password
import ForgetPassword from "@/pages/Dashboard/Pages/ForgetPassword.vue";
import ResetPassword from "@/pages/Dashboard/Pages/ResetPassword.vue";

// Privacy & Security
import PrivacySecurity from "@/pages/Auth/PrivacySecurity.vue";
import PaymentMethods from "@/pages/Auth/PaymentMethods.vue";
import VerifyOtp from "@/pages/Dashboard/Pages/VerifyOtp.vue";

//Customer Routes
import PlanList from "@/pages/Customer/Payments/PlanList.vue";
import PaymentHistory from "@/pages/Customer/Payments/PaymentHistory.vue";
import updateSubscription from "@/pages/Customer/Payments/UpdateSubscription.vue";
import makePayment from "@/pages/Customer/Payments/MakePayment.vue";
import ProfilesList from "@/pages/Customer/Profiles/list.vue";
import ProfileDetail from "@/pages/Customer/Profiles/ProfileDetail.vue";
import SLProfileDetail from "@/pages/Customer/Profiles/SLProfileDetail.vue";
import PCProfileDetail from "@/pages/Customer/Profiles/PCProfileDetail.vue";
import UBProfileDetail from "@/pages/Customer/Profiles/UBProfileDetail.vue";
import KMProfileDetail from "@/pages/Customer/Profiles/KMProfileDetail.vue";
import DeviceLogs from "@/pages/Customer/DeviceLogs.vue";
import DownloadApk from "@/pages/Customer/DownloadApk.vue";

// Super Admin Auth
import SuperAdminLogin from "@/pages/SuperAdmin/Login.vue";
import SuperAdminVerifyOtp from "@/pages/SuperAdmin/VerifyOtp.vue";
import SuperAdminDashboard from "@/pages/SuperAdmin/Dashboard.vue";
import SuperAdminPaymentDashboard from "@/pages/SuperAdmin/Payment/PaymentDashboard.vue";
import SuperAdminChangePassword from "@/pages/SuperAdmin/ChangePassword.vue";
import SuperAdminPricing from "@/pages/SuperAdmin/Price/PricingList.vue";
import SuperAdminEditPricing from "@/pages/SuperAdmin/Price/EditPrice.vue";
import SuperAdminPaymentHistory from "@/pages/SuperAdmin/Payment/PaymentHistory.vue";
import SuperAdminPowerChatUsers from "@/pages/SuperAdmin/PowerChat/List.vue";
import SuperAdminBloatwareApps from "@/pages/SuperAdmin/Bloatware/List.vue";
import SuperAdminExportList from "@/pages/SuperAdmin/Exports/List.vue";
import SuperAdminAuditLogsList from "@/pages/SuperAdmin/AuditLogs/List.vue";
import SuperAdminDashboardHistory from "@/pages/SuperAdmin/DashboardHistory.vue";
import SuperAdminUpcomingExpirePaymentHistory from "@/pages/SuperAdmin/Payment/UpcomingExpirePayment.vue";
import SuperAdminCustomerList from "@/pages/SuperAdmin/Customer/list.vue";
import SuperAdminCustomerCreate from "@/pages/SuperAdmin/Customer/create.vue";
import SuperAdminCustomerSubscriptionCreate from "@/pages/SuperAdmin/Customer/createSubscription.vue";
import SuperAdminCustomerRefundPayment from "@/pages/SuperAdmin/Customer/RefundPayment.vue";
import SuperAdminCustomerEdit from "@/pages/SuperAdmin/Customer/Edit.vue";
import SuperAdminVideos from "@/pages/SuperAdmin/Videos/list.vue";
import SuperAdminContactUs from "@/pages/SuperAdmin/ContactUs.vue"
import SuperAdminNewsletters from "@/pages/SuperAdmin/Newsletters.vue"
import SuperAdminPageViewReport from "@/pages/SuperAdmin/PageViewReport.vue"
import SuperAdminFaq from "@/pages/SuperAdmin/Faq/list.vue";
import SuperAdminEditFaq from "@/pages/SuperAdmin/Faq/edit.vue";
import SuperAdminBlogCreate from "@/pages/SuperAdmin/Blog/create.vue";
import SuperAdminBlogList from "@/pages/SuperAdmin/Blog/list.vue";
import CouponList from "@/pages/SuperAdmin/Payment/CouponList.vue";
import PaymentErrorLogs from "@/pages/SuperAdmin/Payment/PaymentErrorLogs.vue";
import AllDevices from "@/pages/SuperAdmin/Customer/AllDevices.vue";
import DeviceNotifications from "@/pages/SuperAdmin/Customer/DeviceNotifications.vue";
import DeviceNotificationDetail from "@/pages/SuperAdmin/Customer/DeviceNotificationDetail.vue";

import auth from '../middleware/auth';
import beforeAuth from '../middleware/beforeAuth';

import beforeSuperAdminAuth from '../middleware/beforeSuperAdminAuth';
import superAdminAuth from '../middleware/superAdminAuth';

let componentsMenu = {
  path: "/components",
  component: DashboardLayout,
  redirect: "/components/buttons",
  name: "Components",
  children: [
    {
      path: "buttons",
      name: "Buttons",
      components: { default: Buttons },
    },
    {
      path: "grid-system",
      name: "Grid System",
      components: { default: GridSystem },
    },
    {
      path: "panels",
      name: "Panels",
      components: { default: Panels },
    },
    {
      path: "sweet-alert",
      name: "Sweet Alert",
      components: { default: SweetAlert },
    },
    {
      path: "notifications",
      name: "Notifications",
      components: { default: Notifications },
    },
    {
      path: "icons",
      name: "Icons",
      components: { default: Icons },
    },
    {
      path: "typography",
      name: "Typography",
      components: { default: Typography },
    },
  ],
};
let formsMenu = {
  path: "/forms",
  component: DashboardLayout,
  redirect: "/forms/regular",
  name: "Forms",
  children: [
    {
      path: "regular",
      name: "Regular Forms",
      components: { default: RegularForms },
    },
    {
      path: "extended",
      name: "Extended Forms",
      components: { default: ExtendedForms },
    },
    {
      path: "validation",
      name: "Validation Forms",
      components: { default: ValidationForms },
    },
    {
      path: "wizard",
      name: "Wizard",
      components: { default: Wizard },
    },
  ],
};

let tablesMenu = {
  path: "/table-list",
  component: DashboardLayout,
  redirect: "/table-list/regular",
  name: "Tables",
  children: [
    {
      path: "regular",
      name: "Regular Tables",
      components: { default: RegularTables },
    },
    {
      path: "extended",
      name: "Extended Tables",
      components: { default: ExtendedTables },
    },
    {
      path: "paginated",
      name: "Pagianted Tables",
      components: { default: PaginatedTables },
    },
  ],
};

let mapsMenu = {
  path: "/maps",
  component: DashboardLayout,
  name: "Maps",
  redirect: "/maps/google",
  children: [
    {
      path: "google",
      name: "Google Maps",
      components: { default: GoogleMaps },
    },
    {
      path: "full-screen",
      name: "Full Screen Map",
      meta: {
        hideContent: true,
        hideFooter: true,
        navbarAbsolute: true,
      },
      components: { default: FullScreenMap },
    },
    {
      path: "vector-map",
      name: "Vector Map",
      components: { default: VectorMaps },
    },
  ],
};

let pagesMenu = {
  path: "/pages",
  component: DashboardLayout,
  name: "Pages",
  redirect: "/pages/user",
  children: [
    {
      path: "user",
      name: "User Page",
      meta: {
        middleware: [auth],
      },
      components: { default: User },
    },
    {
      path: "timeline",
      name: "Timeline Page",
      components: { default: TimeLine },
    },
    {
      path: "rtl",
      name: "وحة القيادة",
      meta: {
        rtlActive: true,
      },
      components: { default: RtlSupport },
    },
  ],
};

let authPages = {
  path: "/",
  component: AuthLayout,
  name: "Authentication",
  children: [
    {
      path: "/login",
      name: "Login",
      component: Login,
      meta: {
        middleware: [beforeAuth],
      },
    },
    {
      path: "/verify-otp",
      name: "VerifyOtp",
      component: VerifyOtp,
      meta: {
        middleware: [beforeAuth],
      },
    },
    {
      path: "/register",
      name: "Register",
      component: Register,
      meta: {
        middleware: [beforeAuth],
      },
    },
    {
      path: "/forget-password",
      name: "ForgetPassword",
      component: ForgetPassword,
      meta: {
        middleware: [beforeAuth],
      },
    },
    {
      path: "/reset-password",
      name: "ResetPassword",
      component: ResetPassword,
      meta: {
        middleware: [beforeAuth],
      },
    },
    {
      path: "/pricing",
      name: "Pricing",
      component: Pricing,
    },
    {
      path: "/lock",
      name: "Lock",
      component: Lock,
    },
  ],
};

let superAdminAuthPages = {
  path: "/",
  component: SuperAdminAuthLayout,
  name: "Authentication",
  children: [
    {
      path: "/super-admin/login",
      name: "SuperAdminLogin",
      component: SuperAdminLogin,
      meta: {
        middleware: [beforeSuperAdminAuth],
      },
    },
    {
      path: "/super-admin/verify/otp",
      name: "Super Admin Verify OTP",
      component: SuperAdminVerifyOtp,
      meta: {
        middleware: [beforeSuperAdminAuth],
      },
    },
  ],
};

let superAdminDashboardPages = {
  path: "/",
  component: SuperAdminDashboardLayout,
  children: [
    {
      path: "admin-dashboard",
      name: "Super Admin Dashboard",
      component: SuperAdminDashboard,
      meta: {
        middleware: [superAdminAuth],
      },
    },
    {
      path: "admin-payment-dashboard",
      name: "Payment Dashboard",
      component: SuperAdminPaymentDashboard,
      meta: {
        middleware: [superAdminAuth],
      },
    },
    {
      path: "admin-change-password",
      name: "Change Password",
      component: SuperAdminChangePassword,
      meta: {
        middleware: [superAdminAuth],
      },
    },
    {
      path: "admin-pricing-list",
      name: "Pricing",
      component: SuperAdminPricing,
      meta: {
        middleware: [superAdminAuth],
      },
    },
    {
      path: "admin-pricing-edit",
      name: "Edit Pricing",
      component: SuperAdminEditPricing,
      meta: {
        middleware: [superAdminAuth],
      },
    },
    {
      path: "admin-payment-history",
      name: "Payment History",
      component: SuperAdminPaymentHistory,
      meta: {
        middleware: [superAdminAuth],
      },
    },
    {
      path: "admin-coupons",
      name: "Coupons",
      component: CouponList,
      meta: {
        middleware: [superAdminAuth],
      },
    },
    {
      path: "admin-payment-errors",
      name: "Payment Errors",
      component: PaymentErrorLogs,
      meta: {
        middleware: [superAdminAuth],
      },
    },
    {
      path: "admin-devices",
      name: "All Devices",
      component: AllDevices,
      meta: {
        middleware: [superAdminAuth],
      },
    },
    {
      path: "admin-custom-notifications",
      name: "Device Notifications",
      component: DeviceNotifications,
      meta: {
        middleware: [superAdminAuth],
      },
    },
    {
      path: "admin-notification-detail",
      name: "Device Notification Detail",
      component: DeviceNotificationDetail,
      meta: {
        middleware: [superAdminAuth],
      },
    },
    {
      path: "admin-power-chat-users",
      name: "Power Chat Users List",
      component: SuperAdminPowerChatUsers,
      meta: {
        middleware: [superAdminAuth],
      },
    },
    {
      path: "admin-bloatware-apps",
      name: "Bloatware Applications",
      component: SuperAdminBloatwareApps,
      meta: {
        middleware: [superAdminAuth],
      },
    },
    {
      path: "admin-exports-list",
      name: "Exports List",
      component: SuperAdminExportList,
      meta: {
        middleware: [superAdminAuth],
      },
    },
    {
      path: "admin-audit-logs",
      name: "Audit Logs",
      component: SuperAdminAuditLogsList,
      meta: {
        middleware: [superAdminAuth],
      },
    },
    {
      path: "admin-dashboard-history",
      name: "Dashboard History",
      component: SuperAdminDashboardHistory,
      meta: {
        middleware: [superAdminAuth],
      },
    },
    {
      path: "admin-upcoming-expire-payment-history",
      name: "Upcoming Expire Payment History",
      component: SuperAdminUpcomingExpirePaymentHistory,
      meta: {
        middleware: [superAdminAuth],
      },
    },
    {
      path: "admin-customer-list",
      name: "Customers List",
      component: SuperAdminCustomerList,
      meta: {
        middleware: [superAdminAuth],
      },
    },
    {
      path: "admin-customer-create",
      name: "Customers Create",
      component: SuperAdminCustomerCreate,
      meta: {
        middleware: [superAdminAuth],
      },
    },
    {
      path: "admin-customer-subscription-create",
      name: "Customers Subscription Create",
      component: SuperAdminCustomerSubscriptionCreate,
      meta: {
        middleware: [superAdminAuth],
      },
    },
    {
      path: "admin-customer-refund-payment",
      name: "Customers Refund Payment",
      component: SuperAdminCustomerRefundPayment,
      meta: {
        middleware: [superAdminAuth],
      },
    },
    {
      path: "admin-customer-edit",
      name: "Customers Edit",
      component: SuperAdminCustomerEdit,
      meta: {
        middleware: [superAdminAuth],
      },
    },
    {
      path: "admin-videos",
      name: "Videos",
      component: SuperAdminVideos,
      meta: {
        middleware: [superAdminAuth],
      },
    },
    {
      path: "admin-faq",
      name: "Faq",
      component: SuperAdminFaq,
      meta: {
        middleware: [superAdminAuth],
      },
    },
    {
      path: "admin-edit-faq",
      name: "Edit Faq",
      component: SuperAdminEditFaq,
      meta: {
        middleware: [superAdminAuth],
      },
    },
    {
      path: "admin-contactus",
      name: "Contact Us",
      component: SuperAdminContactUs,
      meta: {
        middleware: [superAdminAuth],
      },
    },
    {
      path: "admin-newsletters",
      name: "Newsletters",
      component: SuperAdminNewsletters,
      meta: {
        middleware: [superAdminAuth],
      },
    },
    {
      path: "admin-blog-create",
      name: "Blog Create",
      component: SuperAdminBlogCreate,
      meta: {
        middleware: [superAdminAuth],
      },
    },
    {
      path: "admin-blog-list",
      name: "Blogs List",
      component: SuperAdminBlogList,
      meta: {
        middleware: [superAdminAuth],
      },
    },
    {
      path: "admin-page-view-report",
      name: "Page View Report",
      component: SuperAdminPageViewReport,
      meta: {
        middleware: [superAdminAuth],
      },
    },
  ],
}



const routes = [
  {
    path: "/",
    redirect: "/login",
    meta: {
      middleware: [beforeAuth],
    },
  },
  componentsMenu,
  formsMenu,
  tablesMenu,
  mapsMenu,
  pagesMenu,
  authPages,
  superAdminAuthPages,
  superAdminDashboardPages,
  {
    path: "/",
    component: DashboardLayout,
    children: [
      {
        path: "dashboard",
        name: "Dashboard",
        components: { default: Dashboard },
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "change-password",
        name: "ChangePassword",
        components: { default: ChangePassword },
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "privacy-security",
        name: "PrivacySecurity",
        components: { default: PrivacySecurity },
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "payment-methods",
        name: "Payment Methods",
        components: { default: PaymentMethods },
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "update-profile",
        name: "UpdateProfile",
        components: { default: UpdateProfile },
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "audit-logs",
        name: "Audit Logs",
        components: { default: AuditLogs },
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "export-list",
        name: "Export List",
        components: { default: ExportList },
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "export-detail",
        name: "Export Detail",
        components: { default: ExportDetail },
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "plans",
        name: "Plans",
        components: { default: PlanList },
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "payment-history",
        name: "Payments History",
        components: { default: PaymentHistory },
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "update-subscription",
        name: "Update Subscription",
        components: { default: updateSubscription },
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "make-payment",
        name: "Make Payment",
        components: { default: makePayment },
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "profiles",
        name: "Profiles",
        components: { default: ProfilesList },
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "profile-detail",
        name: "Profile Detail",
        components: { default: ProfileDetail },
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "sl-profile-detail",
        name: "Profile Detail",
        components: { default: SLProfileDetail },
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "pc-profile-detail",
        name: "Power Cleaner Profile Detail",
        components: { default: PCProfileDetail },
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "ub-profile-detail",
        name: "URL Blocker Profile Detail",
        components: { default: UBProfileDetail },
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "km-profile-detail",
        name: "Key Mapper Profile Detail",
        components: { default: KMProfileDetail },
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "device-logs",
        name: "Device Logs",
        components: { default: DeviceLogs },
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "download-apk",
        name: "Download APK",
        components: { default: DownloadApk },
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "become-reseller",
        name: "Become Reseller",
        components: { default: BecomeReseller },
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "power-chat-users",
        name: "Power Chat",
        components: { default: FcmUsersList },
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "power-chat-user-create",
        name: "Power Chat User Create",
        components: { default: FcmUsersCreate },
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "calendar",
        name: "Calendar",
        components: { default: Calendar },
      },
      {
        path: "charts",
        name: "Charts",
        components: { default: Charts },
      },
      {
        path: "widgets",
        name: "Widgets",
        components: { default: Widgets },
      },
    ],
  },
];

export default routes;
