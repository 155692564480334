<template>
  <div>
    <div class="md-layout">
      <div class="md-layout-item md-medium-size-50 md-xsmall-size-100 md-size-33">
        <stats-card header-color="blue">
          <template slot="header">
            <div class="card-icon">
              <md-icon>payment</md-icon>
            </div>
            <p class="category">Total Purchased License</p>
            <h3 class="title">
              <a href="javascript:void(0)" :style="{ color: 'blue !important' }" @click="$router.push({ path: 'payment-history'})"><span>{{ purchasedDevices }}</span></a>
            </h3>
          </template>
        </stats-card>
      </div>
      <div class="md-layout-item md-medium-size-50 md-xsmall-size-100 md-size-33">
        <stats-card header-color="blue">
          <template slot="header">
            <div class="card-icon">
              <md-icon>payment</md-icon>
            </div>
            <p class="category">Total Used License</p>
            <h3 class="title">
              <a href="javascript:void(0)" :style="{ color: 'blue !important' }" @click="$router.push({ path: 'profiles'})"><span>{{ enrolDevices }}</span></a>
            </h3>
          </template>
        </stats-card>
      </div>
      <div class="md-layout-item md-medium-size-50 md-xsmall-size-100 md-size-33" :style="{ 'text-align':'center' }">
        <img :src="userDetail.loginQrCodeUrl" :style="{ 'width':'250px' }" />
        <br><small><b>Login QR Code</b></small>
      </div>
      <!--<div class="md-layout-item md-medium-size-50 md-xsmall-size-100 md-size-33">
        <stats-card header-color="rose">
          <template slot="header">
            <div class="card-icon">
              <md-icon>phone_android</md-icon>
            </div>
            <p class="category">Total PD Subscription Devices</p>
            <h3 class="title">
              <span>{{ pdSubscriptionDevices }}</span>
            </h3>
          </template>
        </stats-card>
      </div>
      <div class="md-layout-item md-medium-size-50 md-xsmall-size-100 md-size-33">
        <stats-card header-color="green">
          <template slot="header">
            <div class="card-icon">
              <md-icon>tablet</md-icon>
            </div>
            <p class="category">Total PD Onetime Devices</p>
            <h3 class="title">
              <span>{{ pdOnetimeDevices }}</span>
            </h3>
          </template>
        </stats-card>
      </div>-->
    </div>
    <!--<div class="md-layout">
      <div class="md-layout-item md-medium-size-50 md-xsmall-size-100 md-size-33">
        <stats-card header-color="blue">
          <template slot="header">
            <div class="card-icon">
              <md-icon>payment</md-icon>
            </div>
            <p class="category">Enrol Devices</p>
            <h3 class="title">
              <span>{{ enrolDevices }}</span>
            </h3>
          </template>
        </stats-card>
      </div>
      <div class="md-layout-item md-medium-size-50 md-xsmall-size-100 md-size-33">
        <stats-card header-color="rose">
          <template slot="header">
            <div class="card-icon">
              <md-icon>phone_android</md-icon>
            </div>
            <p class="category">Enrol PD Subscription Devices</p>
            <h3 class="title">
              <span>{{ enrolPDSubscriptionDevices }}</span>
            </h3>
          </template>
        </stats-card>
      </div>
      <div class="md-layout-item md-medium-size-50 md-xsmall-size-100 md-size-33">
        <stats-card header-color="green">
          <template slot="header">
            <div class="card-icon">
              <md-icon>tablet</md-icon>
            </div>
            <p class="category">Enrol PD Onetime Devices</p>
            <h3 class="title">
              <span>{{ enrolPDOTSubscriptionDevices }}</span>
            </h3>
          </template>
        </stats-card>
      </div>
    </div>-->
    <div class="md-layout">
      <div class="md-layout-item md-size-50 md-small-size-100">
        <chart-card
          header-animation="false"
          :chart-data="simpleBarChart.data"
          :chart-options="simpleBarChart.options"
          :chart-responsive-options="simpleBarChart.responsiveOptions"
          chart-type="Bar"
          header-icon
          chart-inside-content
          no-footer
          background-color="green"
        >
        <template slot="chartInsideHeader">
            <div class="card-icon">
              <md-icon>insert_chart</md-icon>
            </div>
            <h4 class="title">
              License Enrollment
              <small> - Month Wise</small>
            </h4>
          </template>
        </chart-card>
      </div>
      <div class="md-layout-item md-size-50 md-small-size-100">
        <chart-card
          header-animation="false"
          :chart-data="pieChart.data"
          :chart-options="pieChart.options"
          chart-type="Pie"
          header-icon
          chart-inside-content
          background-color="green"
        >
          <template slot="chartInsideHeader">
            <div class="card-icon">
              <md-icon>pie_chart</md-icon>
            </div>
            <h4 class="title">Application Wise License</h4>
          </template>
          <template slot="footer">
            <div class="md-layout">
              <div class="md-layout-item md-size-100">
                <h6 class="category">Legend</h6>
              </div>
              <div class="md-layout-item">
                <i class="fa fa-circle text-info"></i> Package Disabler
                <i class="fa fa-circle text-danger"></i> Owner App
                <i class="fa fa-circle text-warning"></i> Power Cleaner
                <i class="fa fa-circle text-primary"></i> ULR Blocker
                <i class="fa fa-circle text-success"></i> Key Mapper
                <i class="fa fa-circle text-secondary"></i> Power Chat
                <i class="fa fa-circle text-secondary"></i> Power On Boot
              </div>
            </div>
          </template>
        </chart-card>
      </div>
    </div>
  </div>
</template>

<script>
import DashboardService from "../../services/Customer/DashboardService";
import UsersDataService from "../../services/UsersDataService";
import {
  StatsCard,
  ChartCard,
} from "@/components";

export default {
  components: {
    StatsCard,
    ChartCard,
  },
  data() {
    return {
      purchasedDevices: 0,
      pdSubscriptionDevices: 0,
      pdOnetimeDevices: 0,
      enrolPDSubscriptionDevices: 0,
      enrolPDOTSubscriptionDevices: 0,
      enrolDevices: 0,

      simpleBarChart: null,
      pieChart: null,

      userDetail: null,
    };
  },
  methods: {
    GetAdminDetail() {
      var data = {};
      UsersDataService.getUserDetail(data)
        .then((response) => {
          this.userDetail = response.data.data;
        })
        .catch((e) => {
          this.$toast.error(e.response.data.respones_msg);
        });
    },
  },
  beforeMount(){
    this.GetAdminDetail()
  },
  mounted() {
  },
  async created() {
    await DashboardService.getDashboardData()
      .then((response) => {
        this.purchasedDevices = response.data.data.purchasedDevices;
        this.pdOnetimeDevices = response.data.data.pdOnetimeDevices;
        this.enrolDevices = response.data.data.enrolDevices;

        this.pieChart = {
            data: {
              labels: [response.data.data.pdEnrolledDevices, response.data.data.pdOWEnrolledDevices, response.data.data.pcEnrolledDevices, response.data.data.ubEnrolledDevices, response.data.data.kmEnrolledDevices, response.data.data.pChatUsers, response.data.data.pobEnrolledDevices, response.data.data.upEnrolledDevices],
              series: [response.data.data.pdEnrolledDevices, response.data.data.pdOWEnrolledDevices, response.data.data.pcEnrolledDevices, response.data.data.ubEnrolledDevices, response.data.data.kmEnrolledDevices, response.data.data.pChatUsers, response.data.data.pobEnrolledDevices, response.data.data.upEnrolledDevices],
            },
            options: {
              height: "230px",
            },
        }

        this.simpleBarChart = {
          data: {
            labels: ["Jan", "Feb", "March", "Apr", "May", "June", "July", "Aug", "Sept", "Oct", "Nov", "Dec"],
            series: [
              response.data.data.monthWiseDeviceCount,
            ],
          },
          options: {
            height: "230px",
            seriesBarDistance: 10,
            axisX: {
              showGrid: false,
            },
          },
          responsiveOptions: [
            [
              "screen and (max-width: 640px)",
              {
                seriesBarDistance: 5,
                axisX: {
                  labelInterpolationFnc: function (value) {
                    return value[0];
                  },
                },
              },
            ],
          ],
        }

      }).catch((e) => {
        this.$toast.error(e.response.data.respones_msg);
      });
  },
};
</script>