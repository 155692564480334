<template>
    <div>
        <div class="row">
            <div class="col-md-1"></div>
            <div class="col-md-5 wrapper_1">                
                <h4 class="color-white">Summary</h4>
                <hr>
                <p>
                    <span class="color-white">Product</span> 
                    <span class="float-right color-black">
                        <b>{{ planDetail.name }}</b>
                    </span>
                </p>
                <p>
                    <span class="color-white">Plan Price</span> 
                    <span class="float-right color-black">
                        <b> {{ formatCurrency(planDetail.amount) }}</b>
                    </span>
                </p>
                <p>
                    <span class="color-white">Current License</span> 
                    <span class="float-right color-black">
                        <b>{{ planDetail.totalPurchasedDevices }}</b>
                    </span>
                </p>
                <p>
                    <span class="color-white">License After Purchase</span> 
                    <span class="float-right color-black">
                        <b>{{ deviceAfterPurchase }}</b>
                    </span>
                </p>
                <p v-if="coupon">
                    <span class="color-white">Coupon</span> 
                    <span class="float-right color-black">
                        <b>{{ coupon }}</b>
                    </span>
                </p>
                <p v-if="coupon">
                    <span class="color-white">Coupon Discount</span> 
                    <span class="float-right color-black">
                        <b>{{ formatCurrency(discount) }}</b>
                    </span>
                </p>
                <hr>
                <p class="color-white">Total <span class="float-right" style="color:black">
                <b>{{ formatCurrency(this.total - this.discount) }}</b></span></p>
            </div>
            <div class="col-md-5 wrapper_2">
                <div v-if="!paymentSuccess">
                    <p v-if="defaultPaymentMethodDetail && defaultPaymentMethodDetail.type == 'card'">
                        <b>Default Card:</b> {{ defaultPaymentMethodDetail.card.display_brand.toUpperCase() }} - {{ defaultPaymentMethodDetail.card.last4 }} <a href="javascript:void(0)" @click="$router.push({ path: 'payment-methods'})"><i class="fa fa-pen text-primary"></i></a>
                    </p>
                    <md-label v-if="planDetail.identifier == 'PCHAT'"><b>Enter Number Of Users</b></md-label>
                    <md-label v-else><b>Enter Number Of Devices</b></md-label>
                    <md-field>
                        <md-input
                        placeholder="Qty" type="number" min="1" name="qty" v-model="qty"
                        style="box-sizing: border-box;height: 40px;padding: 10px 12px;border: 1px solid #ddd;border-radius: 4px;box-shadow: 0 1px 3px 0 #e6ebf1;"
                        @change="changePayableAmount()"
                        ></md-input>
                    </md-field>
                    <small class="text-danger" v-if="planDetail.identifier != 'PCHAT'"><b>If you want to add more than 1 device then buy in qty</b></small>
                    <div>
                        <vue-recaptcha ref="recaptcha" 
                            :sitekey="siteKey"
                            @verify="verifyCaptchaMethod"
                            >
                        </vue-recaptcha>
                    </div>
                    <div style="float: right">
                        <md-button class="md-success" type="submit" :disabled="isDisabled" @click="updateSubscription()">Update <span v-if="isDisabled"><i class="fa fa-spinner fa-spin"></i></span></md-button>
                    </div>
                </div>
                
                <div v-if="paymentSuccess">
                    <h3 class="text-center">Thank you for your payment, it’s processing</h3>
                    <!--<p class="text-center">Your order # is: 100000023</p>-->
                    <p class="text-center">You will receive an order confirmation email with details of your subscription.</p>
                    <div class="mb-3 text-center">
                        <button type="button" class="btn btn-success" @click="$router.push({ path: '/plans' })">Go Back</button>
                    </div>
                </div>
            </div>
            <div class="col-md-1"></div>
        </div>
    </div>
</template>
<script>
import PaymentService from "../../../services/Customer/PaymentService";
import { VueRecaptcha } from 'vue-recaptcha';

const appearance = {
  theme: "night",
};
let stripe = Stripe(process.env.VUE_APP_STRIPE_PK),
  elements = stripe.elements(appearance),
  card = undefined;

export default {
  components: {
    VueRecaptcha
  },
  data() {
    return {
      isDisabled: false,
      planDetail: [],
      priceId: this.$route.query.id,
      total: 0,
      qty: 1,
      deviceAfterPurchase: 0,
      paymentSuccess: false,
      coupon: "",
      discount: 0,
      siteKey: process.env.VUE_APP_NOCAPTCHA_SITEKEY,
      captcha: "",
      defaultPaymentMethodDetail: null,
    };
  },
  methods: {
      getPlanDetail() {
        let data = {
            id: this.priceId
        }
        PaymentService.getPlanDetail(data)
          .then((response) => {
            this.planDetail = response.data.data;
            this.total = this.planDetail.amount * this.qty;
            this.deviceAfterPurchase = this.planDetail.totalPurchasedDevices + this.qty;
            this.coupon = this.planDetail.subscriptionDetail.coupon;
            if (this.coupon) {
              this.applyCoupon();
            }
            this.defaultPaymentMethodDetail = response.data.defaultPaymentMethodDetail;
            this.$toast.success(response.data.respones_msg);
          })
          .catch((e) => {
            this.$toast.error(e.response.data.respones_msg);
          });
      },
      changePayableAmount() {
        if (this.qty == 0) {
            this.qty = 1
        }
        this.total = this.planDetail.amount * this.qty
        this.deviceAfterPurchase = parseInt(this.planDetail.totalPurchasedDevices) + parseInt(this.qty);
        if (this.coupon) {
          this.applyCoupon();
        }
      },

      updateSubscription() {
        let context = this;
        if (!this.captcha) {
            this.$toast.error("Please verify captcha.");
            return;  
        }
        this.isDisabled = true;
        var data = {
            qty: this.qty,
            priceId: this.priceId,
            captcha: this.captcha,
        };
        PaymentService.updateSubscription(data)
            .then((response) => {
                let confirmPaymentMethod = stripe.confirmCardPayment(response.data.data);
                if (this.planDetail.subscriptionDetail && this.planDetail.subscriptionDetail.paymentMethod) {
                    if (this.planDetail.subscriptionDetail.paymentMethod.type == 'paypal') {
                        confirmPaymentMethod = stripe.confirmPayPalPayment(response.data.data, {
                            return_url: window.location.href,
                        })
                    }
                }
                
                confirmPaymentMethod.then(function (result) {
                    if (result.error) {
                        context.$refs.recaptcha.reset();
                        context.isDisabled = false;
                        context.$toast.error(result.error.message);
                    } else {
                        context.isDisabled = false;
                        // The payment has been processed!
                        if (result.paymentIntent.status === "succeeded") {
                            context.$toast.success(response.data.respones_msg);
                            context.paymentSuccess = true;
                        }
                    }
                });
            })
            .catch((e) => {
                this.$refs.recaptcha.reset();
                this.isDisabled = false;    
                this.$toast.error(e.response.data.respones_msg);
                
            });
      },

      applyCoupon() {
          this.discount = 0;
          this.isDisabled = true;
          let data = { couponCode: this.coupon, priceId: this.priceId, qty: this.qty }
          PaymentService.applyCoupon(data)
          .then((response) => {
              this.isDisabled = false;
              this.discount = response.data.data;
              this.$toast.success(response.data.respones_msg);
          })
          .catch((e) => {
              this.isDisabled = false;
              this.$toast.error(e.response.data.respones_msg);
          });
      },
      verifyCaptchaMethod(recaptchaToken) {
        this.captcha = recaptchaToken;
      }
  },
  beforeMount() {
    this.getPlanDetail();
  },
};
</script>

<style scoped>
.wrapper_1 {
  background-color:#527eee;
  padding: 50px;
}
.wrapper_2 {
  background-color:var(--md-theme-default-background, #fff);
  padding: 50px;
}
.float-right{
  float: right;
}
.color-white{
  color: var(--md-theme-default-background, #fff);
}
.color-black{
  color: black;
}
.coupon{
    border-radius: 1px
}
</style>