<template>
    <div>
        <md-card>
            <md-card-content>
                <div class="md-layout">
                    <div class="md-layout-item md-size-50">
                        <md-field>
                            <md-input type="text" placeholder="Enter URL" v-model="url"></md-input>
                        </md-field>
                    </div>
                    <div class="md-layout-item md-size-30">
                        <md-button class="md-primary" :disabled="isDisabled" @click="addProfileBlockUrl()">Add</md-button> 
                    </div>
                </div>
                <div class="md-layout">
                    <div class="md-layout-item md-size-50">
                        <ul>
                            <li v-for="file in profileUrlData" :key="file">{{ file }}  <a href="javascript:void(0)" @click="deleteProfileBlockUrl(file)"><md-icon :style="{ color: '#f44336 !important' }">delete</md-icon></a> </li>
                        </ul>
                    </div>
                </div>
            </md-card-content>
        </md-card>
    </div>
</template>
<script>
import Swal from "sweetalert2";
import ProfileService from "../../services/Customer/ProfileService";

export default {
  name: 'ProfileFilesTab',
  props: {
    profileId: String,
  },
  data() {
    return {
      isDisabled: false,
        url:"",
        profileUrlData: [],
    }
  },
  methods: {
    profileDetail() {
        let data = { profileId: this.profileId }
        ProfileService.profileDetail(data)
          .then((response) => {
            this.profileUrlData = response.data.data.blockUrl;
            //this.$toast.success(response.data.respones_msg);
          })
          .catch((e) => {
            this.$toast.error(e.response.data.respones_msg);
          });
    },
    addProfileBlockUrl() {
        let data = { 
          profileId: this.profileId,
          url: this.url
        };
        ProfileService.addProfileBlockUrl(data)
          .then((response) => {
            this.url = "";
            this.profileDetail();
            this.$toast.success(response.data.respones_msg);
          }).catch((e) => {
            this.$toast.error(e.response.data.respones_msg);
            if (e.response.data.redirectToPaymentPage == true) {
              this.$router.push("plans");
            }
          });
    },
    deleteProfileBlockUrl(url) {
        let data = { 
          profileId: this.profileId,
          url: url
        };
        ProfileService.deleteProfileBlockUrl(data)
          .then((response) => {
            this.profileDetail();
            this.$toast.success(response.data.respones_msg);
          })
          .catch((e) => {
            this.$toast.error(e.response.data.respones_msg);
            if (e.response.data.redirectToPaymentPage == true) {
              this.$router.push("plans");
            }
          });
    }
  },
  beforeMount() {
    this.profileDetail();
  },
}
</script>