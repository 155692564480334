import http from "../../http-common";

class PaymentService {
  listPrice() {
    return http.get("payments/plans");
  }
  paymentHistory(data) {
    return http.get("payments/history", { params: data });
  }
  getPlanDetail(data) {
      return http.get("payments/subscription", { params: data });
  }
  updateSubscription(data) {
    return http.post("payments/subscription/update", data);
  }
  makePayment(data) {
    return http.post("payments/purchase", data);
  }
  applyCoupon(data) {
    return http.post("payments/coupon/apply", data);
  }
  getPriceDetail(data) {
    return http.get("payments/price/detail", { params: data });
  }
}

export default new PaymentService();