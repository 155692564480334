<template>
    <div>
      <md-card>
            <md-card-content>
                <form>
                    <div class="row">
                        <div class="col">
                            <md-field>
                                <label>Delivery Status</label>
                                <md-select v-model="isDelivered">
                                    <md-option value="">All</md-option>
                                    <md-option value="true">Delivered</md-option>
                                    <md-option value="false">Not Delivered</md-option>
                                </md-select>
                            </md-field>
                        </div>
                        <div class="col">
                            <md-field>
                                <label>Is Open</label>
                                <md-select v-model="isOpen">
                                    <md-option value="">All</md-option>
                                    <md-option value="true">Open</md-option>
                                    <md-option value="false">Not Open</md-option>
                                </md-select>
                            </md-field>
                        </div>
                        <div class="col">
                            <md-button class="md-success" :disabled="isDisabled" @click="getNotificationDetail()">Search<span v-if="isDisabled"><i class="fa fa-spinner fa-spin"></i></span></md-button>
                        </div>
                    </div>
                </form>
            </md-card-content>
        </md-card>
        <md-card>
            <md-card-content>
                <div class="md-layout">
                    <div class="md-layout-item md-size-100">
                        <md-table v-if="tableData.length > 0 " v-model="queriedData" 
                        class="md-container-custom paginated-table" table-header-color="green" md-fixed-header>
                            <md-table-toolbar>
                                <md-field>
                                    <label for="pages">Per page</label>
                                    <md-select v-model="pagination.perPage" name="pages">
                                    <md-option
                                        v-for="item in pagination.perPageOptions"
                                        :key="item"
                                        :label="item"
                                        :value="item"
                                    >
                                        {{ item }}
                                    </md-option>
                                    </md-select>
                                </md-field>
                              </md-table-toolbar>
                            <md-table-row slot="md-table-row" slot-scope="{ item }">            
                                <md-table-cell md-label="Message">
                                    <span v-if="item.deviceId">{{ item.deviceId.serialNumber }} - {{ item.deviceId.deviceName }}</span>
                                    <span v-else>-</span>
                                </md-table-cell>
                                <md-table-cell md-label="Is Delivered">{{ item.isDelivered }}</md-table-cell>
                                <md-table-cell md-label="Is Open">{{ item.isOpen }}</md-table-cell>
                                <md-table-cell md-label="Open At">
                                    <span v-if="item.openDate">{{ HumanReadableDateFormat(item.openDate) }}</span>
                                    <span v-else>-</span>
                                </md-table-cell>
                            </md-table-row>
                        </md-table>
                        <md-card-actions md-alignment="space-between">
                            <div class="">
                                <p class="card-category">
                                Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
                                </p>
                            </div>
                            <pagination
                                class="pagination-no-border pagination-success"
                                v-model="pagination.currentPage"
                                :per-page="pagination.perPage"
                                :total="total"
                            >
                            </pagination>
                        </md-card-actions>
                        <h4 v-if="tableData.length == 0">No data found.</h4>
                    </div>
                </div>
            </md-card-content>
        </md-card>
    </div>
</template>
<script>
import NotificationService from "../../../services/SuperAdmin/NotificationService";
import { Pagination } from "@/components";

export default {
  components: {
    Pagination
  },
  computed: {
        queriedData() {
          let result = this.tableData;
          if (this.searchedData.length > 0) {
              result = this.searchedData;
          }
          return result.slice(this.from, this.to);
        },
        to() {
          let highBound = this.from + this.pagination.perPage;
          if (this.total < highBound) {
              highBound = this.total;
          }
          return highBound;
        },
        from() {
          return this.pagination.perPage * (this.pagination.currentPage - 1);
        },
        total() {
          return this.searchedData.length > 0
              ? this.searchedData.length
              : this.tableData.length;
        },
  },
  data() {
    return {
      notificationId: this.$route.query.id,
      isDisabled: false,
      tableData: [],
      notificationDetail: null,
      isOpen: "",
      isDelivered: "",

      //Pagination
      pagination: {
          perPage: 25,
          currentPage: 1,
          perPageOptions: [5, 10, 25, 50],
          total: 0,
      },
      searchedData: [],
    };
  },
  methods: {
      getNotificationDetail() {
        this.isDisabled = true;
        let data = { id: this.notificationId, isOpen: this.isOpen, isDelivered: this.isDelivered };
        NotificationService.getNotificationDetail(data)
          .then((response) => {
            this.isDisabled = false;
            this.notificationDetail = response.data.data;
            this.tableData = response.data.data.notificationDetail;
          }).catch((e) => {
            this.isDisabled = false;
            this.$toast.error(e.response.data.respones_msg);
          });
      },
  },
  beforeMount() {
    this.getNotificationDetail();
  },
};
</script>