<template>
    <div>
        <md-card>
            <md-card-content>
                <div class="md-layout">
                  <div class="md-layout-item md-size-33">
                      <md-field>
                      <md-input type="text" class="box-textbox" placeholder="App Name" v-model="appName"></md-input>
                      </md-field>
                  </div>
                  <div class="md-layout-item md-size-33">
                      <md-button class="md-primary" :disabled="isDisabled"  @click="listProfile()">Search 
                        <span v-if="isDisabled"><i class="fa fa-spinner fa-spin"></i></span></md-button> 
                  </div>
                </div>
                <div class="md-layout">
                    <div class="md-layout-item md-size-100">
                        <md-table v-if="tableData.length > 0 " 
                          v-model="tableData" 
                          :md-sort.sync="currentSort"
                          :md-sort-order.sync="currentSortOrder"
                          :md-sort-fn="customSort"
                          class="md-container-custom paginated-table" table-header-color="green" md-fixed-header>
                            <md-table-row slot="md-table-row" slot-scope="{ item }">            
                                <md-table-cell md-label="Serial Number" md-sort-by="name">
                                    <a v-if="item.appName == 'PC' || item.appName == 'PCOT'" @click="$router.push({ path: 'pc-profile-detail', query: { profile_id: item._id }})" href="javascript:void(0)">{{ item.name }}</a>
                                    <a v-else-if="item.appName == 'UB' || item.appName == 'UBOT'" @click="$router.push({ path: 'ub-profile-detail', query: { profile_id: item._id }})" href="javascript:void(0)">{{ item.name }}</a>
                                    <a v-else-if="item.appName == 'KM' || item.appName == 'KMOT'" @click="$router.push({ path: 'km-profile-detail', query: { profile_id: item._id }})" href="javascript:void(0)">{{ item.name }}</a>
                                    <a v-else-if="item.appName == 'PD' || item.appName == 'PDOT'|| item.appName == 'PDOW'|| item.appName == 'PDOWOT'" @click="$router.push({ path: 'profile-detail', query: { profile_id: item._id }})" href="javascript:void(0)">{{ item.name }}</a>
                                    <a v-else-if="item.appName == 'SL' || item.appName == 'SLOT'" @click="$router.push({ path: 'sl-profile-detail', query: { profile_id: item._id }})" href="javascript:void(0)">{{ item.name }}</a>
                                    <a v-else href="javascript:void(0)">{{ item.name }}</a>
                                </md-table-cell>
                                <md-table-cell md-label="Deivce Name" md-sort-by="profileName">
                                    {{ item.profileName }}
                                </md-table-cell>
                                <md-table-cell md-label="appName" md-sort-by="appName">
                                  <span :class="'badge-'+item.appName">{{ appNameFull(item.appName) }}</span>
                                </md-table-cell>
                                <md-table-cell md-label="Created On" md-sort-by="created_at">{{ HumanReadableDateFormat(item.created_at) }}</md-table-cell>
                            </md-table-row>
                        </md-table>
                        <h4 v-else>No data found.</h4>
                    </div>
                </div>
            </md-card-content>
        </md-card>
    </div>
</template>
<script>
import ProfileService from "../../../services/Customer/ProfileService";

export default {
  components: {
  },
  data() {
    return {
      isDisabled: false,
      tableData: [],
      priceId: this.$route.query.priceId,
      appName: "",

      //Sorting
      currentSort: "created_at",
      currentSortOrder: "asc",
    };
  },
  methods: {
      customSort(value) {
        return value.sort((a, b) => {
          const sortBy = this.currentSort;
          if (this.currentSortOrder === "desc") {
            return a[sortBy].localeCompare(b[sortBy]);
          }
          return b[sortBy].localeCompare(a[sortBy]);
        });
      },
      listProfile() {
        this.isDisabled = true;
        let data = { appName: this.appName }
        ProfileService.listProfile(data)
          .then((response) => {
            this.isDisabled = false;
            this.tableData = response.data.data;
            this.$toast.success(response.data.respones_msg);
          })
          .catch((e) => {
            this.isDisabled = false;
            this.$toast.error(e.response.data.respones_msg);
          });
      },
  },
  beforeMount() {
    this.listProfile();
  },
};
</script>
