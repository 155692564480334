import http from "../../http-common-super-admin";

class NotificationService {
  allDeviceslist(data) {
    return http.get("notification/devices/list", { params: data });
  }
  send(data) {
    return http.post("notification/send", data);
  }
  getNotificationList() {
    return http.get("notification/list");
  }
  getNotificationDetail(data) {
    return http.get("notification/detail", { params: data });
  }
  searchCustomer(data) {
    return http.get("notification/customer/search", { params: data });
  }
}

export default new NotificationService();