<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-100">
      <div style="float: right">
        <md-button class="md-success" :disabled="isDisabled" @click="syncPricing()">Sync Stripe Price</md-button>
      </div>
      <tabs :tab-name="[ 'Subscriptions', 'One Time', 'Multi Currency']" color-button="warning">
        <template slot="tab-pane-1">
            <md-card>
                <md-card-content>
                    <div class="md-layout">
                        <div class="md-layout-item md-size-100">
                            <md-table v-if="recurringPriceList.length > 0 " v-model="recurringPriceList" table-header-color="green">
                                <md-table-row slot="md-table-row" slot-scope="{ item }">            
                                    <md-table-cell md-label="Amount"> <b>{{ item.currency }} {{ item.amount }} / <small>{{ item.interval }}</small></b></md-table-cell>
                                    <md-table-cell md-label="Name">{{ item.name }} <br> <small>{{ item.priceId }}</small></md-table-cell>
                                    <md-table-cell md-label="Status">
                                        <p class="badge-success" v-if="item.status == 'Active'">Active</p>
                                        <p class="badge-danger" v-else>InActive</p>
                                    </md-table-cell>
                                    <md-table-cell md-label="Created On">{{ HumanReadableDateFormat(item.created_at) }}</md-table-cell>
                                    <md-table-cell md-label="Actions">
                                        <md-button class="md-just-icon md-success md-simple" @click="$router.push({ path: 'admin-pricing-edit', query: { id: item._id }})"><md-icon>edit</md-icon></md-button>
                                        <md-button class="md-just-icon md-info md-simple" @click="openAndriodVersionCodeModal(item.name, item.identifier)"><md-icon title="Configure App Version">smartphone</md-icon></md-button>
                                    </md-table-cell>
                                </md-table-row>
                            </md-table>
                            <h4 v-else>No data found.</h4>
                        </div>
                    </div>
                </md-card-content>
            </md-card>
        </template>
        <template slot="tab-pane-2">
            <md-card>
                <md-card-content>
                    <div class="md-layout">
                        <div class="md-layout-item md-size-100">
                            <md-table v-if="oneTimePriceList.length > 0 " v-model="oneTimePriceList" table-header-color="green">
                                <md-table-row slot="md-table-row" slot-scope="{ item }">            
                                    <md-table-cell md-label="Amount"> <b>{{ item.currency }} {{ item.amount }} </b></md-table-cell>
                                    <md-table-cell md-label="Name">{{ item.name }} <br> <small>{{ item.priceId }}</small></md-table-cell>
                                    <md-table-cell md-label="Status">
                                        <p class="badge-success" v-if="item.status == 'Active'">Active</p>
                                        <p class="badge-danger" v-else>InActive</p>
                                    </md-table-cell>
                                    <md-table-cell md-label="Created On">{{ HumanReadableDateFormat(item.created_at) }}</md-table-cell>
                                    <md-table-cell md-label="Actions">
                                        <md-button class="md-just-icon md-success md-simple" @click="$router.push({ path: 'admin-pricing-edit', query: { id: item._id }})"><md-icon>edit</md-icon></md-button>
                                        <md-button class="md-just-icon md-info md-simple" @click="openAndriodVersionCodeModal(item.name, item.identifier)"><md-icon title="Configure App Version">smartphone</md-icon></md-button>
                                    </md-table-cell>
                                </md-table-row>
                            </md-table>
                            <h4 v-else>No data found.</h4>
                        </div>
                    </div>
                </md-card-content>
            </md-card>
        </template>
        <template slot="tab-pane-3">
            <md-card>
                <md-card-content>
                    <div class="row">
                      <div class="col">
                          <md-field>
                              <select v-model="countryCode" class="form-control">
                                  <option value="">Select Country</option>
                                  <option v-for="(value, key) in globalCountryList" :key="key" :value="key">{{ value }}</option>
                              </select>
                          </md-field>
                      </div>
                      <div class="col">
                          <md-field>
                              <label>Rate (1 GBP = 100 Rs)</label>
                              <md-input v-model="conversationRate" type="text"></md-input>
                          </md-field>
                      </div>
                      <div class="col">
                          <md-field>
                              <label>Coupon Code</label>
                              <md-input v-model="couponCode" type="text"></md-input>
                          </md-field>
                      </div>
                      <div class="col">
                          <md-field>
                              <label>Discount %</label>
                              <md-input v-model="discountPercentage" type="text"></md-input>
                          </md-field>
                      </div>
                      <div class="col">
                          <md-field>
                              <label>Currency Code</label>
                              <md-input v-model="currencyCode" type="text"></md-input>
                          </md-field>
                      </div>
                      <div class="col">
                            <md-button class="md-success" v-if="!editCurrencyId" :disabled="isDisabled" @click="addCurrency()">Add<span v-if="isDisabled"><i class="fa fa-spinner fa-spin"></i></span></md-button>
                            <md-button class="md-success" v-if="editCurrencyId" :disabled="isDisabled" @click="updateCurrency()">Update<span v-if="isDisabled"><i class="fa fa-spinner fa-spin"></i></span></md-button>
                        </div>
                    </div>
                </md-card-content>
            </md-card>
            <md-card>
                <md-card-content>
                  <div style="float: right">
                    <md-button class="md-success" :disabled="isDisabled" @click="syncCurrencyRate()">Sync Currency Rate</md-button>
                  </div>
                    <div class="md-layout">
                        <div class="md-layout-item md-size-100">
                            <md-table v-if="currencyList.length > 0 " v-model="currencyList" table-header-color="green">
                                <md-table-row slot="md-table-row" slot-scope="{ item }">            
                                    <md-table-cell md-label="Country"> <b>{{ item.countryCode }} </b></md-table-cell>
                                    <md-table-cell md-label="Rate">{{ item.conversationRate }}</md-table-cell>
                                    <md-table-cell md-label="Coupon">{{ item.couponCode }}</md-table-cell>
                                    <md-table-cell md-label="Discount">{{ item.discountPercentage }}</md-table-cell>
                                    <md-table-cell md-label="Currency">{{ item.currencyCode }}</md-table-cell>
                                    <md-table-cell md-label="Created On">{{ HumanReadableDateFormat(item.created_at) }}</md-table-cell>
                                    <md-table-cell md-label="Actions">
                                        <div>  
                                          <md-button class="md-just-icon md-success md-simple" @click="editCurrency(item)"><md-icon title="Edit">edit</md-icon></md-button>&nbsp;                  
                                          <md-button class="md-just-icon md-danger md-simple" @click="deleteCurrency(item._id)"><md-icon>close</md-icon></md-button>
                                        </div>
                                    </md-table-cell>
                                </md-table-row>
                            </md-table>
                            <h4 v-else>No data found.</h4>
                        </div>
                    </div>
                </md-card-content>
            </md-card>
        </template>
      </tabs>
      <!-- Configure Andriod Version Code Modal -->
      <template>
          <modal v-if="andriodVersioncodeModal" @close="andriodVersioncodeModal = false">
            <template slot="header">
              <h4 class="modal-title">Configure {{ planName }}</h4>
              <md-button
                style="height: 25px" class="md-simple md-just-icon md-round modal-default-button"
                @click="andriodVersioncodeModal = false"><md-icon>clear</md-icon></md-button>
            </template>
            <template slot="body">
              <div class="box-body">
                <div class="md-layout">
                  <div class="md-layout-item md-size-100">
                    <md-field>
                      <label>Min Version Code</label>
                      <md-input type="text" v-model="minAppVersionCode"></md-input>
                    </md-field>
                  </div>
                  <div class="md-layout-item md-size-100">
                    <md-field>
                      <label>Message</label>
                      <md-input type="text" v-model="message"></md-input>
                    </md-field>
                  </div>
                  <div class="md-layout-item md-size-100">
                    <md-switch v-model="forceUpdate">Force Update</md-switch>
                  </div>
                </div>
              </div>
            </template>
            <template slot="footer">
              <md-button class="md-danger md-simple" @click="andriodVersioncodeModal = false">Close</md-button>
              <md-button class="md-success" :disabled="isDisabled" @click="updateAppVersion()">Save</md-button>
            </template>
          </modal>
      </template>
    </div>
  </div>
</template>
<script>
import PriceDataService from "../../../services/SuperAdmin/PriceDataService";
import { Tabs } from "@/components";
import { Modal } from "@/components";
import Swal from "sweetalert2";

export default {
  components: {
    Tabs,
    Modal,
  },
  data() {
    return {
      isDisabled: false,
      tableData: [],
      oneTimePriceList: [],
      recurringPriceList: [],

      andriodVersioncodeModal: false,
      minAppVersionCode: 0,
      message:"",
      forceUpdate: false,
      planName: "",
      planIdentifier: "",

      //Multi Currency
      countryCode: "",
      conversationRate: "",
      couponCode: "",
      discountPercentage: "",
      currencyCode: "",
      currencyList: [],
      editCurrencyId: "",
      globalCountryList: {
          "AF": "Afghanistan",
          "AL": "Albania",
          "DZ": "Algeria",
          "AS": "American Samoa",
          "AD": "Andorra",
          "AO": "Angola",
          "AI": "Anguilla",
          "AQ": "Antarctica",
          "AG": "Antigua and Barbuda",
          "AR": "Argentina",
          "AM": "Armenia",
          "AW": "Aruba",
          "AU": "Australia",
          "AT": "Austria",
          "AZ": "Azerbaijan",
          "BS": "Bahamas (the)",
          "BH": "Bahrain",
          "BD": "Bangladesh",
          "BB": "Barbados",
          "BY": "Belarus",
          "BE": "Belgium",
          "BZ": "Belize",
          "BJ": "Benin",
          "BM": "Bermuda",
          "BT": "Bhutan",
          "BO": "Bolivia (Plurinational State of)",
          "BQ": "Bonaire, Sint Eustatius and Saba",
          "BA": "Bosnia and Herzegovina",
          "BW": "Botswana",
          "BV": "Bouvet Island",
          "BR": "Brazil",
          "IO": "British Indian Ocean Territory (the)",
          "BN": "Brunei Darussalam",
          "BG": "Bulgaria",
          "BF": "Burkina Faso",
          "BI": "Burundi",
          "CV": "Cabo Verde",
          "KH": "Cambodia",
          "CM": "Cameroon",
          "CA": "Canada",
          "KY": "Cayman Islands (the)",
          "CF": "Central African Republic (the)",
          "TD": "Chad",
          "CL": "Chile",
          "CN": "China",
          "CX": "Christmas Island",
          "CC": "Cocos (Keeling) Islands (the)",
          "CO": "Colombia",
          "KM": "Comoros (the)",
          "CD": "Congo (the Democratic Republic of the)",
          "CG": "Congo (the)",
          "CK": "Cook Islands (the)",
          "CR": "Costa Rica",
          "HR": "Croatia",
          "CU": "Cuba",
          "CW": "Curaçao",
          "CY": "Cyprus",
          "CZ": "Czechia",
          "CI": "Côte d'Ivoire",
          "DK": "Denmark",
          "DJ": "Djibouti",
          "DM": "Dominica",
          "DO": "Dominican Republic (the)",
          "EC": "Ecuador",
          "EG": "Egypt",
          "SV": "El Salvador",
          "GQ": "Equatorial Guinea",
          "ER": "Eritrea",
          "EE": "Estonia",
          "SZ": "Eswatini",
          "ET": "Ethiopia",
          "FK": "Falkland Islands (the) [Malvinas]",
          "FO": "Faroe Islands (the)",
          "FJ": "Fiji",
          "FI": "Finland",
          "FR": "France",
          "GF": "French Guiana",
          "PF": "French Polynesia",
          "TF": "French Southern Territories (the)",
          "GA": "Gabon",
          "GM": "Gambia (the)",
          "GE": "Georgia",
          "DE": "Germany",
          "GH": "Ghana",
          "GI": "Gibraltar",
          "GR": "Greece",
          "GL": "Greenland",
          "GD": "Grenada",
          "GP": "Guadeloupe",
          "GU": "Guam",
          "GT": "Guatemala",
          "GG": "Guernsey",
          "GN": "Guinea",
          "GW": "Guinea-Bissau",
          "GY": "Guyana",
          "HT": "Haiti",
          "HM": "Heard Island and McDonald Islands",
          "VA": "Holy See (the)",
          "HN": "Honduras",
          "HK": "Hong Kong",
          "HU": "Hungary",
          "IS": "Iceland",
          "IN": "India",
          "ID": "Indonesia",
          "IR": "Iran (Islamic Republic of)",
          "IQ": "Iraq",
          "IE": "Ireland",
          "IM": "Isle of Man",
          "IL": "Israel",
          "IT": "Italy",
          "JM": "Jamaica",
          "JP": "Japan",
          "JE": "Jersey",
          "JO": "Jordan",
          "KZ": "Kazakhstan",
          "KE": "Kenya",
          "KI": "Kiribati",
          "KP": "Korea (the Democratic People's Republic of)",
          "KR": "Korea (the Republic of)",
          "KW": "Kuwait",
          "KG": "Kyrgyzstan",
          "LA": "Lao People's Democratic Republic (the)",
          "LV": "Latvia",
          "LB": "Lebanon",
          "LS": "Lesotho",
          "LR": "Liberia",
          "LY": "Libya",
          "LI": "Liechtenstein",
          "LT": "Lithuania",
          "LU": "Luxembourg",
          "MO": "Macao",
          "MG": "Madagascar",
          "MW": "Malawi",
          "MY": "Malaysia",
          "MV": "Maldives",
          "ML": "Mali",
          "MT": "Malta",
          "MH": "Marshall Islands (the)",
          "MQ": "Martinique",
          "MR": "Mauritania",
          "MU": "Mauritius",
          "YT": "Mayotte",
          "MX": "Mexico",
          "FM": "Micronesia (Federated States of)",
          "MD": "Moldova (the Republic of)",
          "MC": "Monaco",
          "MN": "Mongolia",
          "ME": "Montenegro",
          "MS": "Montserrat",
          "MA": "Morocco",
          "MZ": "Mozambique",
          "MM": "Myanmar",
          "NA": "Namibia",
          "NR": "Nauru",
          "NP": "Nepal",
          "NL": "Netherlands (the)",
          "NC": "New Caledonia",
          "NZ": "New Zealand",
          "NI": "Nicaragua",
          "NE": "Niger (the)",
          "NG": "Nigeria",
          "NU": "Niue",
          "NF": "Norfolk Island",
          "MP": "Northern Mariana Islands (the)",
          "NO": "Norway",
          "OM": "Oman",
          "PK": "Pakistan",
          "PW": "Palau",
          "PS": "Palestine, State of",
          "PA": "Panama",
          "PG": "Papua New Guinea",
          "PY": "Paraguay",
          "PE": "Peru",
          "PH": "Philippines (the)",
          "PN": "Pitcairn",
          "PL": "Poland",
          "PT": "Portugal",
          "PR": "Puerto Rico",
          "QA": "Qatar",
          "MK": "Republic of North Macedonia",
          "RO": "Romania",
          "RU": "Russian Federation (the)",
          "RW": "Rwanda",
          "RE": "Réunion",
          "BL": "Saint Barthélemy",
          "SH": "Saint Helena, Ascension and Tristan da Cunha",
          "KN": "Saint Kitts and Nevis",
          "LC": "Saint Lucia",
          "MF": "Saint Martin (French part)",
          "PM": "Saint Pierre and Miquelon",
          "VC": "Saint Vincent and the Grenadines",
          "WS": "Samoa",
          "SM": "San Marino",
          "ST": "Sao Tome and Principe",
          "SA": "Saudi Arabia",
          "SN": "Senegal",
          "RS": "Serbia",
          "SC": "Seychelles",
          "SL": "Sierra Leone",
          "SG": "Singapore",
          "SX": "Sint Maarten (Dutch part)",
          "SK": "Slovakia",
          "SI": "Slovenia",
          "SB": "Solomon Islands",
          "SO": "Somalia",
          "ZA": "South Africa",
          "GS": "South Georgia and the South Sandwich Islands",
          "SS": "South Sudan",
          "ES": "Spain",
          "LK": "Sri Lanka",
          "SD": "Sudan (the)",
          "SR": "Suriname",
          "SJ": "Svalbard and Jan Mayen",
          "SE": "Sweden",
          "CH": "Switzerland",
          "SY": "Syrian Arab Republic",
          "TW": "Taiwan",
          "TJ": "Tajikistan",
          "TZ": "Tanzania, United Republic of",
          "TH": "Thailand",
          "TL": "Timor-Leste",
          "TG": "Togo",
          "TK": "Tokelau",
          "TO": "Tonga",
          "TT": "Trinidad and Tobago",
          "TN": "Tunisia",
          "TR": "Turkey",
          "TM": "Turkmenistan",
          "TC": "Turks and Caicos Islands (the)",
          "TV": "Tuvalu",
          "UG": "Uganda",
          "UA": "Ukraine",
          "AE": "United Arab Emirates (the)",
          "GB": "United Kingdom of Great Britain and Northern Ireland (the)",
          "UM": "United States Minor Outlying Islands (the)",
          "US": "United States of America (the)",
          "UY": "Uruguay",
          "UZ": "Uzbekistan",
          "VU": "Vanuatu",
          "VE": "Venezuela (Bolivarian Republic of)",
          "VN": "Viet Nam",
          "VG": "Virgin Islands (British)",
          "VI": "Virgin Islands (U.S.)",
          "WF": "Wallis and Futuna",
          "EH": "Western Sahara",
          "YE": "Yemen",
          "ZM": "Zambia",
          "ZW": "Zimbabwe",
          "AX": "Åland Islands"
      }
    };
  },
  methods: {
      syncPricing() {
        this.isDisabled = true;
        PriceDataService.syncPricing()
          .then((response) => {
            this.isDisabled = false;
            this.$toast.success(response.data.respones_msg);
            this.listPrice();
          })
          .catch((e) => {
            this.isDisabled = false;
            this.$toast.error(e.response.data.respones_msg);
          });
      },
      listPrice() {
        PriceDataService.listPrice()
          .then((response) => {
            this.recurringPriceList = response.data.data.recurringPriceList;
            this.oneTimePriceList = response.data.data.oneTimePriceList;
            this.$toast.success(response.data.respones_msg);
          })
          .catch((e) => {
            this.$toast.error(e.response.data.respones_msg);
          });
      },
      openAndriodVersionCodeModal(planName, planIdentifier) {
        this.minAppVersionCode =  "";
        this.message = "";
        this.forceUpdate = false;
        this.planName = planName;
        this.planIdentifier = planIdentifier;
        this.andriodVersioncodeModal = true;
        PriceDataService.getAppVersionDetail({ planIdentifier: planIdentifier })
          .then((response) => {
            if (response.data.data) {
              this.minAppVersionCode = response.data.data.minAppVersionCode;
              this.message = response.data.data.message;
              this.forceUpdate = response.data.data.forceUpdate;
            }
            this.$toast.success(response.data.respones_msg);
          })
          .catch((e) => {
            this.$toast.error(e.response.data.respones_msg);
          });  
      },
      updateAppVersion() {
        let data =  {
          planIdentifier: this.planIdentifier,
          minAppVersionCode: this.minAppVersionCode,
          message: this.message,
          forceUpdate: this.forceUpdate
        }
        PriceDataService.updateAppVersion(data)
          .then((response) => {
            this.andriodVersioncodeModal = false;
            this.minAppVersionCode =  "";
            this.message = "";
            this.forceUpdate = false;
            this.$toast.success(response.data.respones_msg);
          })
          .catch((e) => {
            this.$toast.error(e.response.data.respones_msg);
          }); 
      },
      addCurrency() {
        this.isDisabled = true;
        let data =  {
          countryCode: this.countryCode,
          conversationRate: this.conversationRate,
          couponCode: this.couponCode,
          discountPercentage: this.discountPercentage,
          currencyCode: this.currencyCode
        }
        PriceDataService.addCurrency(data)
          .then((response) => {
            this.isDisabled = false;
            this.couponCode = ""; this.discountPercentage = ""; this.conversationRate =  ""; this.countryCode = "";
            this.getCurrency();
            this.$toast.success(response.data.respones_msg);
          }).catch((e) => {
            this.isDisabled = false;
            this.$toast.error(e.response.data.respones_msg);
          }); 
      },
      getCurrency() {
        PriceDataService.getCurrency()
          .then((response) => {
            this.currencyList = response.data.data;
          }).catch((e) => {
            this.isDisabled = false;
            this.$toast.error(e.response.data.respones_msg);
          }); 
      },
      deleteCurrency(id) {
        this.editCurrencyId = "";
        let data =  {
          id: id
        }
        Swal.fire({
            title: "Are you sure?",
            html: '<p>Currency will be removed from this list</p>',
            type: "warning",
            showCancelButton: true,
            confirmButtonClass: "md-button md-success",
            cancelButtonClass: "md-button md-danger",
            confirmButtonText: "Yes, delete it!",
            buttonsStyling: false,
        }).then((result) => {
            if (result.value) {
                PriceDataService.deleteCurrency(data)
                .then((response) => {
                    this.$toast.success(response.data.respones_msg);
                    this.getCurrency();
                }).catch((e) => {
                    this.$toast.error(e.response.data.respones_msg);
                }); 
            }
        });
      },
      
      editCurrency(item) {
        this.countryCode =  item.countryCode;
        this.conversationRate = item.conversationRate;
        this.couponCode = item.couponCode;
        this.discountPercentage = item.discountPercentage;
        this.currencyCode =  item.currencyCode;
        this.editCurrencyId = item._id;
      },

      updateCurrency() {
        this.isDisabled = true;
        let data =  {
          countryCode: this.countryCode,
          conversationRate: this.conversationRate,
          couponCode: this.couponCode,
          discountPercentage: this.discountPercentage,
          currencyCode: this.currencyCode,
          id: this.editCurrencyId
        }
        PriceDataService.updateCurrency(data)
          .then((response) => {
            this.isDisabled = false;
            this.editCurrencyId = "", this.couponCode = ""; this.discountPercentage = ""; this.conversationRate =  ""; this.countryCode = "";
            this.getCurrency();
            this.$toast.success(response.data.respones_msg);
          }).catch((e) => {
            this.isDisabled = false;
            this.$toast.error(e.response.data.respones_msg);
          }); 
      },

      syncCurrencyRate() {
        this.isDisabled = true;
        PriceDataService.syncCurrencyRate()
          .then((response) => {
            this.isDisabled = false;
            this.getCurrency();
            this.$toast.success(response.data.respones_msg);
          }).catch((e) => {
            this.isDisabled = false;
            this.$toast.error(e.response.data.respones_msg);
          }); 
      },
  },
  watch: {
    countryCode() {
      if (!this.countryCode) {
        return;
      }
      PriceDataService.countryLookup({ countryCode: this.countryCode })
        .then((response) => {
          this.currencyCode = response.data.data.currencyCode;
          this.conversationRate = response.data.data.conversationRate;
        }).catch((e) => {
          this.isDisabled = false;
          this.$toast.error(e.response.data.respones_msg);
        }); 
    }
  },
  beforeMount() {
    this.listPrice();
    this.getCurrency();
  },
  mounted() {
  }
};
</script>

<style>
@import '../../../assets/css/common.css';
.select2-container{
  width: 100% !important;
}
</style>
