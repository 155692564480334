<template>
    <div>
        <div style="float: right">
          <md-button v-if="!id" class="md-success" :disabled="isDisabled" @click="createBlog()">Save
            <span v-if="isDisabled"><i class="fa fa-spinner fa-spin"></i></span>
          </md-button>
          <md-button v-else class="md-success" :disabled="isDisabled" @click="updateBlog()">Update
            <span v-if="isDisabled"><i class="fa fa-spinner fa-spin"></i></span>
          </md-button>
        </div>
        <md-card>
            <md-card-content>
                <form ref="createBlogForm">
                    <div class="row">
                        <div class="col">
                            <md-field>
                                <label>Title</label>
                                <md-input v-model="title" type="text"></md-input>
                            </md-field>
                        </div>
                        <div class="col">
                            <md-field>
                                <label>Short Description</label>
                                <md-input v-model="shortDescription" type="text"></md-input>
                            </md-field>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <md-field>
                                <vue-editor style="width: 100%" v-model="longDescription"></vue-editor>
                            </md-field>
                        </div>
                    </div><br><br>
                    <div class="row">
                        <div class="col">
                            <md-field>
                                <label>Category</label>
                                <md-input v-model="category" type="text"></md-input>
                            </md-field>
                        </div>
                        <div class="col">
                            <md-field>
                                <label>Tags</label>
                                <vue-tags-input
                                v-model="tag"
                                :tags="tags"
                                @tags-changed="newTags => tags = newTags"
                                />
                            </md-field>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <md-field>
                                <label>Author</label>
                                <md-input v-model="author" type="text"></md-input>
                            </md-field>
                        </div>
                        <div class="col">
                            <md-field>
                                <input type="file" @change="setImage($event)" />
                            </md-field>
                            <img v-if="id" :src="imageUrl" class="img-thumbnail">
                        </div>
                    </div>
                </form>
            </md-card-content>
        </md-card>
    </div>
</template>
<script>
import { VueEditor } from "vue2-editor";
import VueTagsInput from '@johmun/vue-tags-input';
import BlogService from "../../../services/SuperAdmin/BlogService";

export default {
  components: {
    VueEditor,
    VueTagsInput,
  },
  data() {
    return {
      isDisabled: false,
      title: "",
      shortDescription:"",
      longDescription:"",
      category:"",
      tag: '',
      author:"",
      imageFile: "",
      tags: [],
      id: this.$route.query.id,
      imageUrl: "",
    };
  },
  methods: {
    createBlog() {
        this.isDisabled = true;
        let formData = new FormData();
        formData.append("title", this.title);
        formData.append("shortDescription", this.shortDescription);
        formData.append("longDescription", this.longDescription);
        formData.append("category", this.category);
        formData.append("tags", JSON.stringify(this.tags));
        formData.append("author", this.author);
        formData.append("imageFile", this.imageFile);
        BlogService.create(formData)
        .then((response) => {
            this.isDisabled = false;
            this.$toast.success(response.data.respones_msg);
            this.$router.push("admin-blog-list");
        }).catch((e) => {
            this.isDisabled = false;
            this.$toast.error(e.response.data.respones_msg);
        });
    },
    setImage(event){
      this.imageFile = event.target.files[0];
    },
    blogDetail() {
        BlogService.blogDetail({ id: this.id })
        .then((response) => {
            let blogDetail = response.data.data
            this.title = blogDetail.title;
            this.shortDescription = blogDetail.shortDescription;
            this.longDescription = blogDetail.longDescription;
            this.category = blogDetail.category;
            this.tags = blogDetail.tags;
            this.author = blogDetail.author;
            this.imageUrl = process.env.VUE_APP_API_BASE_URL+"upload/blog/"+blogDetail.image
            this.$toast.success(response.data.respones_msg);
        }).catch((e) => {
            this.$toast.error(e.response.data.respones_msg);
        });
    },
    updateBlog() {
        this.isDisabled = true;
        let formData = new FormData();
        formData.append("id", this.id);
        formData.append("title", this.title);
        formData.append("shortDescription", this.shortDescription);
        formData.append("longDescription", this.longDescription);
        formData.append("category", this.category);
        formData.append("tags", JSON.stringify(this.tags));
        formData.append("author", this.author);
        formData.append("imageFile", this.imageFile);
        BlogService.update(formData)
        .then((response) => {
            this.isDisabled = false;
            this.$toast.success(response.data.respones_msg);
            this.$router.push("admin-blog-list");
        }).catch((e) => {
            this.isDisabled = false;
            this.$toast.error(e.response.data.respones_msg);
        });
    },
  },
  beforeMount() {
    if (this.id) {
        this.blogDetail();
    }
  },
};
</script>
