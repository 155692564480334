<template>
    <div>
        <md-card>
            <md-card-content>
                <div class="md-layout">
                    <div class="md-layout-item md-size-25">
                        <md-field>
                            <md-select v-model="keyName">
                                <md-option value="">Select Key Name</md-option>
                                <md-option value="BACK_KEY">Back Key</md-option>
                                <md-option value="RECENT_KEY">Recent Key</md-option>
                                <md-option value="HOME_KEY">Home Key</md-option>
                                <md-option value="VOLUME_UP_KEY">Volume Up Key</md-option>
                                <md-option value="VOLUME_DOWN_KEY">Volume Down Key</md-option>
                                <md-option value="POWER_KEY">Power Key</md-option>
                            </md-select>
                        </md-field>
                    </div>
                    <div class="md-layout-item md-size-25">
                        <md-field>
                            <md-select v-model="keyEvent">
                                <md-option value="">Select Key Event</md-option>
                                <md-option value="SINGLE_TAP">Single Tap</md-option>
                                <md-option value="DOUBLE_TAP">Double Tap</md-option>
                                <md-option value="LONG_PRESS">Long Press</md-option>
                            </md-select>
                        </md-field>
                    </div>
                    <div class="md-layout-item md-size-25">
                        <md-field>
                            <md-input type="text" placeholder="Package Name" v-model="packageName"></md-input>
                        </md-field>
                    </div>
                    <div class="md-layout-item md-size-25">
                        <md-button class="md-primary" :disabled="isDisabled" @click="addProfileKeyMapper()">Add</md-button> 
                    </div>
                </div>
                <div class="md-layout">
                    <md-table :value="queriedData"
                        class="paginated-table table-striped table-hover"  table-header-color="green">
                        <md-table-toolbar>
                            <md-field>
                                <label for="pages">Per page</label>
                                <md-select v-model="pagination.perPage" name="pages">
                                <md-option
                                    v-for="item in pagination.perPageOptions"
                                    :key="item"
                                    :label="item"
                                    :value="item"
                                >
                                    {{ item }}
                                </md-option>
                                </md-select>
                            </md-field>
                        </md-table-toolbar>
                        <md-table-row slot="md-table-row" slot-scope="{ item }">
                            <md-table-cell md-label="Package Name"><b :style="{ 'word-wrap':'break-word' }">{{ item.packageName }}</b></md-table-cell>
                            <md-table-cell md-label="Key Name"><b>{{ item.keyName }}</b></md-table-cell>
                            <md-table-cell md-label="Key Event"><b>{{ item.keyEvent }}</b></md-table-cell>
                            <md-table-cell md-label="Created On">{{ HumanReadableDateFormat(item.created_at, true) }}</md-table-cell>
                            <md-table-cell md-label="Actions">
                                <md-button class="md-just-icon md-danger md-simple" @click="deleteProfileKeyMapper(item._id)"><md-icon>close</md-icon></md-button>
                            </md-table-cell>
                        </md-table-row>
                    </md-table>
                </div>
            </md-card-content>
            <md-card-actions md-alignment="space-between">
                <div class="">
                        <p class="card-category">
                        Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
                        </p>
                    </div>
                    <pagination
                        class="pagination-no-border pagination-success"
                        v-model="pagination.currentPage"
                        :per-page="pagination.perPage"
                        :total="total"
                    >
                    </pagination>
            </md-card-actions>
        </md-card>
    </div>
</template>
<script>
import ProfileService from "../../services/Customer/ProfileService";
import { Pagination } from "@/components";

export default {
  name: 'KeyMappingTab',
  components: {
    Pagination
  },
  props: {
    profileId: String,
  },
  computed: {
    /***
     * Returns a page from the searched data or the whole data. Search is performed in the watch section below
     */
    queriedData() {
      let result = this.tableData;
      if (this.searchedData.length > 0) {
        result = this.searchedData;
      }
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.tableData.length;
    },
  },
  data() {
    return {
        isDisabled: false,
        keyName:"",
        keyEvent:"",
        packageName:"",
        
        tableData:[],
        //Pagination
        pagination: {
            perPage: 25,
            currentPage: 1,
            perPageOptions: [5, 10, 25, 50],
            total: 0,
        },
        searchedData: [],
    }
  },
  methods: {
    profileDetail() {
        let data = { profileId: this.profileId }
        ProfileService.profileDetail(data)
          .then((response) => {
                this.tableData = response.data.data.keyMapper
          }).catch((e) => {
            this.$toast.error(e.response.data.respones_msg);
          });
    },
    addProfileKeyMapper() {
        this.isDisabled = true;
        let data = { 
          profileId: this.profileId,
          keyName: this.keyName,
          keyEvent: this.keyEvent,
          packageName: this.packageName,
        };
        ProfileService.addProfileKeyMapper(data)
          .then((response) => {
            this.isDisabled = false;
            this.keyName = "", this.keyEvent = "", this.packageName = "";
            this.profileDetail();
            this.$toast.success(response.data.respones_msg);
          }).catch((e) => {
            this.isDisabled = false;
            this.$toast.error(e.response.data.respones_msg);
            if (e.response.data.redirectToPaymentPage == true) {
              this.$router.push("plans");
            }
          });
    },
    deleteProfileKeyMapper(id) {
        let data = { 
          profileId: this.profileId,
          id: id,
        };
        ProfileService.deleteProfileKeyMapper(data)
          .then((response) => {
            this.profileDetail();
            this.$toast.success(response.data.respones_msg);
          }).catch((e) => {
            this.$toast.error(e.response.data.respones_msg);
            if (e.response.data.redirectToPaymentPage == true) {
              this.$router.push("plans");
            }
          });
    },
  },
  beforeMount() {
    this.profileDetail();
  },
}
</script>