<template>
    <div>
        <md-card>
            <md-card-content>
                <div style="float: right">
                    <md-button class="md-success" :disabled="isDisabled" @click="updatePcSetting()">
                    Save<span v-if="isDisabled"><i class="fa fa-spinner fa-spin"></i></span></md-button>
                </div>
                <br><span><b>Profile Counter : {{ this.profileDetail.profileCounter }}</b></span>
                <br><small class="text-danger"><b>Works with Samsung devices only</b></small>
                <div class="row">
                    <div class="col-md-4">
                        <md-switch v-model="clearBrowserCoockies">Clear Browser Coockies</md-switch>
                        <md-switch v-model="clearCache">Clear Cache</md-switch>
                    </div>
                    <div class="col-md-4">
                        <md-switch v-model="clearNotifications">Clear Notifications</md-switch>
                        <md-switch v-model="clearRecentApps">Clear Recent Apps</md-switch>
                    </div>
                    <div class="col-md-4">
                        <md-switch v-model="clearcallLogs">Clear Call Logs</md-switch>
                    </div>
                </div>
            </md-card-content>
        </md-card>
    </div>
</template>
<script>
import ProfileService from "../../services/Customer/ProfileService";

export default {
  name: 'PcSettingTab',
  props: {
    profileId: String,
    profileDetail: Object,
  },
  data() {
    return {
        isDisabled: false,
        clearBrowserCoockies: (this.profileDetail.powerCleanerSettings) ? this.profileDetail.powerCleanerSettings.clearBrowserCoockies : false,
        clearCache:(this.profileDetail.powerCleanerSettings) ? this.profileDetail.powerCleanerSettings.clearCache : false,
        clearNotifications: (this.profileDetail.powerCleanerSettings) ? this.profileDetail.powerCleanerSettings.clearNotifications : false,
        clearRecentApps:(this.profileDetail.powerCleanerSettings) ? this.profileDetail.powerCleanerSettings.clearRecentApps : false,
        clearcallLogs:(this.profileDetail.powerCleanerSettings) ? this.profileDetail.powerCleanerSettings.clearcallLogs : false,
    }
  },
  methods: {
    updatePcSetting() {
        let data = { 
          profileId: this.profileId,
          powerCleanerSettings: {
            clearBrowserCoockies: this.clearBrowserCoockies,
            clearCache: this.clearCache,
            clearNotifications: this.clearNotifications,
            clearRecentApps: this.clearRecentApps,
            clearcallLogs: this.clearcallLogs,
          }
        };
        ProfileService.updatePcSetting(data)
          .then((response) => {
            this.$toast.success(response.data.respones_msg);
          })
          .catch((e) => {
            this.$toast.error(e.response.data.respones_msg);
            if (e.response.data.redirectToPaymentPage == true) {
              this.$router.push("plans");
            }
          });
      },
  },
  beforeMount() {
  },
}
</script>