<template>
    <div>
      <div style="float: right">
        <md-button class="md-primary" :disabled="isDisabled" @click="exportData()">Export</md-button>
      </div>
        <md-card>
            <md-card-content>
                <div class="md-layout">
                    <div class="md-layout-item md-size-100">
                        <md-table v-if="tableData.length > 0 " v-model="queriedData" 
                        :md-sort.sync="currentSort"
                        :md-sort-order.sync="currentSortOrder"
                        :md-sort-fn="customSort"
                        class="md-container-custom paginated-table" table-header-color="green" md-fixed-header>
                            <md-table-toolbar>
                                <md-field>
                                    <label for="pages">Per page</label>
                                    <md-select v-model="pagination.perPage" name="pages">
                                    <md-option
                                        v-for="item in pagination.perPageOptions"
                                        :key="item"
                                        :label="item"
                                        :value="item"
                                    >
                                        {{ item }}
                                    </md-option>
                                    </md-select>
                                </md-field>
                              </md-table-toolbar>
                            <md-table-row slot="md-table-row" slot-scope="{ item }">            
                                <md-table-cell md-label="Email" md-sort-by="email">{{ item.email }}</md-table-cell>
                                <md-table-cell md-label="Created On" md-sort-by="created_at">{{ HumanReadableDateFormat(item.created_at) }}</md-table-cell>
                            </md-table-row>
                        </md-table>
                        <md-card-actions md-alignment="space-between">
                            <div class="">
                                <p class="card-category">
                                Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
                                </p>
                            </div>
                            <pagination
                                class="pagination-no-border pagination-success"
                                v-model="pagination.currentPage"
                                :per-page="pagination.perPage"
                                :total="total"
                            >
                            </pagination>
                        </md-card-actions>
                        <h4 v-if="tableData.length == 0">No data found.</h4>
                    </div>
                </div>
            </md-card-content>
        </md-card>
    </div>
</template>
<script>
import WebsiteService from "../../services/SuperAdmin/WebsiteService";
import { Pagination } from "@/components";

export default {
  components: {
    Pagination
  },
  computed: {
        queriedData() {
          let result = this.tableData;
          if (this.searchedData.length > 0) {
              result = this.searchedData;
          }
          return result.slice(this.from, this.to);
        },
        to() {
          let highBound = this.from + this.pagination.perPage;
          if (this.total < highBound) {
              highBound = this.total;
          }
          return highBound;
        },
        from() {
          return this.pagination.perPage * (this.pagination.currentPage - 1);
        },
        total() {
          return this.searchedData.length > 0
              ? this.searchedData.length
              : this.tableData.length;
        },
  },
  data() {
    return {
      isDisabled: false,
      tableData: [],

      //Sorting
      currentSort: "created_at",
      currentSortOrder: "asc",

      //Pagination
      pagination: {
            perPage: 25,
            currentPage: 1,
            perPageOptions: [5, 10, 25, 50],
            total: 0,
        },
      searchedData: [],
    };
  },
  methods: {
      customSort(value) {
            return value.sort((a, b) => {
            const sortBy = this.currentSort;
            if (this.currentSortOrder === "desc") {
                return a[sortBy].localeCompare(b[sortBy]);
            }
            return b[sortBy].localeCompare(a[sortBy]);
            });
      },
      newsletterList() {
        WebsiteService.newsletterList()
          .then((response) => {
            this.tableData = response.data.data;
            this.$toast.success(response.data.respones_msg);
          })
          .catch((e) => {
            this.$toast.error(e.response.data.respones_msg);
          });
      },
      async exportData() {
      if (this.tableData.length > 0) {
        let exportData = [];
        for await (let data of this.tableData) {
          exportData.push([ data.email, data.created_at]);
        }
        this.DownloadSampleImportFile(exportData);
      } else {
        this.$toast.error("No data to export");
      }
    },
    DownloadSampleImportFile(exportData = "") {
      let csv = 'Email,Created On\n';
      if (exportData) {
        exportData.forEach((row) => {
            csv += row.join(',');
            csv += "\n";
        });
      }
      const anchor = document.createElement('a');
      anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv);
      anchor.target = '_blank';
      if (exportData) {
        anchor.download = 'Newsletters.csv';
      }
      anchor.click();
    },
  },
  beforeMount() {
    this.newsletterList();
  },
};
</script>