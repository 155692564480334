<template>
    <div>
        <div style="float: right">
          <md-button class="md-success" @click="$router.push({ path: 'admin-customer-create'})">Create Customer</md-button>
          <md-button class="md-primary" :disabled="isDisabled" @click="exportData()">Export</md-button>
        </div>
        <md-card>
            <md-card-content>
                <form ref="createVideoForm">
                    <div class="row">
                        <div class="col">
                            <md-field>
                                <md-datepicker v-model="from_date" md-immediately>
                                    <label>From</label>
                                </md-datepicker>
                            </md-field>
                        </div>
                        <div class="col">
                            <md-field>
                                <md-datepicker v-model="to_date" md-immediately>
                                    <label>To</label>
                                </md-datepicker>
                            </md-field>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <md-field>
                                <label>Email</label>
                                <md-input v-model="email" type="text"></md-input>
                            </md-field>
                        </div>
                        <div class="col">
                            <md-field>
                                <label>Soruce</label>
                                <md-select v-model="customerSource">
                                    <md-option value="">All</md-option>
                                    <md-option value="Offline">Offline</md-option>
                                    <md-option value="Online">Online</md-option>
                                </md-select>
                            </md-field>
                        </div>
                        <div class="col">
                            <md-field>
                                <label>Purchased Devices</label>
                                <md-input v-model="purchasedDevices" type="number"></md-input>
                            </md-field>
                        </div>
                    </div>
                    <div class="row">
                      <div class="col">
                          <md-field>
                              <label>Products</label>
                              <md-select v-model="product">
                                  <md-option value="">All</md-option>
                                  <md-option value="PD">Package Disabler</md-option>
                                  <md-option value="PC">Power Cleaner</md-option>
                                  <md-option value="UB">Url Blocker</md-option>
                                  <md-option value="KM">Key Mapper</md-option>
                                  <md-option value="POB">Power On Boot</md-option>
                              </md-select>
                          </md-field>
                      </div>
                      <div class="col">
                            <md-field>
                                <label>Country code</label>
                                <md-input v-model="countryCode" type="text"></md-input>
                            </md-field>
                        </div>
                        <div class="col">
                            <md-switch v-model="showUnusedLicense">Show Unused License</md-switch>
                        </div>
                        <div class="col">
                            <md-button class="md-success" :disabled="isDisabled" @click="customerList()">Search<span v-if="isDisabled"><i class="fa fa-spinner fa-spin"></i></span></md-button>
                        </div>
                    </div>
                </form>
            </md-card-content>
        </md-card>
        <md-card>
            <md-card-content>
                <div class="md-layout">
                    <div class="md-layout-item md-size-100">
                        <md-table :value="queriedData" class="paginated-table table-striped table-hover" 
                            table-header-color="green" md-fixed-header>
                            <md-table-toolbar>
                                <md-field>
                                    <label for="pages">Per page</label>
                                    <md-select v-model="pagination.perPage" name="pages">
                                    <md-option
                                        v-for="item in pagination.perPageOptions"
                                        :key="item"
                                        :label="item"
                                        :value="item"
                                    >
                                        {{ item }}
                                    </md-option>
                                    </md-select>
                                </md-field>
                            </md-table-toolbar>
                            <md-table-row slot="md-table-row" slot-scope="{ item }">            
                                <md-table-cell md-label="Email">
                                    <a href="javascript:void(0)" @click="ByPassUserLogin(item.email)">{{ item.email }}</a>
                                    <br><span class="badge-primary" v-if="item.isOfflineCustomer">Offline</span>
                                </md-table-cell>
                                <md-table-cell md-label="Phone Number">{{ item.formattedPhoneNumber }} </md-table-cell>
                                <md-table-cell md-label="Purchased License">{{ item.totalPurchasedDevices }} </md-table-cell>
                                <md-table-cell md-label="Used License">{{ item.totalUsedDevices }} </md-table-cell>
                                <md-table-cell md-label="Created On">{{ HumanReadableDateFormat(item.created_at) }}</md-table-cell>
                                <md-table-cell md-label="Actions">
                                    <div>                    
                                      <md-button class="md-just-icon md-primary md-simple" @click="$router.push({ path: 'admin-customer-subscription-create', query: { id: item._id }})"><md-icon>euro</md-icon></md-button>&nbsp;
                                      <md-button class="md-just-icon md-success md-simple" @click="$router.push({ path: 'admin-customer-edit', query: { id: item._id }})"><md-icon>edit</md-icon></md-button>
                                    </div>
                                </md-table-cell>
                            </md-table-row>
                        </md-table>
                    </div>
                </div>
            </md-card-content>
            <md-card-actions md-alignment="space-between">
                <div class="">
                    <p class="card-category">
                    Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
                    </p>
                </div>
                <pagination
                    class="pagination-no-border pagination-success"
                    v-model="pagination.currentPage"
                    :per-page="pagination.perPage"
                    :total="total"
                >
                </pagination>
            </md-card-actions>
        </md-card>
        <md-card v-if="oldPortalUsers.length > 0">
            <md-card-content>
                <div class="md-layout">
                    <div class="md-layout-item md-size-100">
                        <md-table v-model="oldPortalUsers" class="paginated-table table-striped table-hover" 
                            table-header-color="green" md-fixed-header>
                            <md-table-row slot="md-table-row" slot-scope="{ item }">            
                                <md-table-cell md-label="Email">{{ item.email }}</md-table-cell>
                                <md-table-cell md-label="Name">{{ item.name }} </md-table-cell>
                                <md-table-cell md-label="Expiry Date">{{ item.expiration_date }} </md-table-cell>
                                <md-table-cell md-label="Serial Number">{{ item.device_serial_number }} </md-table-cell>
                            </md-table-row>
                        </md-table>
                    </div>
                </div>
            </md-card-content>
        </md-card>
    </div>
</template>
<script>
import CustomerService from "../../../services/SuperAdmin/CustomerService";
import { Pagination } from "@/components";

export default {
  components: {
    Pagination,
  },
  data() {
    return {
      isDisabled: false,
      tableData: [],
      email: "",
      from_date: new Date().toISOString().split('T')[0],
      to_date: new Date().toISOString().split('T')[0],
      role: localStorage.getItem('adminRole'),
      customerSource: "",
      phoneNumber:"",
      purchasedDevices:"",
      countryCode:"",
      product:"",
      showUnusedLicense: false,

      //Pagination
      pagination: {
        perPage: 25,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0,
      },
      searchedData: [],
      oldPortalUsers: [],
    };
  },
  computed: {
    /***
     * Returns a page from the searched data or the whole data. Search is performed in the watch section below
     */
    queriedData() {
      let result = this.tableData;
      if (this.searchedData.length > 0) {
        result = this.searchedData;
      }
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.tableData.length;
    },
  },
  methods: {
      customerList() {
        this.isDisabled = true;
        let data; 
        if (this.role == 'SuperAdmin') {
          data = { email: this.email, from_date: this.from_date, to_date: this.to_date, customerSource: this.customerSource,
          purchasedDevices: this.purchasedDevices, countryCode: this.countryCode, product: this.product, showUnusedLicense: this.showUnusedLicense }
        } else {
          //data = { email: this.email, from_date: null, to_date: null }
          data = { email: this.email, from_date: this.from_date, to_date: this.to_date, customerSource: this.customerSource,
          purchasedDevices: this.purchasedDevices, countryCode: this.countryCode, product: this.product, showUnusedLicense: this.showUnusedLicense }
        }
        
        CustomerService.list(data)
          .then((response) => {
            this.isDisabled = false;
            this.tableData = response.data.data;
            this.oldPortalUsers = response.data.oldPortalUsers.data;
            this.$toast.success(response.data.respones_msg);
          })
          .catch((e) => {
            this.isDisabled = false;
            this.$toast.error(e.response.data.respones_msg);
          });
      },
      ByPassUserLogin(email) {
        let data = {
            email: email,
        }
        CustomerService.ByPassUserLogin(data)
        .then((response) => {
            localStorage.setItem('token', response.data.data.token);
            let routeData = this.$router.resolve({name: 'dashboard'});
            window.open(routeData.href, '_blank');
        })
        .catch((e) => {
          this.$toast.error(e.response.data.respones_msg);
        });
    },
    async exportData() {
      if (this.tableData.length > 0) {
        let exportData = [];
        for await (let data of this.tableData) {
          exportData.push([data.email, data.formattedPhoneNumber, data.totalPurchasedDevices, data.totalUsedDevices, data.created_at]);
        }
        let header = 'Email,Phone Number,Purchased License,Used License,CreatedOn\n'
        this.exportToCSV(exportData, "Customers",header);
      } else {
        this.$toast.error("No data to export");
      }
    },
    exportToCSV(exportData = "", fileName, columnsName) {
        let csv = columnsName; //'Email,Created On\n';
        if (exportData) {
          exportData.forEach((row) => {
              csv += row.join(',');
              csv += "\n";
          });
        }
        const anchor = document.createElement('a');
        anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv);
        anchor.target = '_blank';
        if (exportData) {
          anchor.download = fileName+'.csv';
        }
        anchor.click();
    },
  },
  beforeMount() {
    let d = new Date();
    d.setMonth(d.getMonth()-1);
    this.from_date = d.toISOString().split('T')[0];
    this.customerList();
  },
};
</script>
