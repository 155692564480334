<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-100 md-medium-size-100">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <md-icon>security</md-icon>
          </div>
          <h4 class="title">Add Payment Methods</h4>
        </md-card-header>

        <md-card-content>
            <div class="row">
                <div class="col-md-6">      
                    <div ref="card"></div>
                </div>
                <div class="col-md-6">      
                    <md-button class="md-success" type="submit" :disabled="isDisabled" @click="addPaymentMethod()">Add <span v-if="isDisabled"><i class="fa fa-spinner fa-spin"></i></span></md-button>
                </div>
            </div>
        </md-card-content>
      </md-card>
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <md-icon>security</md-icon>
          </div>
          <h4 class="title">List Payment Methods</h4>
        </md-card-header>

        <md-card-content>
            <div class="row">
                <div class="col-sm-3" v-for="pm in paymentMethods" :key="pm.id">
                    <div class="card">
                        <div class="card-body">
                            <h5 class="card-title">
                                <b>{{ pm.type }}</b> <span v-if="pm.id == defaultSource" class="badge badge-secondary">Default</span>
                            </h5>
                            <ul v-if="pm.type != 'paypal'">
                                <li><b>Brand:</b> {{ pm.card.brand.toUpperCase() }}</li>
                                <li><b>Country:</b> {{ pm.card.country }}</li>
                                <li><b>Exp Month:</b> {{ pm.card.exp_month }}</li>
                                <li><b>Exp Year:</b> {{ pm.card.exp_year }}</li>
                                <li><b>Last 4 Digit:</b> {{ pm.card.last4 }}</li>
                            </ul>
                            <ul v-else>
                                <li><b>Country:</b> {{ pm.paypal.country }}</li>
                                <li><b>Payer Email:</b> {{ pm.paypal.payer_email }}</li>
                                <li><b>Payer ID:</b> {{ pm.paypal.payer_id }}</li>
                            </ul>
                            <md-button v-if="pm.id != defaultSource" class="md-primary" :disabled="isDisabled"  @click="setAsDefaultPaymentMethod(pm.id)">Set As Default </md-button> 
                        </div>
                    </div>
                </div>
            </div>
        </md-card-content>
      </md-card>  
    </div>
  </div>
</template>
<script>
import UsersDataService from "../../services/UsersDataService";
const appearance = {
  theme: "night",
};
let stripe = Stripe(process.env.VUE_APP_STRIPE_PK),
  elements = stripe.elements(appearance),
  card = undefined;

export default {
  data() {
    return {
      isDisabled: false,
      paymentMethods: [],
      defaultSource: null,
    }
  },
  methods: {
    setAsDefaultPaymentMethod(pmId) {
      this.isDisabled = true;
      let data = {
        pmId: pmId,
      };

      UsersDataService.setAsDefaultPaymentMethod(data)
        .then((response) => {
          this.isDisabled = false;
          this.$toast.success(response.data.respones_msg);
          this.getPaymentMethods();
        }).catch((e) => {
          this.isDisabled = false;
          this.$toast.error(e.response.data.respones_msg);
        });
    },
    getPaymentMethods() {
      UsersDataService.getPaymentMethods()
        .then((response) => {
          this.paymentMethods = response.data.data.data;
          this.defaultSource = response.data.defaultSource;
        })
        .catch((e) => {
          this.$toast.error(e.response.data.respones_msg);
        });
    },
    addPaymentMethod() {
        let context = this;
        context.isDisabled = true;
        stripe.createPaymentMethod({
                type: "card",
                card: card,
        }).then(function (result) {
                let data = {
                    pmId: result.paymentMethod.id,
                };
                UsersDataService.addPaymentMethod(data)
                .then((response) => {
                    context.$toast.success(response.data.respones_msg);
                    card.clear();
                    context.isDisabled = false;
                    context.getPaymentMethods();
                }).catch((e) => {
                    context.$toast.error(result.error.message);
                    context.isDisabled = false;
                })
        }).catch((e) => {
            context.$toast.error(result.error.message);
        })
    }
  },
  mounted: function () {
    if (!card) {
        card = elements.create("card");
        card.mount(this.$refs.card);
    } else {
        card.mount(this.$refs.card);
    }
  },
  beforeMount(){
    this.getPaymentMethods();
  },
};
</script>
<style scoped>
/*streip css*/
.StripeElement {
  box-sizing: border-box;
  height: 40px;
  padding: 10px 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: white;
  box-shadow: 0 1px 3px 0 #e6ebf1;
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
}

.StripeElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df;
}

.StripeElement--invalid {
  border-color: #fa755a;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}

</style>