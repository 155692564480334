<template>
    <div>
        <md-card>
            <md-card-content>
                <form ref="createVideoForm">
                    <div class="row">
                        <div class="col">
                            <md-field>
                                <label>Title</label>
                                <md-input v-model="title" type="text"></md-input>
                            </md-field>
                        </div>
                        <div class="col">
                            <md-field>
                                <label>Link</label>
                                <md-input v-model="link" type="text"></md-input>
                            </md-field>
                        </div>
                        <div class="col">
                            <md-button class="md-success" :disabled="isDisabled" @click="addVideo()">Add</md-button>
                        </div>
                    </div>
                </form>
            </md-card-content>
        </md-card>
        <md-card>
            <md-card-content>
                <div class="md-layout">
                    <div class="md-layout-item md-size-100">
                        <md-table v-if="tableData.length > 0 " v-model="tableData" table-header-color="green">
                            <md-table-row slot="md-table-row" slot-scope="{ item }">            
                                <md-table-cell md-label="Title"> <b>{{ item.title }} </b></md-table-cell>
                                <md-table-cell md-label="Link">{{ item.link }}</md-table-cell>
                                <md-table-cell md-label="Created On">{{ HumanReadableDateFormat(item.created_at) }}</md-table-cell>
                                <md-table-cell md-label="Actions">
                                    <md-button class="md-just-icon md-danger md-simple" @click="deleteVideo(item._id)"><md-icon>delete</md-icon></md-button>
                                </md-table-cell>
                            </md-table-row>
                        </md-table>
                        <h4 v-else>No data found.</h4>
                    </div>
                </div>
            </md-card-content>
        </md-card>
    </div>
</template>
<script>
import VideoDataService from "../../../services/SuperAdmin/VideoDataService";

export default {
  components: {
  },
  data() {
    return {
      isDisabled: false,
      tableData: [],
      
      title: "",
      link: "",
    };
  },
  methods: {
      videoList() {
        VideoDataService.videoList()
          .then((response) => {
            this.tableData = response.data.data;
            this.$toast.success(response.data.respones_msg);
          })
          .catch((e) => {
            this.$toast.error(e.response.data.respones_msg);
          });
      },
      addVideo() {
        this.isDisabled = true;
        let data =  {
          title: this.title,
          link: this.link,
        }
        VideoDataService.addVideo(data)
          .then((response) => {
            this.isDisabled = false;
            this.$toast.success(response.data.respones_msg);
            this.$refs.createVideoForm.reset();
            this.videoList();
          })
          .catch((e) => {
            this.isDisabled = false;
            this.$toast.error(e.response.data.respones_msg);
          }); 
      },
      deleteVideo(id) {
        let data =  {
          id: id
        }
        VideoDataService.deleteVideo(data)
          .then((response) => {
            this.$toast.success(response.data.respones_msg);
            this.videoList();
          })
          .catch((e) => {
            this.$toast.error(e.response.data.respones_msg);
          }); 
      }
  },
  beforeMount() {
    this.videoList();
  },
};
</script>