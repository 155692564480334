<template>
    <div>
      <div style="float: right">
          <md-button class="md-primary" :disabled="isDisabled" @click="exportData()">Export</md-button>
        </div>
        <md-card>
            <md-card-content>
                <form ref="createVideoForm">
                    <div class="row">
                        <div class="col">
                            <md-field>
                                <md-datepicker v-model="from_date" md-immediately>
                                    <label>From</label>
                                </md-datepicker>
                            </md-field>
                        </div>
                        <div class="col">
                            <md-field>
                                <md-datepicker v-model="to_date" md-immediately>
                                    <label>To</label>
                                </md-datepicker>
                            </md-field>
                        </div>
                        <div class="col" :style="{ 'padding': '20px' }">
                            <md-field>
                                <label>Type</label>
                                <md-select v-model="subscriptionType">
                                    <md-option value="">All</md-option>
                                    <md-option value="recurring">Subscription</md-option>
                                    <md-option value="one_time">Onetime</md-option>
                                </md-select>
                            </md-field>
                        </div>
                        <div class="col" :style="{ 'padding': '20px' }">
                            <md-field>
                                <label>Payment Method</label>
                                <md-select v-model="paymentMethodType">
                                    <md-option value="">All</md-option>
                                    <md-option value="card">Card</md-option>
                                    <md-option value="paypal">Paypal</md-option>
                                    <md-option value="link">Link</md-option>
                                    <md-option value="google_pay">Google Pay</md-option>
                                    <md-option value="apple_pay">Apple Pay</md-option>
                                    <md-option value="wechat_pay">WeChat Pay</md-option>
                                    <md-option value="alipay">Ali Pay</md-option>
                                </md-select>
                            </md-field>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <md-field>
                                <label>Email</label>
                                <md-input v-model="email" type="text"></md-input>
                            </md-field>
                        </div>
                        <div class="col">
                            <md-field>
                                <label>Status</label>
                                <md-select v-model="status">
                                    <md-option value="">All</md-option>
                                    <md-option value="Success">Success</md-option>
                                    <md-option value="Failed">Failed</md-option>
                                </md-select>
                            </md-field>
                        </div>
                        <div class="col">
                            <md-field>
                                <label>Product Name</label>
                                <md-select v-model="priceId">
                                    <md-option value="">All</md-option>
                                    <md-option v-for="price in pricings" :key="price._id" :value="price._id">{{ price.name }}</md-option>
                                </md-select>
                            </md-field>
                        </div>
                        <div class="col">
                            <md-switch v-model="zeroSuccessPayment">0 Success Payments</md-switch>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <md-field>
                                <label>Coupon</label>
                                <md-input v-model="coupon" type="text"></md-input>
                            </md-field>
                        </div>
                        <div class="col">
                            <md-button class="md-success" :disabled="isDisabled" @click="paymentHistory()">Search<span v-if="isDisabled"><i class="fa fa-spinner fa-spin"></i></span></md-button>
                        </div>
                    </div>
                </form>
            </md-card-content>
        </md-card>
        <md-card>
            <md-card-content>
                <div class="md-layout">
                    <div class="md-layout-item md-size-100">
                        <md-table :value="queriedData" class="paginated-table table-striped table-hover" 
                            table-header-color="green" md-fixed-header>
                            <md-table-toolbar>
                                <md-field>
                                    <label for="pages">Per page</label>
                                    <md-select v-model="pagination.perPage" name="pages">
                                    <md-option
                                        v-for="item in pagination.perPageOptions"
                                        :key="item"
                                        :label="item"
                                        :value="item"
                                    >
                                        {{ item }}
                                    </md-option>
                                    </md-select>
                                </md-field>
                            </md-table-toolbar>
                            <md-table-row slot="md-table-row" slot-scope="{ item }">            
                                <md-table-cell md-label="Name">
                                    {{ item.productName }} <br>
                                    <small>
                                        <a v-if="item.status == 'Failed'" href="javascript:void(0)" @click="sendFailedEmailModelOpen(item.email)">
                                            {{ item.email }} <span v-if="item.failedEmailFollowupCount"> - {{ item.failedEmailFollowupCount }}</span>
                                        </a>
                                        <b v-else>{{ item.email }}</b>                                        
                                    </small>
                                    <a href="javascript:void(0);" :style="{ color: 'green', 'padding-left': '12px' }" class="md-just-icon md-success md-simple" @click="copyContent(item.email)"><i class="fa fa-clone" aria-hidden="true"></i></a>
                                </md-table-cell>
                                <md-table-cell md-label="Type">
                                    <p class="badge-primary" v-if="item.subscriptionType == 'recurring'">Recurring</p>
                                    <p class="badge-warning" v-else>Onetime</p>
                                    <small v-if="item.subscriptionReason"> <b>{{ item.subscriptionReason }}</b></small>
                                </md-table-cell>
                                <md-table-cell md-label="Qty">
                                  <b> {{ item.qty }} License </b> 
                                  <span v-if="item.paymentMethod && item.paymentMethod.type == 'paypal'" class="text-primary"><br><b>Payment Method: 
                                    <i class="fab fa-paypal"></i> 
                                  </b>
                                  </span>
                                  <span v-else-if="item.paymentMethod && item.paymentMethod.type == 'wechat_pay'" class="text-primary"><br><b>Payment Method: 
                                    WeChatPay 
                                  </b>
                                  </span>
                                  <span v-else-if="item.paymentMethod && item.paymentMethod.type == 'alipay'" class="text-primary"><br><b>Payment Method: 
                                    Alipay
                                  </b>
                                  </span>
                                  <span v-else-if="item.paymentMethod && item.paymentMethod.card" class="text-primary"><br><b>Payment Method: 
                                    <i class="far fa-credit-card fa-lg" v-if="item.paymentMethod.card.wallet && item.paymentMethod.card.wallet.type == null"></i> 
                                    <i class="fab fa-google-pay fa-lg" v-else-if="item.paymentMethod.card.wallet && item.paymentMethod.card.wallet.type == 'google_pay'"></i>
                                    <i class="fab fa-cc-apple-pay fa-lg" v-else-if="item.paymentMethod.card.wallet && item.paymentMethod.card.wallet.type == 'apple_pay'"></i>
                                    <label v-else-if="item.paymentMethod.card.wallet && item.paymentMethod.card.wallet.type == 'link'">link</label>
                                    <label v-else>{{ item.paymentMethod.type }}</label>
                                  </b>
                                  </span>
                                  <span v-else class="text-primary"><br><b>Payment Method: 
                                    <i class="far fa-credit-card fa-lg"></i>
                                  </b>
                                  </span>
                                </md-table-cell>
                                <md-table-cell md-label="Amount">
                                  <b>€{{ item.totalAmount }}</b> 
                                  <span v-if="item.coupon" class="text-info">
                                      <!--<b v-if="item.discountAmount > 0"><br>Discount : €{{ item.discountAmount }} </b>-->
                                      <b><br>CouponCode : {{ item.coupon }} </b>
                                  </span>
                                </md-table-cell>
                                <md-table-cell md-label="Status">
                                    <p class="badge-success" v-if="item.status == 'Success'">{{ item.status }}</p>
                                    <p class="badge-danger" v-else-if="item.status == 'Failed'">
                                        {{ item.status }}
                                    </p>
                                    <p class="badge-primary" v-else>{{ item.status }}</p>
                                    <small v-if="item.message">{{ item.message }}</small>
                                    <small v-if="!item.message && item.status == 'Failed' && item.paymentIntent && item.paymentIntent.last_payment_error">{{ item.paymentIntent.last_payment_error.code }}</small>
                                    <label v-if="item.userId.phone_number && item.status == 'Failed'"><br> &nbsp;
                                      <a :href="'https://wa.me/'+item.userId.phone_number+'?text=I noticed there was an issue with your payment. How may I assist you?'" @click="updateWhatsappCount(item.userId._id)" target="_blank"><i class="fab fa-whatsapp"></i>&nbsp;{{ item.userId.phone_number  }}</a>
                                      - <span v-if="item.userId.whatsappRedirectCount">{{ item.userId.whatsappRedirectCount }}</span> <span v-else><b>0</b></span>
                                    </label>
                                </md-table-cell>
                                <md-table-cell md-label="Expiry At">
                                    <span v-if="item.expiryAt">{{ HumanReadableDateFormat(item.expiryAt, true) }}</span>
                                    <span v-else>-</span>
                                </md-table-cell>
                                <md-table-cell md-label="Created On">{{ HumanReadableDateFormat(item.created_at) }}</md-table-cell>
                            </md-table-row>
                        </md-table>
                    </div>
                </div>
            </md-card-content>
            <md-card-actions md-alignment="space-between">
                <div class="">
                    <p class="card-category">
                    Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
                    </p>
                </div>
                <pagination
                    class="pagination-no-border pagination-success"
                    v-model="pagination.currentPage"
                    :per-page="pagination.perPage"
                    :total="total"
                >
                </pagination>
            </md-card-actions>
        </md-card>
        <template>
          <modal v-if="sendFailedEmailModal" @close="sendFailedEmailModal = false">
            <template slot="header">
              <h4 class="modal-title">Send Email</h4>
              <md-button
                style="height: 25px" class="md-simple md-just-icon md-round modal-default-button"
                @click="sendFailedEmailModal = false"><md-icon>clear</md-icon></md-button>
            </template>
            <template slot="body">
            <div class="box-body">
              <div class="md-layout">
                <div class="md-layout-item md-size-100">
                  <md-field>
                    <md-textarea v-model="failPaymentEmailContent"></md-textarea>
                  </md-field>
                </div>
              </div>
            </div>
            </template>
            <template slot="footer">
              <md-button class="md-danger md-simple" @click="sendFailedEmailModal = false">Close</md-button>
              <md-button class="md-success mt-1" :disabled="isDisabled" @click="sendFailedPaymentEmail()">Send</md-button>
            </template>
          </modal>
      </template>
    </div>
</template>
<script>
import PaymentService from "../../../services/SuperAdmin/PaymentService";
import CustomerService from "../../../services/SuperAdmin/CustomerService";
import { Pagination } from "@/components";
import { Modal } from "@/components";

export default {
  components: {
    Pagination,
    Modal
  },
  computed: {
    /***
     * Returns a page from the searched data or the whole data. Search is performed in the watch section below
     */
    queriedData() {
      let result = this.tableData;
      if (this.searchedData.length > 0) {
        result = this.searchedData;
      }
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.tableData.length;
    },
  },
  data() {
    return {
      isDisabled: false,
      tableData: [],
      email: "",
      status:"",
      from_date: new Date().toISOString().split('T')[0],
      to_date: new Date().toISOString().split('T')[0],
      zeroSuccessPayment: false,
      subscriptionType: "",
      pricings:[],
      priceId:"",
      coupon: (this.$route.query.coupon) ? this.$route.query.coupon : "",
      
      //Pagination
      pagination: {
        perPage: 25,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0,
      },
      searchedData: [],

      sendFailedEmailModal: false,
      failedPaymentEmail: "",
      failPaymentEmailContent:"Dear User,<br>We regret to inform you that your purchase of the app was unsuccessful. We apologize for any inconvenience this may have caused you.\n\nIf you need any assistance to resolve this issue, please do not hesitate to contact us.<br><br>You can also make payment via paypal to packagedisabler@gmail.com and email us.<br><br>Sincerely,<br>Support team",
      paymentMethodType: "",
    };
  },
  methods: {
      paymentHistory() {
        this.isDisabled = true;
        let data = { email: this.email, status: this.status, from_date: this.from_date, to_date: this.to_date,
        zeroSuccessPayment: this.zeroSuccessPayment, subscriptionType: this.subscriptionType, priceId: this.priceId, paymentMethodType: this.paymentMethodType,
        coupon: this.coupon }
        PaymentService.paymentHistory(data)
          .then((response) => {
            this.tableData = response.data.data;
            this.pricings = response.data.pricing;
            this.isDisabled = false;
            this.$toast.success(response.data.respones_msg);
          })
          .catch((e) => {
            this.isDisabled = false;
            this.$toast.error(e.response.data.respones_msg);
          });
      },
      sendFailedEmailModelOpen(email) {
        this.failedPaymentEmail = email;
        this.sendFailedEmailModal = true;
      },
      sendFailedPaymentEmail() {
        this.isDisabled = true;
        let data = { email: this.failedPaymentEmail, content: this.failPaymentEmailContent };
        PaymentService.sendFailedPaymentEmail(data)
          .then((response) => {
            this.isDisabled = false;
            this.sendFailedEmailModal = false;
            this.paymentHistory();
            this.$toast.success(response.data.respones_msg);
          })
          .catch((e) => {
            this.isDisabled = false;
            this.$toast.error(e.response.data.respones_msg);
          });
      },
      updateWhatsappCount(id) {
          let data = { 
              id: id,
          }
          CustomerService.updateWhatsappCount(data)
            .then((response) => {
                this.$toast.success(response.data.respones_msg);
                this.paymentHistory();
            })
            .catch((e) => {
            this.$toast.error(e.response.data.respones_msg);
            });
      },
      async exportData() {
        if (this.tableData.length > 0) {
          let exportData = [];
          for await (let data of this.tableData) {
            exportData.push([ data.email, data.productName.replaceAll(",", "-"), data.subscriptionType, data.qty, data.totalAmount,data.status, data.created_at]);
          }
          this.DownloadSampleImportFile(exportData);
        } else {
          this.$toast.error("No data to export");
        }
      },
      DownloadSampleImportFile(exportData = "") {
        let csv = 'Email,Product,Type,Qty,Amount,Status,Created On\n';
        if (exportData) {
          exportData.forEach((row) => {
              csv += row.join(',');
              csv += "\n";
          });
        }
        const anchor = document.createElement('a');
        anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv);
        anchor.target = '_blank';
        if (exportData) {
          anchor.download = 'payment-history.csv';
        }
        anchor.click();
      },
  },
  beforeMount() {
    this.paymentHistory();
  },
};
</script>
