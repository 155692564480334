<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-100">
      <md-card>
        <md-card-actions class="text-center">
        </md-card-actions>
        <md-card-content>
          <div class="md-layout">
              <div class="md-layout-item md-size-33">
                <md-field>
                    <md-select class="box-textbox" placeholder="Search By Type" v-model="search_by_type">
                      <md-option value="">Search By Type</md-option>
                      <md-option value="User Account">User Account</md-option>
                      <md-option value="Profile">Profile</md-option>
                      <md-option value="Payments">Payments</md-option>
                      <md-option value="Device">Device</md-option>
                  </md-select>
                </md-field>
              </div>
              <div class="md-layout-item md-size-33">
                <md-field>
                    <md-select class="box-textbox" placeholder="Search By Type" v-model="search_by_sub_type">
                      <md-option value="">Search By Sub Type</md-option>
                      <md-option v-if="['','Profile', 'Device'].indexOf(search_by_type) !== -1" value="Update">Update</md-option>
                      <md-option v-if="['','Profile'].indexOf(search_by_type) !== -1" value="Apply">Apply</md-option>
                      <md-option v-if="['','Profile', 'Device'].indexOf(search_by_type) !== -1" value="Application Update">Application Update</md-option>
                      <md-option v-if="['','Profile'].indexOf(search_by_type) !== -1" value="Application Sync">Application Sync</md-option>

                      <md-option v-if="['','Payments'].indexOf(search_by_type) !== -1" value="Subscription Created">Subscription Created</md-option>
                      <md-option v-if="['','Payments'].indexOf(search_by_type) !== -1" value="Subscription Update">Subscription Update</md-option>
                      <md-option v-if="['','Payments'].indexOf(search_by_type) !== -1" value="Onetime Purchased">Onetime Purchased</md-option>
                      <md-option v-if="['','Payments'].indexOf(search_by_type) !== -1" value="Subscription Failed">Subscription Failed</md-option>
                      <md-option v-if="['','Payments'].indexOf(search_by_type) !== -1" value="Onetime Purchased Failed">Onetime Purchased Failed</md-option>

                      <md-option v-if="['','User Account'].indexOf(search_by_type) !== -1" value="Login">Login</md-option>
                      <md-option v-if="['','User Account'].indexOf(search_by_type) !== -1" value="Logout">Logout</md-option>
                      <md-option v-if="['','User Account'].indexOf(search_by_type) !== -1" value="Change Password">Change Password</md-option>
                      <md-option v-if="['','User Account'].indexOf(search_by_type) !== -1" value="Two Step Setting Changed">Two Step Setting Changed</md-option>
                      <md-option v-if="['','User Account'].indexOf(search_by_type) !== -1" value="Update Profile">Update Profile</md-option>

                      <md-option v-if="['','Device'].indexOf(search_by_type) !== -1" value="Registration">Registration</md-option>
                      <md-option v-if="['','Device'].indexOf(search_by_type) !== -1" value="Application Status Update">Application Status Update</md-option>
                  </md-select>
                </md-field>
              </div>
          </div>
          <div class="md-layout">
              <div class="md-layout-item md-size-33">
                <md-field>
                    <md-select class="box-textbox" placeholder="Search By Source" v-model="searchBySource">
                      <md-option value="">Search By Source</md-option>
                      <md-option value="Web">Web</md-option>
                      <md-option value="App">App</md-option>
                  </md-select>
                </md-field>
              </div>
              <div class="md-layout-item md-size-33">
                <md-field>
                    <md-select class="box-textbox" placeholder="Search By Status" v-model="searchByStatus">
                      <md-option value="">Search By Status</md-option>
                      <md-option value="false">Success</md-option>
                      <md-option value="true">Error</md-option>
                  </md-select>
                </md-field>
              </div>
              
              <div class="md-layout-item md-size-25">
                <md-button class="md-primary" :disabled="isDisabled" style="margin-top: 10px;margin-left: -20px;" @click="GetAuditList()">Search</md-button> 
              </div>
          </div>
          <hr>
          <md-table
            :value="queriedData"
            class="paginated-table table-striped table-hover" 
            table-header-color="green"
            md-fixed-header
          >
          <md-table-toolbar>
              <md-field>
                <label for="pages">Per page</label>
                <md-select v-model="pagination.perPage" name="pages">
                  <md-option
                    v-for="item in pagination.perPageOptions"
                    :key="item"
                    :label="item"
                    :value="item"
                  >
                    {{ item }}
                  </md-option>
                </md-select>
              </md-field>
            </md-table-toolbar>
            <md-table-row slot="md-table-row" slot-scope="{ item }">
              <md-table-cell md-label="Type"><b>{{ item.type }}</b></md-table-cell>
              <md-table-cell md-label="Sub Type"><b>{{ item.sub_type }}</b></md-table-cell>
              <md-table-cell md-label="Message">{{ item.message }}</md-table-cell>
              <md-table-cell md-label="Source">
                <p class="badge-primary" v-if="item.source == 'Web'">{{ item.source }}</p>
                <p class="badge-warning" v-else>{{ item.source }}</p>
              </md-table-cell>
              <md-table-cell md-label="Status">
                <p class="badge-success" v-if="item.hasError == false">Success</p>
                <p class="badge-danger" v-else>Error</p>
              </md-table-cell>
              <md-table-cell md-label="Created On">{{ HumanReadableDateFormat(item.created_at, true) }}</md-table-cell>
            </md-table-row>
          </md-table>
        </md-card-content>
        <md-card-actions md-alignment="space-between">
          <div class="">
            <p class="card-category">
              Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
            </p>
          </div>
          <pagination
            class="pagination-no-border pagination-success"
            v-model="pagination.currentPage"
            :per-page="pagination.perPage"
            :total="total"
          >
          </pagination>
        </md-card-actions>
      </md-card>
    </div>
  </div>
</template>
<script>
import UserDataService from "../../services/UsersDataService";
import { Pagination } from "@/components";

export default {
  components: {
    Pagination
  },
  computed: {
    /***
     * Returns a page from the searched data or the whole data. Search is performed in the watch section below
     */
    queriedData() {
      let result = this.tableData;
      if (this.searchedData.length > 0) {
        result = this.searchedData;
      }
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.tableData.length;
    },
  },
  data() {
    return {
      isDisabled: false,
      tableData: [],
      user_profiles: [],
      search_profile_id:"",
      search_serial_number: "",
      searchBySource: "",
      searchByStatus:"",

      //Pagination
      pagination: {
        perPage: 25,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0,
      },
      searchedData: [],

      //Search Paramters
      search_by_type: "",
      search_by_sub_type: "",
    };
  },
  methods: {
    GetAuditList() {
        let data = {
          type: this.search_by_type,
          sub_type: this.search_by_sub_type,
          source: this.searchBySource,
          hasError: this.searchByStatus,
      }
      UserDataService.GetAuditList(data)
        .then((response) => {
          this.tableData = response.data.data;
        })
        .catch((e) => {
          this.$toast.error(e);
        });
    },
  },
  beforeMount() {
    this.GetAuditList();
  },
};
</script>
<style scoped>
</style>
