<template>
    <md-card>
        <md-card-content>
            <div class="md-layout">
                <div class="md-layout-item md-size-30 mt-2" :style="{ 'display':'ruby', 'text-align': 'center' }">
                    <md-radio v-model="appAction" value="Uninstall"><p :style="{ color: 'black' }">Uninstall</p></md-radio>
                    <md-radio v-model="appAction" value="Clear Data"><p :style="{ color: 'black' }">Clear Data</p></md-radio>
                </div>
                <div class="md-layout-item md-size-30">
                    <md-field>
                        <md-input type="text" placeholder="Search Applications to Add" v-model="searchPackageName"></md-input>
                    </md-field>
                </div>
                <div class="md-layout-item md-size-30">
                    <md-button class="md-primary" :disabled="isDisabled" @click="searchPlayStoreApps()">Search</md-button> 
                </div>
            </div>
            <div class="md-layout">
                <div class="app-listing-copy" v-if="playStoreApp">
                <ul id="product-directory">
                    <!-- Play Store Application Append -->
                    <li v-for="app in playStoreApp" :key="app.appId">
                        <img :src="app.icon">
                        <a class="badge-icon" href="javascript:void(0);" :style="{ color: 'black' }" @click="createProfileApplication(app)">
                            <i class="fa fa-plus" aria-hidden="true"></i>
                        </a>
                        <div class="app-listing-text">
                            <span class="apptext" :title="app.package_name"><small><b>{{app.title }}</b>  ({{ app.appId }})</small></span>
                        </div>
                    </li>
                </ul>
                </div>
            </div>
            <md-table v-model="profileApplications" table-header-color="green">
                <md-table-row slot="md-table-row" slot-scope="{ item }">
                    <md-table-cell md-label="Package Name">
                        <div style="display:flex;">
                        <img :src="item.appIcon" style="height: 50px;width: 50px;" />&nbsp;
                        <p v-if="item.appDetails">{{ item.appDetails.title }} : </p> <small>{{ item.appPackageName }}</small>
                        </div>
                    </md-table-cell>
                    <md-table-cell md-label="App Status">
                        <select class="form-control" @change="changeInstallType(item.appPackageName,$event)">
                        <option value="Uninstall" v-bind:selected="item.status == 'Uninstall'">Uninstall</option>
                        <option value="Clear Data" v-bind:selected="item.status == 'Clear Data'">Clear Data</option>
                        </select>  
                    </md-table-cell>
                    <md-table-cell md-label="Actions">
                        <div style="display: flex;">                    
                        <md-button class="md-just-icon md-danger md-simple" @click="deleteProfileApplication(item._id)"><md-icon>close</md-icon></md-button>
                        </div>
                    </md-table-cell>
                </md-table-row>
        </md-table>
        </md-card-content>
    </md-card>
</template>
<script>
import Swal from "sweetalert2";
import ProfileService from "../../services/Customer/ProfileService";
import DeviceService from "../../services/Customer/DeviceService";

export default {
  name: 'PcProfileApplicationsTab',
  props: {
    profileId: String,
  },
  data() {
    return {
      isDisabled: false,
        //Application Tab
        searchPackageName: "",
        playStoreApp: [],
        appAction:"Uninstall",
        profileApplications: [],
    }
  },
  methods: {
    searchPlayStoreApps() {
        this.isDisabled = true;
        let data = { app_name: this.searchPackageName }
        DeviceService.searchPlayStoreApps(data)
          .then((response) => {
            this.isDisabled = false;
            this.playStoreApp = response.data.data;
            this.$toast.success(response.data.respones_msg);
          })
          .catch((e) => {
            this.isDisabled = false;
            this.$toast.error(e.response.data.respones_msg);
          });
      },
      createProfileApplication(app) {
        let data = { profileId: this.profileId, appPackageName: app.appId, appIcon: app.icon, status: this.appAction, appDetails: app }
        ProfileService.createProfileApplication(data)
          .then((response) => {
            this.$toast.success(response.data.respones_msg);
            this.profileApplicationsList();
            this.playStoreApp = [];
            this.searchPackageName = "";
          })
          .catch((e) => {
            this.$toast.error(e.response.data.respones_msg);
            if (e.response.data.redirectToPaymentPage == true) {
              this.$router.push("plans");
            }
          });
      },
      profileApplicationsList() {
        let data = { profileId: this.profileId }
        ProfileService.profileApplicationsList(data)
          .then((response) => {
            this.profileApplications = response.data.data;
          })
          .catch((e) => {
            this.$toast.error(e.response.data.respones_msg);
          });
      },
      changeInstallType(packageName, event) {
        let data = { profileId: this.profileId, appPackageName: packageName, status: event.target.value }
        ProfileService.updateProfileApplication(data)
          .then((response) => {
            this.$toast.success(response.data.respones_msg);
          })
          .catch((e) => {
            this.$toast.error(e.response.data.respones_msg);
            if (e.response.data.redirectToPaymentPage == true) {
              this.$router.push("plans");
            }
          });
      },
      deleteProfileApplication(id) {
        Swal.fire({
            title: "Are you sure?",
            html: '<p>Application will be removed from this list</p>',
            type: "warning",
            showCancelButton: true,
            confirmButtonClass: "md-button md-success",
            cancelButtonClass: "md-button md-danger",
            confirmButtonText: "Yes, delete it!",
            buttonsStyling: false,
        }).then((result) => {
            if (result.value) {
                let data = { profileId: this.profileId, id: id }
                ProfileService.deleteProfileApplication(data)
                .then((response) => {
                    this.$toast.success(response.data.respones_msg);
                    this.profileApplicationsList();
                })
                .catch((e) => {
                    this.$toast.error(e.response.data.respones_msg);
                    if (e.response.data.redirectToPaymentPage == true) {
                    this.$router.push("plans");
                    }
                });
            }
        });
      },
  },
  beforeMount() {
    this.profileApplicationsList();
  },
}
</script>