<template>
    <div>
        <md-card>
            <md-card-content>
                <div class="md-layout">
                    <div class="md-layout-item md-size-33">
                        <md-field>
                        <md-input type="text" class="box-textbox" placeholder="Serial Number" v-model="serialNumber"></md-input>
                        </md-field>
                    </div>
                    <div class="md-layout-item md-size-33">
                        <md-button class="md-primary" :disabled="isDisabled"  @click="listDeviceLogs()">Search<span v-if="isDisabled"><i class="fa fa-spinner fa-spin"></i></span></md-button> 
                    </div>                    
                    <div class="md-layout-item md-size-100">
                        <md-table v-if="tableData.length > 0 "
                          :value="queriedData" 
                          :md-sort.sync="currentSort" :md-sort-order.sync="currentSortOrder" :md-sort-fn="customSort"
                          class="paginated-table table-striped table-hover"  table-header-color="green" md-fixed-header>
                          
                            <md-table-toolbar>
                              <md-field>
                                  <label for="pages">Per page</label>
                                  <md-select v-model="pagination.perPage" name="pages">
                                  <md-option
                                      v-for="item in pagination.perPageOptions"
                                      :key="item"
                                      :label="item"
                                      :value="item"
                                  >
                                      {{ item }}
                                  </md-option>
                                  </md-select>
                              </md-field>
                            </md-table-toolbar>
                            <md-table-row slot="md-table-row" slot-scope="{ item }">            
                                <md-table-cell md-label="Serial number" md-sort-by="serialNumber">
                                  {{ item.serialNumber }} <span v-if="item.deviceName"><br><b>{{ item.deviceName }}</b></span>
                                </md-table-cell>
                                <md-table-cell md-label="Device Activity">{{ item.deviceActivity }}</md-table-cell>
                                <md-table-cell md-label="Activity Result">{{ item.activityResult }}</md-table-cell>
                                <md-table-cell md-label="Created On" md-sort-by="created_at">{{ HumanReadableDateFormat(item.created_at) }}</md-table-cell>
                            </md-table-row>
                        </md-table>
                        <h4 v-else>No data found.</h4>
                    </div>
                </div>
            </md-card-content>
            
            <md-card-actions md-alignment="space-between">
                <div class="">
                    <p class="card-category">
                    Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
                    </p>
                </div>
                <pagination
                    class="pagination-no-border pagination-success"
                    v-model="pagination.currentPage"
                    :per-page="pagination.perPage"
                    :total="total"
                >
                </pagination>
            </md-card-actions>
        </md-card>
    </div>
</template>
<script>
import DeviceService from "../../services/Customer/DeviceService";
import { Pagination } from "@/components";

export default {
  components: {
    Pagination
  },
  computed: {
        /***
         * Returns a page from the searched data or the whole data. Search is performed in the watch section below
         */
        queriedData() {
        let result = this.tableData;
        if (this.searchedData.length > 0) {
            result = this.searchedData;
        }
        return result.slice(this.from, this.to);
        },
        to() {
        let highBound = this.from + this.pagination.perPage;
        if (this.total < highBound) {
            highBound = this.total;
        }
        return highBound;
        },
        from() {
        return this.pagination.perPage * (this.pagination.currentPage - 1);
        },
        total() {
        return this.searchedData.length > 0
            ? this.searchedData.length
            : this.tableData.length;
        },
    },
  data() {
    return {
      isDisabled: false,
      tableData: [],
      serialNumber:"",

      //Pagination
      pagination: {
            perPage: 25,
            currentPage: 1,
            perPageOptions: [5, 10, 25, 50],
            total: 0,
        },
      searchedData: [],
      //Sorting
      currentSort: "created_at",
      currentSortOrder: "asc",
    };
  },
  methods: {
      customSort(value) {
        return value.sort((a, b) => {
          const sortBy = this.currentSort;
          if (this.currentSortOrder === "desc") {
            return a[sortBy].localeCompare(b[sortBy]);
          }
          return b[sortBy].localeCompare(a[sortBy]);
        });
      },
      listDeviceLogs() {
        this.isDisabled = true;
        let data = { serialNumber: this.serialNumber }
        DeviceService.listDeviceLogs(data)
          .then((response) => {
            this.isDisabled = false;
            this.tableData = response.data.data;
            this.$toast.success(response.data.respones_msg);
          })
          .catch((e) => {
            this.isDisabled = false;
            this.$toast.error(e.response.data.respones_msg);
          });
      },
  },
  beforeMount() {
    this.listDeviceLogs();
  },
};
</script>
