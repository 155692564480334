<template>
    <div>
          <div style="float: right">
            <md-button class="md-primary" :disabled="isDisabled" @click="exportData()">Export</md-button>
          </div>
          <md-card>
            <md-card-content>
                  <div class="row">
                      <div class="col">
                          <md-field>
                              <md-datepicker v-model="from_date" md-immediately>
                                  <label>From</label>
                              </md-datepicker>
                          </md-field>
                      </div>
                      <div class="col">
                          <md-field>
                              <md-datepicker v-model="to_date" md-immediately>
                                  <label>To</label>
                              </md-datepicker>
                          </md-field>
                      </div>
                      <div class="col" :style="{ 'padding': '20px' }">
                          <md-field>
                              <label>Email</label>
                              <md-input v-model="email" type="text"></md-input>
                          </md-field>
                      </div>
                      <div class="col" :style="{ 'padding': '20px' }">
                          <md-button class="md-success" :disabled="isDisabled" @click="getAllDevices()">Search<span v-if="isDisabled"><i class="fa fa-spinner fa-spin"></i></span></md-button>
                      </div>
                  </div>
            </md-card-content>
        </md-card>
        <md-card>
            <md-card-content>
                <div class="md-layout">
                    <div class="md-layout-item md-size-100">
                        <md-table v-if="tableData.length > 0 " :value="queriedData" 
                        class="md-container-custom paginated-table" table-header-color="green" md-fixed-header>
                            <md-table-toolbar>
                                <md-field>
                                    <label for="pages">Per page</label>
                                    <md-select v-model="pagination.perPage" name="pages">
                                    <md-option
                                        v-for="item in pagination.perPageOptions"
                                        :key="item"
                                        :label="item"
                                        :value="item"
                                    >
                                        {{ item }}
                                    </md-option>
                                    </md-select>
                                </md-field>
                            </md-table-toolbar>
                            <md-table-row slot="md-table-row" slot-scope="{ item }">            
                                <md-table-cell md-label="User" >
                                    <span v-if="item.userId">{{ item.userId.email }}</span>
                                    <span v-else>-</span>
                                </md-table-cell>
                                <md-table-cell md-label="Serial Number" >{{ item.serialNumber }}</md-table-cell>
                                <md-table-cell md-label="Device Name" >{{ item.deviceName }}</md-table-cell>
                                <md-table-cell md-label="Version Code" >{{ item.appVersionCode }}</md-table-cell>
                                <md-table-cell md-label="Created On">{{ HumanReadableDateFormat(item.created_at) }}</md-table-cell>
                            </md-table-row>
                        </md-table>
                        <h4 v-if="tableData.length == 0">No data found.</h4>
                    </div>
                </div>
            </md-card-content>
            <md-card-actions md-alignment="space-between">
                <div class="">
                    <p class="card-category">
                    Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
                    </p>
                </div>
                <pagination
                    class="pagination-no-border pagination-success"
                    v-model="pagination.currentPage"
                    :per-page="pagination.perPage"
                    :total="total"
                >
                </pagination>
            </md-card-actions>
        </md-card>
    </div>
</template>
<script>
import NotificationService from "../../../services/SuperAdmin/NotificationService";
import { Pagination } from "@/components";
export default {
  components: {
    Pagination,
  },
  computed: {
    /***
     * Returns a page from the searched data or the whole data. Search is performed in the watch section below
     */
    queriedData() {
      let result = this.tableData;
      if (this.searchedData.length > 0) {
        result = this.searchedData;
      }
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.tableData.length;
    },
  },
  data() {
    return {
      isDisabled: false,
      tableData: [],
      from_date: new Date().toISOString().split('T')[0],
      to_date: new Date().toISOString().split('T')[0],
      email: "",

      //Pagination
      pagination: {
        perPage: 25,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50, 100, 500],
        total: 0,
      },
      searchedData: [],
    };
  },
  methods: {
      getAllDevices() {
        this.isDisabled = true;
        let data = {
          email: this.email, from_date: this.from_date, to_date: this.to_date
        }
        NotificationService.allDeviceslist(data)
          .then((response) => {
            this.isDisabled = false;
            this.tableData = response.data.data;
          }).catch((e) => {
            this.isDisabled = false;
            this.$toast.error(e.response.data.respones_msg);
          });
      },
      async exportData() {
        if (this.tableData.length > 0) {
          let exportData = [];
          for await (let data of this.tableData) {
            exportData.push([ (data.userId) ? data.userId.email : "" , data.serialNumber, data.deviceName, data.appVersionCode, data.created_at]);
          }
          let header = 'Email,SerialNumber,DeviceName,VersionCode,CreatedOn\n'
          this.exportToCSV(exportData, "AllDevices",header);
        } else {
          this.$toast.error("No data to export");
        }
      },
      exportToCSV(exportData = "", fileName, columnsName) {
          let csv = columnsName; //'Email,Created On\n';
          if (exportData) {
            exportData.forEach((row) => {
                csv += row.join(',');
                csv += "\n";
            });
          }
          const anchor = document.createElement('a');
          anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv);
          anchor.target = '_blank';
          if (exportData) {
            anchor.download = fileName+'.csv';
          }
          anchor.click();
      },
  },
  beforeMount() {
    this.getAllDevices();
  },
};
</script>