<template>
    <div>
        <md-card>
            <md-card-content>
                <div class="row">
                    <div class="col">
                        <md-field>
                            <md-datepicker v-model="from_date" md-immediately>
                                <label>From</label>
                            </md-datepicker>
                        </md-field>
                    </div>
                    <div class="col">
                        <md-field>
                            <md-datepicker v-model="to_date" md-immediately>
                                <label>To</label>
                            </md-datepicker>
                        </md-field>
                    </div>
                    <div class="col">
                        <md-field>
                            <label>Page</label>
                            <md-select v-model="page">
                                <md-option value="">All</md-option>
                                <md-option value="Home">Home</md-option>
                                <md-option value="Payment">Payment</md-option>
                            </md-select>
                        </md-field>
                    </div>
                    <div class="col">
                        <md-button class="md-success" :disabled="isDisabled" @click="pageViewReport()">Search<span v-if="isDisabled"><i class="fa fa-spinner fa-spin"></i></span></md-button>
                    </div>
                </div>
            </md-card-content>
        </md-card>
        <div style="float: right">
            <md-button class="md-primary" :disabled="isDisabled" @click="exportData()">Export</md-button>
        </div>
        <md-card>
            <md-card-content>
                <div class="md-layout">
                    <div class="md-layout-item md-size-100">
                        <md-table v-if="tableData.length > 0 " v-model="queriedData" 
                        :md-sort.sync="currentSort"
                        :md-sort-order.sync="currentSortOrder"
                        :md-sort-fn="customSort"
                        class="md-container-custom paginated-table" table-header-color="green" md-fixed-header>
                            <md-table-toolbar>
                                <md-field>
                                    <label for="pages">Per page</label>
                                    <md-select v-model="pagination.perPage" name="pages">
                                    <md-option
                                        v-for="item in pagination.perPageOptions"
                                        :key="item"
                                        :label="item"
                                        :value="item"
                                    >
                                        {{ item }}
                                    </md-option>
                                    </md-select>
                                </md-field>
                              </md-table-toolbar>
                            <md-table-row slot="md-table-row" slot-scope="{ item }">            
                                <md-table-cell md-label="Country" md-sort-by="_id">{{ item._id }}</md-table-cell>
                                <md-table-cell md-label="Count" md-sort-by="count">{{ item.ipAddress.length }}</md-table-cell>
                            </md-table-row>
                        </md-table>
                        <md-card-actions md-alignment="space-between">
                            <div class="">
                                <p class="card-category">
                                Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
                                </p>
                            </div>
                            <pagination
                                class="pagination-no-border pagination-success"
                                v-model="pagination.currentPage"
                                :per-page="pagination.perPage"
                                :total="total"
                            >
                            </pagination>
                        </md-card-actions>
                        <h4 v-if="tableData.length == 0">No data found.</h4>
                    </div>
                </div>
            </md-card-content>
        </md-card>
    </div>
</template>
<script>
import PriceDataService from "../../services/SuperAdmin/PriceDataService";
import { Pagination } from "@/components";

export default {
  components: {
    Pagination
  },
  computed: {
        queriedData() {
          let result = this.tableData;
          if (this.searchedData.length > 0) {
              result = this.searchedData;
          }
          return result.slice(this.from, this.to);
        },
        to() {
          let highBound = this.from + this.pagination.perPage;
          if (this.total < highBound) {
              highBound = this.total;
          }
          return highBound;
        },
        from() {
          return this.pagination.perPage * (this.pagination.currentPage - 1);
        },
        total() {
          return this.searchedData.length > 0
              ? this.searchedData.length
              : this.tableData.length;
        },
  },
  data() {
    return {
      isDisabled: false,
      tableData: [],
      page: "",
      from_date: new Date().toISOString().split('T')[0],
      to_date: new Date().toISOString().split('T')[0],

      //Sorting
      currentSort: "count",
      currentSortOrder: "asc",

      //Pagination
      pagination: {
            perPage: 25,
            currentPage: 1,
            perPageOptions: [5, 10, 25, 50, 100],
            total: 0,
        },
      searchedData: [],
    };
  },
  methods: {
      customSort(value) {
            return value.sort((a, b) => {
            const sortBy = this.currentSort;
            if (this.currentSortOrder === "desc") {
                return a[sortBy].localeCompare(b[sortBy]);
            }
            return b[sortBy].localeCompare(a[sortBy]);
            });
      },
      pageViewReport() {
        let data = { page: this.page, from_date: this.from_date, to_date: this.to_date };
        PriceDataService.pageViewReport(data)
          .then((response) => {
            this.tableData = response.data.data;
            this.$toast.success(response.data.respones_msg);
          }).catch((e) => {
            this.$toast.error(e.response.data.respones_msg);
          });
      },
      async exportData() {
        if (this.tableData.length > 0) {
            let exportData = [];
            for await (let data of this.tableData) {
            exportData.push([ data._id, data.ipAddress.length]);
            }
            this.DownloadSampleImportFile(exportData);
        } else {
            this.$toast.error("No data to export");
        }
    },
    DownloadSampleImportFile(exportData = "") {
        let csv = 'Country,Count\n';
        if (exportData) {
            exportData.forEach((row) => {
                csv += row.join(',');
                csv += "\n";
            });
        }
        const anchor = document.createElement('a');
        anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv);
        anchor.target = '_blank';
        if (exportData) {
            anchor.download = 'CountryWisePageView.csv';
        }
        anchor.click();
    },
  },
  beforeMount() {
    this.pageViewReport();
  },
};
</script>