<template>
  <div>
    <div class="md-layout">
      <div class="md-layout-item md-size-30">
          <md-field>
              <label>Month</label>
              <md-select v-model="reportMonth">
                  <md-option value="0">January</md-option>
                  <md-option value="1">February</md-option>
                  <md-option value="2">March</md-option>
                  <md-option value="3">April</md-option>
                  <md-option value="4">May</md-option>
                  <md-option value="5">June</md-option>
                  <md-option value="6">July</md-option>
                  <md-option value="7">August</md-option>
                  <md-option value="8">September</md-option>
                  <md-option value="9">Octomber</md-option>
                  <md-option value="10">November</md-option>
                  <md-option value="11">December</md-option>
              </md-select>
          </md-field>
        </div>
        <div class="md-layout-item md-size-30">
          <md-field>
              <label>Year</label>
              <md-select v-model="reportYear">
                  <md-option value="2023">2023</md-option>
                  <md-option value="2024">2024</md-option>
                  <md-option value="2025">2025</md-option>
                  <md-option value="2026">2026</md-option>
                  <md-option value="2027">2027</md-option>
                  <md-option value="2028">2028</md-option>
                  <md-option value="2029">2029</md-option>
                  <md-option value="2030">2030</md-option>
                  <md-option value="2031">2031</md-option>
                  <md-option value="2032">2032</md-option>
                  <md-option value="2033">2033</md-option>
              </md-select>
          </md-field>  
        </div>
        <div class="md-layout-item md-size-30">
          <md-button class="md-primary" :disabled="isDisabled"  @click="filterDashboard()">Search 
                        <span v-if="isDisabled"><i class="fa fa-spinner fa-spin"></i></span></md-button> 
        </div>
    </div>
    <div class="md-layout">
      <div class="md-layout-item md-medium-size-50 md-xsmall-size-100 md-size-20">
          <stats-card header-color="blue">
            <template slot="header">
              <p class="category">Today's Payment</p>
              <h3 class="title">
                <a href="javascript:void(0)" :style="{ color: 'blue !important' }"><span>{{ dashboardStats.todaysPayment }}</span></a>
              </h3>
            </template>
          </stats-card>
        </div>
        <div class="md-layout-item md-medium-size-50 md-xsmall-size-100 md-size-20">
          <stats-card header-color="blue">
            <template slot="header">
              <p class="category">Today's Success Payment</p>
              <h3 class="title">
                <a href="javascript:void(0)" :style="{ color: 'blue !important' }"><span>{{ dashboardStats.todaysSuccessPayment }}</span></a>
              </h3>
            </template>
          </stats-card>
        </div>
        <div class="md-layout-item md-medium-size-50 md-xsmall-size-100 md-size-20">
          <stats-card header-color="blue">
            <template slot="header">
              <p class="category">Today's Failed Payment</p>
              <h3 class="title">
                <a href="javascript:void(0)" :style="{ color: 'blue !important' }"><span>{{ dashboardStats.todaysFailedPayment }}</span></a>
              </h3>
            </template>
          </stats-card>
        </div>
        <div class="md-layout-item md-medium-size-50 md-xsmall-size-100 md-size-20">
          <stats-card header-color="blue">
            <template slot="header">
              <p class="category">Home Page</p>
              <h3 class="title">
                <a href="javascript:void(0)" @click="$router.push({ path: 'admin-page-view-report'})" :style="{ color: 'blue !important' }"><span>{{ dashboardStats.todaysHomePageCount }}</span></a>
              </h3>
            </template>
          </stats-card>
        </div>
        <div class="md-layout-item md-medium-size-50 md-xsmall-size-100 md-size-20">
          <stats-card header-color="blue">
            <template slot="header">
              <p class="category">Payment Page</p>
              <h3 class="title">
                <a href="javascript:void(0)" @click="$router.push({ path: 'admin-page-view-report'})" :style="{ color: 'blue !important' }"><span>{{ dashboardStats.todaysPaymentPageCount }}</span></a>
              </h3>
            </template>
          </stats-card>
        </div>
    </div>
    <div class="md-layout">
        <div class="md-layout-item md-size-100 md-small-size-100">
            <md-button :style="{ 'float': 'right' }" class="md-primary" :disabled="isDisabled"  @click="exportDayWiseData()">Export 
                        <span v-if="isDisabled"><i class="fa fa-spinner fa-spin"></i></span></md-button> 
            <chart-card
            header-animation="false"
            :chart-data="dailyBarChart.data"
            :chart-options="dailyBarChart.options"
            :chart-responsive-options="dailyBarChart.responsiveOptions"
            chart-type="Bar"
            header-icon
            chart-inside-content
            no-footer
            background-color="green"
            >
            <template slot="chartInsideHeader">
                <div class="card-icon">
                <md-icon>money</md-icon>
                </div>
                <h4 class="title">
                Payment Count
                <small> - Day Wise</small>
                </h4>
            </template>
            </chart-card>
        </div>
    </div>
    <div class="md-layout">
        <div class="md-layout-item md-size-50 md-small-size-100">
            <chart-card
            header-animation="false"
            :chart-data="planWisePieChartByMonth.data"
            :chart-options="planWisePieChartByMonth.options"
            chart-type="Pie"
            header-icon
            chart-inside-content
            background-color="green"
            >
            <template slot="chartInsideHeader">
                <div class="card-icon">
                <md-icon>pie_chart</md-icon>
                </div>
                <h4 class="title">Product Wise Payment - By Month</h4>
            </template>
            <template slot="footer">
                <div class="md-layout">
                <div class="md-layout-item md-size-100">
                    <h6 class="category">Legend</h6>
                </div>
                <div class="md-layout-item">
                    <i class="fa fa-circle text-info"></i> Package Disabler - <b>{{ pdSubscriptionsCountByMonth }}</b> &nbsp;
                    <i class="fa fa-circle text-danger"></i> Power Cleaner - <b>{{ pcSubscriptionsCountByMonth }}</b> &nbsp;
                    <i class="fa fa-circle text-warning"></i> URL Blocker - <b>{{ ubSubscriptionsCountByMonth }}</b> &nbsp;
                    <i class="fa fa-circle text-primary"></i> Key Mapper - <b>{{ kmSubscriptionsCountByMonth }}</b> &nbsp;
                    <i class="fa fa-circle text-success"></i> Power Chat - <b>{{ pChatSubscriptionsCountByMonth }}</b> &nbsp;
                    <i class="fa fa-circle text-secondary"></i> Power On Boot- <b>{{ pobSubscriptionsCountByMonth }}</b> &nbsp;
                </div>
                </div>
            </template>
            </chart-card>
        </div>
        <div class="md-layout-item md-size-50 md-small-size-100">
            <md-button :style="{ 'float': 'right' }" class="md-primary" :disabled="isDisabled"  @click="exportMonthWiseData()">Export 
                        <span v-if="isDisabled"><i class="fa fa-spinner fa-spin"></i></span></md-button> 
            <chart-card
            header-animation="false"
            :chart-data="monthWiseBarChart.data"
            :chart-options="monthWiseBarChart.options"
            :chart-responsive-options="monthWiseBarChart.responsiveOptions"
            chart-type="Bar"
            header-icon
            chart-inside-content
            no-footer
            background-color="green"
            >
            <template slot="chartInsideHeader">
                <div class="card-icon">
                <md-icon>money</md-icon>
                </div>
                <h4 class="title">
                Payment Count
                <small> - Month Wise</small>
                </h4>
            </template>
            </chart-card>
        </div>
    </div>
    <div class="md-layout">
        <div class="md-layout-item md-size-50 md-small-size-100">
            <chart-card
            header-animation="false"
            :chart-data="planWisePieChart.data"
            :chart-options="planWisePieChart.options"
            chart-type="Pie"
            header-icon
            chart-inside-content
            background-color="green"
            >
            <template slot="chartInsideHeader">
                <div class="card-icon">
                <md-icon>pie_chart</md-icon>
                </div>
                <h4 class="title">Product Wise Payment - Overall</h4>
            </template>
            <template slot="footer">
                <div class="md-layout">
                <div class="md-layout-item md-size-100">
                    <h6 class="category">Legend</h6>
                </div>
                <div class="md-layout-item">
                    <i class="fa fa-circle text-info"></i> Package Disabler - <b>{{ pdSubscriptionsCount }}</b> &nbsp;
                    <i class="fa fa-circle text-danger"></i> Power Cleaner - <b>{{ pcSubscriptionsCount }}</b> &nbsp;
                    <i class="fa fa-circle text-warning"></i> URL Blocker - <b>{{ ubSubscriptionsCount }}</b> &nbsp;
                    <i class="fa fa-circle text-primary"></i> Key Mapper - <b>{{ kmSubscriptionsCount }}</b> &nbsp;
                    <i class="fa fa-circle text-success"></i> Power Chat - <b>{{ pChatSubscriptionsCount }}</b> &nbsp;
                    <i class="fa fa-circle text-secondary"></i> Power On Boot- <b>{{ pobSubscriptionsCount }}</b> &nbsp;
                </div>
                </div>
            </template>
            </chart-card>
        </div>
        <div class="md-layout-item md-size-50 md-small-size-100">
            <md-button class="md-primary" :disabled="isDisabled" @click="getPaymentDashboardData(true)">Export 
                        <span v-if="isDisabled"><i class="fa fa-spinner fa-spin"></i></span></md-button> <br>
            <md-table v-model="topTenCountryWiseCustomer" class="paginated-table table-striped table-hover" 
                table-header-color="green" md-fixed-header>
                <md-table-row slot="md-table-row" slot-scope="{ item }">     
                    <md-table-cell md-label="Country Code"><b> {{ item._id }}</b> </md-table-cell>
                    <md-table-cell md-label="Total Customer"><b> {{ item.count }}</b> </md-table-cell>
                </md-table-row>
            </md-table>
        </div>
    </div>
    <div class="md-layout">
      <div class="md-layout-item md-size-50 md-small-size-100">
            <chart-card
            header-animation="false"
            :chart-data="monthWiseMultiBarChartPageView.data"
            :chart-options="monthWiseMultiBarChartPageView.options"
            :chart-responsive-options="monthWiseMultiBarChartPageView.responsiveOptions"
            chart-type="Bar"
            header-icon
            chart-inside-content
            no-footer
            background-color="green"
            >
            <template slot="chartInsideHeader">
                <div class="card-icon">
                <md-icon>money</md-icon>
                </div>
                <h4 class="title">
                Page View Count
                <small> - Month Wise</small>
                </h4>
            </template>
            </chart-card>
        </div>
    </div>

  </div>
</template>
<script>
import {
  StatsCard,
  ChartCard,
} from "@/components";
import PaymentService from "../../../services/SuperAdmin/PaymentService";

export default {
  components: {
    StatsCard,
    ChartCard,
  },
  data() {
    return {
      isDisabled: false,
      dashboardStats: {
        todaysPayment: 0,
        todaysSuccessPayment: 0,
        todaysFailedPayment: 0
      },
      planWisePieChart: null,
      planWisePieChartByMonth: null,
      dailyBarChart: null,
      monthWiseBarChart: null,
      monthWiseBarChartData: null,
      dayWiseBarChartData: null,

      monthWiseMultiBarChartPageView: null,

      // Product wise count
      pdSubscriptionsCount: 0,
      pcSubscriptionsCount: 0,
      ubSubscriptionsCount: 0,
      kmSubscriptionsCount: 0,
      pChatSubscriptionsCount: 0,
      pobSubscriptionsCount: 0,

      // Product wise count by month
      pdSubscriptionsCountByMonth: 0,
      pcSubscriptionsCountByMonth: 0,
      ubSubscriptionsCountByMonth: 0,
      kmSubscriptionsCountByMonth: 0,
      pChatSubscriptionsCountByMonth: 0,
      pobSubscriptionsCountByMonth: 0,

      topTenCountryWiseCustomer: null,
      countryWiseCustomerData: null,

      filterByStatus:"",
      reportMonth: (this.$route.query.reportMonth) ? this.$route.query.reportMonth : new Date().getMonth(),
      reportYear: (this.$route.query.reportYear) ? this.$route.query.reportYear : new Date().getFullYear(),

      monthArray: ["Jan", "Feb", "March", "Apr", "May", "June", "July", "Aug", "Sept", "Oct", "Nov", "Dec"],
    };
  },
  methods: {
    async getPaymentDashboardData(isExportData = false) {
      this.isDisabled = true;
      var data = { hasError : this.filterByStatus, reportMonth: this.reportMonth, reportYear: this.reportYear, isExportData: isExportData };
      await PaymentService.getPaymentDashboardData(data)
        .then((response) => { 
            this.isDisabled = false;
            this.dashboardStats = response.data.data.dashboardStats;
            let planWisePieChart = response.data.data.planWisePieChart;
            let planWisePieChartByMonth = response.data.data.planWisePieChartByMonth;
            if (isExportData == false) {
              this.topTenCountryWiseCustomer = response.data.data.topTenCountryWiseCustomer;
            } else {
              this.countryWiseCustomerData = response.data.data.topTenCountryWiseCustomer;
              let exportData = [];
              for (let data of this.countryWiseCustomerData) {
                exportData.push([ data._id, data.count ]);
              }
              this.DownloadSampleImportFile(exportData, 'Country,Customers\n', 'country-wise-customers');
            }
            this.pdSubscriptionsCount = planWisePieChart.pdSubscriptions;
            this.pcSubscriptionsCount = planWisePieChart.pcSubscriptions;
            this.ubSubscriptionsCount = planWisePieChart.ubSubscriptions;
            this.kmSubscriptionsCount = planWisePieChart.kmSubscriptions;
            this.pobSubscriptionsCount = planWisePieChart.pobSubscriptions;
            this.pChatSubscriptionsCount = planWisePieChart.pChatSubscriptions;

            this.planWisePieChart = {
                data: {
                labels: [planWisePieChart.pdSubscriptions, planWisePieChart.pcSubscriptions, planWisePieChart.ubSubscriptions, planWisePieChart.kmSubscriptions, planWisePieChart.pChatSubscriptions, planWisePieChart.pobSubscriptions],
                series: [planWisePieChart.pdSubscriptions, planWisePieChart.pcSubscriptions, planWisePieChart.ubSubscriptions, planWisePieChart.kmSubscriptions, planWisePieChart.pChatSubscriptions, planWisePieChart.pobSubscriptions],
                },
                options: {
                height: "230px",
                },
            }

            this.pdSubscriptionsCountByMonth = planWisePieChartByMonth.pdSubscriptions;
            this.pcSubscriptionsCountByMonth = planWisePieChartByMonth.pcSubscriptions;
            this.ubSubscriptionsCountByMonth = planWisePieChartByMonth.ubSubscriptions;
            this.kmSubscriptionsCountByMonth = planWisePieChartByMonth.kmSubscriptions;
            this.pobSubscriptionsCountByMonth = planWisePieChartByMonth.pobSubscriptions;
            this.pChatSubscriptionsCountByMonth = planWisePieChartByMonth.pChatSubscriptions;

            this.planWisePieChartByMonth = {
                data: {
                labels: [planWisePieChartByMonth.pdSubscriptions, planWisePieChartByMonth.pcSubscriptions, planWisePieChartByMonth.ubSubscriptions, planWisePieChartByMonth.kmSubscriptions, planWisePieChartByMonth.pChatSubscriptions, planWisePieChartByMonth.pobSubscriptions],
                series: [planWisePieChartByMonth.pdSubscriptions, planWisePieChartByMonth.pcSubscriptions, planWisePieChartByMonth.ubSubscriptions, planWisePieChartByMonth.kmSubscriptions, planWisePieChartByMonth.pChatSubscriptions, planWisePieChartByMonth.pobSubscriptions],
                },
                options: {
                height: "230px",
                },
            }
            
            const now = new Date();
            now.setMonth(this.reportMonth);
            let totalDaysInMonth = new Date(now.getFullYear(), now.getMonth() + 1, 0).getDate();
            let daysArray = Array(totalDaysInMonth).fill(0).map((_, i) => i + 1);
            
            this.dayWiseBarChartData = response.data.data.dayWiseBarChart;
            this.dailyBarChart = {
                data: {
                    labels: daysArray,
                    series: response.data.data.dayWiseBarChart
                },
                options: {
                    height: "230px",
                    seriesBarDistance: 10,
                    axisX: {
                    showGrid: false,
                    },
                },
                responsiveOptions: [
                    [
                    "screen and (max-width: 640px)",
                    {
                        seriesBarDistance: 5,
                        axisX: {
                        labelInterpolationFnc: function (value) {
                            return value[0];
                        },
                        },
                    },
                    ],
                ],
            }

            this.monthWiseBarChartData = response.data.data.monthWiseBarChart;
            this.monthWiseBarChart = {
                data: {
                    labels: ["Jan", "Feb", "March", "Apr", "May", "June", "July", "Aug", "Sept", "Oct", "Nov", "Dec"],
                    series: response.data.data.monthWiseBarChart,
                },
                options: {
                    height: "230px",
                    seriesBarDistance: 10,
                    axisX: {
                    showGrid: false,
                    },
                },
                responsiveOptions: [
                    [
                    "screen and (max-width: 640px)",
                    {
                        seriesBarDistance: 5,
                        axisX: {
                        labelInterpolationFnc: function (value) {
                            return value[0];
                        },
                        },
                    },
                    ],
                ],
            }

            this.monthWiseMultiBarChartPageView = {
                data: {
                    labels: ["Jan", "Feb", "March", "Apr", "May", "June", "July", "Aug", "Sept", "Oct", "Nov", "Dec"],
                    series: response.data.data.monthWisePageViewMultipleBarChart,
                },
                options: {
                    height: "230px",
                    seriesBarDistance: 10,
                    axisX: {
                    showGrid: false,
                    },
                },
                responsiveOptions: [
                    [
                    "screen and (max-width: 640px)",
                    {
                        seriesBarDistance: 5,
                        axisX: {
                        labelInterpolationFnc: function (value) {
                            return value[0];
                        },
                        },
                    },
                    ],
                ],
            }
        })
        .catch((e) => {
          this.isDisabled = false;
          if (e.response.data.is_logout == 1) {
            localStorage.setItem('super_admin_token', "");            
              this.$router.push('/super-admin/login');
            }
          this.$toast.error(e.response.data.respones_msg);
        });
    },
    filterDashboard() {
      this.$router.push({ path: 'admin-payment-dashboard', query: { reportMonth: this.reportMonth, reportYear: this.reportYear }});
      this.$router.go();
    },
    async exportDayWiseData() {
      let exportData = [];
      const now = new Date();
      now.setMonth(this.reportMonth);
      let totalDaysInMonth = new Date(now.getFullYear(), now.getMonth() + 1, 0).getDate();
      let daysArray = Array(totalDaysInMonth).fill(0).map((_, i) => i + 1);
      let i = 0
      for await (let data of this.dayWiseBarChartData) {
        exportData.push([ daysArray[i], data ]);
        i++;
      }
      this.DownloadSampleImportFile(exportData, 'Day,Count\n', this.monthArray[this.reportMonth] + ' ' +this.reportYear);
    },
    async exportMonthWiseData() {
      let exportData = [];
      let i = 0
      for await (let data of this.monthWiseBarChartData) {
        exportData.push([ this.monthArray[i], data ]);
        i++;
      }
      this.DownloadSampleImportFile(exportData, 'Month,Count\n', this.reportYear);
    },
    DownloadSampleImportFile(exportData = "", heading, title) {
      let csv = heading;
      if (exportData) {
        exportData.forEach((row) => {
            csv += row.join(',');
            csv += "\n";
        });
      }
      const anchor = document.createElement('a');
      anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv);
      anchor.target = '_blank';
      if (exportData) {
        anchor.download = title+'-payment.csv';
      }
      anchor.click();
    },
  },
  watch: {
  },
  beforeMount() {
    this.getPaymentDashboardData();  
  },
};
</script>

<style>
.fs-12{
  font-size: 12px;
}
.md-card-actions{
  padding: unset !important;
}
</style>
