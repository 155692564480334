<template>
    <div>
        <div class="md-layout">
            <div class="md-layout-item md-size-100">
                <div style="float: left">
                    <h4>
                        <p class="text-primary" v-if="profileData"> {{ profileData.name }} </p>
                    </h4>
                </div>
                <div style="float: right">
                    <md-button class="md-success mr-2" :disabled="isDisabled" @click="applyPolicy()">
                        Apply<span v-if="isDisabled"><i class="fa fa-spinner fa-spin"></i></span>
                    </md-button>
                </div>
                <tabs :tab-name="['Key Mapping', 'Devices']" color-button="warning">
                    <template slot="tab-pane-1">
                        <KeyMappingTab  :profileId="profileId" />
                    </template>
                    <template slot="tab-pane-2">
                        <DeviceTab  :profileId="profileId" />
                    </template>  
                </tabs>
            </div>
        </div>
    </div>
</template>
<script>
import ProfileService from "../../../services/Customer/ProfileService";
import { Tabs } from "@/components";
import Swal from "sweetalert2";
import KeyMappingTab  from '@/components/Custom/KeyMappingTab';
import DeviceTab  from '@/components/Custom/DeviceTab';

export default {
  components: {
    Tabs,
    KeyMappingTab,
    DeviceTab
  },
  data() {
    return {
      isDisabled: false,
      profileId: this.$route.query.profile_id,
      profileData: {},
      deviceDetail: null,
      blockFileUrl: "",
    };
  },
  methods: {
      profileDetail() {
        let data = { profileId: this.profileId }
        ProfileService.profileDetail(data)
          .then((response) => {
            this.profileData = response.data.data;
            this.blockFileUrl = this.profileData.blockFileUrl;
            //this.$toast.success(response.data.respones_msg);
          })
          .catch((e) => {
            this.$toast.error(e.response.data.respones_msg);
          });
      },
      profileDeviceDetail() {
        let data = { profileId: this.profileId }
        ProfileService.profileDeviceDetail(data)
          .then((response) => {
            this.deviceDetail = response.data.data;
            //this.$toast.success(response.data.respones_msg);
          })
          .catch((e) => {
            this.$toast.error(e.response.data.respones_msg);
          });
      },
      applyPolicy() {
        this.isDisabled = true;
        let data = { 
          profileId: this.profileId,
        };
        ProfileService.applyPolicy(data)
          .then((response) => {
            this.isDisabled = false;
            this.profileDetail();
            this.$toast.success(response.data.respones_msg);
          })
          .catch((e) => {
            this.isDisabled = false;
            this.$toast.error(e.response.data.respones_msg);
            if (e.response.data.redirectToPaymentPage == true) {
              this.$router.push("plans");
            }
          });
      },

      updateProfile() {
        let data = { 
          profileId: this.profileId,
          blockFileUrl: this.blockFileUrl,
        };
        ProfileService.updateProfile(data)
          .then((response) => {
            this.$toast.success(response.data.respones_msg);
          })
          .catch((e) => {
            this.$toast.error(e.response.data.respones_msg);
            if (e.response.data.redirectToPaymentPage == true) {
              this.$router.push("plans");
            }
          });
      },
  },
  beforeMount() {
    this.profileDetail();
    //this.profileDeviceDetail();
  },
};
</script>
<style>
.tab-content {
  display: unset !important;
}
.md-switch .md-switch-label {
  color: unset !important;
}
</style>