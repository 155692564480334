<template>
    <div>
        <md-card>
            <md-card-content>
                <form ref="createVideoForm">
                    <div class="row">
                        <div class="col">
                            <md-switch v-model="isOfflinePayment">Offline Payments</md-switch>  
                        </div>
                        <div class="col">
                            <md-field>
                                <label>Email</label>
                                <md-input v-model="email" type="text"></md-input>
                            </md-field>
                        </div>
                        <div class="col">
                            <md-button class="md-success" :disabled="isDisabled" @click="paymentHistory()">Search</md-button>
                        </div>
                    </div>
                </form>
            </md-card-content>
        </md-card>
        <md-card>
            <md-card-content>
                <div class="md-layout">
                    <div class="md-layout-item md-size-100">
                        <md-table :value="queriedData" class="paginated-table table-striped table-hover" 
                            table-header-color="green" md-fixed-header>
                            <md-table-toolbar>
                                <md-field>
                                    <label for="pages">Per page</label>
                                    <md-select v-model="pagination.perPage" name="pages">
                                    <md-option
                                        v-for="item in pagination.perPageOptions"
                                        :key="item"
                                        :label="item"
                                        :value="item"
                                    >
                                        {{ item }}
                                    </md-option>
                                    </md-select>
                                </md-field>
                            </md-table-toolbar>
                            <md-table-row slot="md-table-row" slot-scope="{ item }">            
                                <md-table-cell md-label="Name">
                                    {{ item.productName }} <br>
                                    <small>
                                        <a v-if="item.status == 'Failed'" href="javascript:void(0)" @click="sendFailedEmailModelOpen(item.email)">
                                            {{ item.email }} <span v-if="item.failedEmailFollowupCount"> - {{ item.failedEmailFollowupCount }}</span>
                                        </a>
                                        <b v-else>{{ item.email }}</b>                                        
                                    </small>
                                    <a href="javascript:void(0);" :style="{ color: 'green', 'padding-left': '12px' }" class="md-just-icon md-success md-simple" @click="copyContent(item.email)"><i class="fa fa-clone" aria-hidden="true"></i></a>
                                </md-table-cell>
                                <md-table-cell md-label="Type">
                                    <p class="badge-primary" v-if="item.subscriptionType == 'recurring'">Recurring</p>
                                    <p class="badge-warning" v-else>Onetime</p>
                                    <small v-if="item.subscriptionReason"> <b>{{ item.subscriptionReason }}</b></small>
                                </md-table-cell>
                                <md-table-cell md-label="Qty"><b> {{ item.qty }} License </b> </md-table-cell>
                                <md-table-cell md-label="Amount">
                                  <b>€{{ item.totalAmount }}</b> 
                                  <span v-if="item.coupon" class="text-info">
                                      <!--<b v-if="item.discountAmount > 0"><br>Discount : €{{ item.discountAmount }} </b>-->
                                      <b><br>CouponCode : {{ item.coupon }} </b>
                                  </span>
                                </md-table-cell>
                                <md-table-cell md-label="Status">
                                    <p class="badge-success" v-if="item.status == 'Success'">{{ item.status }}</p>
                                    <p class="badge-danger" v-else-if="item.status == 'Failed'">
                                        {{ item.status }}
                                    </p>
                                    <p class="badge-primary" v-else>{{ item.status }}</p>
                                    <small v-if="item.message">{{ item.message }}</small>
                                </md-table-cell>
                                <md-table-cell md-label="Expiry At">
                                    <span v-if="item.expiryAt">{{ HumanReadableDateFormat(item.expiryAt, true) }}</span>
                                    <span v-else>-</span>
                                </md-table-cell>
                                <md-table-cell md-label="Created On">{{ HumanReadableDateFormat(item.created_at) }}</md-table-cell>
                            </md-table-row>
                        </md-table>
                    </div>
                </div>
            </md-card-content>
            <md-card-actions md-alignment="space-between">
                <div class="">
                    <p class="card-category">
                    Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
                    </p>
                </div>
                <pagination
                    class="pagination-no-border pagination-success"
                    v-model="pagination.currentPage"
                    :per-page="pagination.perPage"
                    :total="total"
                >
                </pagination>
            </md-card-actions>
        </md-card>
        <template>
          <modal v-if="sendFailedEmailModal" @close="sendFailedEmailModal = false">
            <template slot="header">
              <h4 class="modal-title">Send Email</h4>
              <md-button
                style="height: 25px" class="md-simple md-just-icon md-round modal-default-button"
                @click="sendFailedEmailModal = false"><md-icon>clear</md-icon></md-button>
            </template>
            <template slot="body">
            <div class="box-body">
              <div class="md-layout">
                <div class="md-layout-item md-size-100">
                  <md-field>
                    <md-textarea v-model="failPaymentEmailContent"></md-textarea>
                  </md-field>
                </div>
              </div>
            </div>
            </template>
            <template slot="footer">
              <md-button class="md-danger md-simple" @click="sendFailedEmailModal = false">Close</md-button>
              <md-button class="md-success mt-1" :disabled="isDisabled" @click="sendFailedPaymentEmail()">Send</md-button>
            </template>
          </modal>
      </template>
    </div>
</template>
<script>
import PaymentService from "../../../services/SuperAdmin/PaymentService";
import { Pagination } from "@/components";
import { Modal } from "@/components";

export default {
  components: {
    Pagination,
    Modal
  },
  computed: {
    /***
     * Returns a page from the searched data or the whole data. Search is performed in the watch section below
     */
    queriedData() {
      let result = this.tableData;
      if (this.searchedData.length > 0) {
        result = this.searchedData;
      }
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.tableData.length;
    },
  },
  data() {
    return {
      isDisabled: false,
      tableData: [],
      email: "",
      status:"",
      from_date: new Date().toISOString().split('T')[0],
      to_date: new Date().toISOString().split('T')[0],
      zeroSuccessPayment: false,
      subscriptionType: "",
      isOfflinePayment: false,
      
      //Pagination
      pagination: {
        perPage: 25,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0,
      },
      searchedData: [],

      sendFailedEmailModal: false,
      failedPaymentEmail: "",
      failPaymentEmailContent:"Dear User,<br>We regret to inform you that your purchase of the app was unsuccessful. We apologize for any inconvenience this may have caused you.\n\nIf you need any assistance to resolve this issue, please do not hesitate to contact us.<br><br>You can also make payment via paypal to packagedisabler@gmail.com and email us.<br><br>Sincerely,<br>Support team"
    };
  },
  methods: {
      paymentHistory() {
        let data = { email: this.email, isOfflinePayment: this.isOfflinePayment }
        PaymentService.upcomingExpirePaymentHistory(data)
          .then((response) => {
            this.tableData = response.data.data;
            this.$toast.success(response.data.respones_msg);
          })
          .catch((e) => {
            this.$toast.error(e.response.data.respones_msg);
          });
      },
      sendFailedEmailModelOpen(email) {
        this.failedPaymentEmail = email;
        this.sendFailedEmailModal = true;
      },
      sendFailedPaymentEmail() {
        this.isDisabled = true;
        let data = { email: this.failedPaymentEmail, content: this.failPaymentEmailContent };
        PaymentService.sendFailedPaymentEmail(data)
          .then((response) => {
            this.isDisabled = false;
            this.sendFailedEmailModal = false;
            this.paymentHistory();
            this.$toast.success(response.data.respones_msg);
          })
          .catch((e) => {
            this.isDisabled = false;
            this.$toast.error(e.response.data.respones_msg);
          });
      }
  },
  beforeMount() {
    this.paymentHistory();
  },
};
</script>
