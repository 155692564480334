<template>
    <div>
        <md-card>
            <md-card-content>
                <div class="row">
                    <div class="col-md-10">
                        <md-field>
                            <label>Payment Text (It's visible on website)</label>
                            <md-textarea v-model="paymentNotes" type="text"></md-textarea>
                        </md-field>
                    </div>
                    <div class="col-md-2">
                        <md-button class="md-success" :disabled="isDisabled" @click="updateSettings()">Save<span v-if="isDisabled"><i class="fa fa-spinner fa-spin"></i></span></md-button>
                    </div>
                </div>
            </md-card-content>
        </md-card>
        <md-card>
            <md-card-content>
                <div class="md-layout">
                    <div class="md-layout-item md-size-100">
                        <md-table v-if="tableData.length > 0 " v-model="tableData" 
                        class="md-container-custom paginated-table" table-header-color="green" md-fixed-header>
                            <md-table-row slot="md-table-row" slot-scope="{ item }">            
                                <md-table-cell md-label="Coupon" >{{ item.id }}</md-table-cell>
                                <md-table-cell md-label="Number Of Payment">
                                    <a href="javascript:void(0)" @click="$router.push({ path: 'admin-payment-history', query: { coupon: item.id }})">{{ item.totalPaymentCount }}</a>
                                </md-table-cell>
                            </md-table-row>
                        </md-table>
                        <h4 v-if="tableData.length == 0">No data found.</h4>
                    </div>
                </div>
            </md-card-content>
        </md-card>
    </div>
</template>
<script>
import PaymentService from "../../../services/SuperAdmin/PaymentService";

export default {
  components: {
  },
  data() {
    return {
      isDisabled: false,
      tableData: [],
      coupon: "",
      paymentNotes:""
    };
  },
  methods: {
      couponList() {
        this.isDisabled = true;
        PaymentService.couponList()
          .then((response) => {
            this.isDisabled = false;
            this.tableData = response.data.data;
            if (response.data.paymentNotes) {
                this.paymentNotes = response.data.paymentNotes.value;
            }
            this.$toast.success(response.data.respones_msg);
          }).catch((e) => {
            this.isDisabled = false;
            this.$toast.error(e.response.data.respones_msg);
          });
      },
      updateSettings() {
        this.isDisabled = true;
        let data = {
            key:"paymentNotes",
            value: this.paymentNotes
        }
        PaymentService.updateSettings(data)
          .then((response) => {
            this.isDisabled = false;
            this.$toast.success(response.data.respones_msg);
          }).catch((e) => {
            this.isDisabled = false;
            this.$toast.error(e.response.data.respones_msg);
          });
      },
  },
  beforeMount() {
    this.couponList();
  },
};
</script>