import http from "../../http-common-super-admin";

class BlogService {
  create(data) {
    return http.post("blog/create", data,  {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
      });
  }
  list() {
    return http.get("blog/list");
  }
  delete(data) {
    return http.delete("blog/delete", { params: data});
  }
  blogDetail(data) {
    return http.get("blog/detail", { params: data });
  }
  update(data) {
    return http.post("blog/update", data,  {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
      });
  }
}

export default new BlogService();