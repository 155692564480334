<template>
    <div>
        <div class="md-layout">
            <div class="md-layout-item md-size-100">
                <div style="float: left">
                    <h4>
                        <p class="text-primary" v-if="profileData"> {{ profileData.name }} </p>
                    </h4>
                </div>
                <div style="float: right">
                    <md-button class="md-success mr-2" :disabled="isDisabled" @click="applyPolicy()">
                        Apply<span v-if="isDisabled"><i class="fa fa-spinner fa-spin"></i></span>
                    </md-button>
                </div>
                <tabs :tab-name="['Applications', 'Files', 'Settings', 'Device']" color-button="warning">
                    <template slot="tab-pane-1">
                        <PcProfileApplicationsTab  :profileId="profileId" />
                    </template>
                    <template slot="tab-pane-2">
                        <ProfileFilesTab  :profileId="profileId" />
                    </template>
                    <template slot="tab-pane-3">
                        <PcSettingTab  :profileId="profileId" :profileDetail="profileData" />
                    </template>
                    <template slot="tab-pane-4">
                      <DeviceTab  :profileId="profileId" />    
                    </template>  
                </tabs>
            </div>
        </div>
    </div>
</template>
<script>
import ProfileService from "../../../services/Customer/ProfileService";
import { Tabs } from "@/components";
import Swal from "sweetalert2";
import ProfileFilesTab  from '@/components/Custom/ProfileFilesTab';
import PcProfileApplicationsTab  from '@/components/Custom/PcProfileApplicationsTab';
import PcSettingTab  from '@/components/Custom/PcSettingTab';
import DeviceTab  from '@/components/Custom/DeviceTab';

export default {
  components: {
    Tabs,
    ProfileFilesTab,
    PcProfileApplicationsTab,
    PcSettingTab,
    DeviceTab
  },
  computed: {
        queriedData() {
          let result = this.applications;
          if (this.searchedData.length > 0) {
              result = this.searchedData;
          }
          return result.slice(this.from, this.to);
        },
        to() {
          let highBound = this.from + this.pagination.perPage;
          if (this.total < highBound) {
              highBound = this.total;
          }
          return highBound;
        },
        from() {
          return this.pagination.perPage * (this.pagination.currentPage - 1);
        },
        total() {
          return this.searchedData.length > 0
              ? this.searchedData.length
              : this.applications.length;
        },
  },
  data() {
    return {
      isDisabled: false,
      profileId: this.$route.query.profile_id,
      profileData: {},
      deviceDetail: null,

      //Application Tab
      searchPackageName: "",
      playStoreApp: [],
      appAction:"Uninstall",
      profileApplications: [],

      //Pagination
      pagination: {
            perPage: 25,
            currentPage: 1,
            perPageOptions: [5, 10, 25, 50],
            total: 0,
        },
      searchedData: [],

    };
  },
  methods: {
      profileDetail() {
        let data = { profileId: this.profileId }
        ProfileService.profileDetail(data)
          .then((response) => {
            this.profileData = response.data.data;
            //this.$toast.success(response.data.respones_msg);
          })
          .catch((e) => {
            this.$toast.error(e.response.data.respones_msg);
          });
      },
      profileDeviceDetail() {
        let data = { profileId: this.profileId }
        ProfileService.profileDeviceDetail(data)
          .then((response) => {
            this.deviceDetail = response.data.data;
            //this.$toast.success(response.data.respones_msg);
          })
          .catch((e) => {
            this.$toast.error(e.response.data.respones_msg);
          });
      },
      updateProfile() {
        let data = { 
          profileId: this.profileId,
          pin: this.pin,
          advancedSettings: {
            disableFactoryReset: this.disableFactoryReset,
            disableHomeKey: this.disableHomeKey,
            disableBackKey: this.disableBackKey,
            disableRecentsKey: this.disableRecentsKey,
            disablePowerKey: this.disablePowerKey,
            disableVolumeUpKey: this.disableVolumeUpKey,
            disableVolumeDownKey: this.disableVolumeDownKey,
            DisableOSUpdates: this.DisableOSUpdates,
            DisableBluetooth: this.DisableBluetooth,
            disableWiFi: this.disableWiFi,
            disableGPS: this.disableGPS,
            disableMultiWindow: this.disableMultiWindow,
            disableUsbDebugging: this.disableUsbDebugging,
            disableUnknownSource: this.disableUnknownSource,
          }
        };
        ProfileService.updateProfile(data)
          .then((response) => {
            this.$toast.success(response.data.respones_msg);
          })
          .catch((e) => {
            this.$toast.error(e.response.data.respones_msg);
            if (e.response.data.redirectToPaymentPage == true) {
              this.$router.push("plans");
            }
          });
      },
      applyPolicy() {
        this.isDisabled = true;
        let data = { 
          profileId: this.profileId,
        };
        ProfileService.applyPolicy(data)
          .then((response) => {
            this.isDisabled = false;
            this.profileDetail();
            this.$toast.success(response.data.respones_msg);
          })
          .catch((e) => {
            this.isDisabled = false;
            this.$toast.error(e.response.data.respones_msg);
            if (e.response.data.redirectToPaymentPage == true) {
              this.$router.push("plans");
            }
          });
      },

  },
  beforeMount() {
    this.profileDetail();
    this.profileDeviceDetail();
    this.profileApplicationsList();
  },
};
</script>
<style>
.tab-content {
  display: unset !important;
}
.md-switch .md-switch-label {
  color: unset !important;
}
#product-directory {
  padding: unset;
  box-sizing: border-box;
}
#product-directory ul {
  padding-left: 0;
  box-sizing: border-box;
}
#product-directory li {
  list-style: none;
  box-sizing: border-box;
  display: inline-block;
  width: 140px;
  margin: 5px;
  position: relative;            
  border: 1px solid #d3d3d3;
  align-content: center;
  display: inline-block; 
  vertical-align: top;
}
#product-directory li img {
  max-width: 65%;
  height: 85px;
  margin: 0 auto;
  position: relative;
  align-self: center;
  margin:0 auto;
  display:block;
}
#product-directory li span {
  display: block;
  text-align: center;
  font-size: 12px;
  font-weight: bold;
  /*background-color: #6a6969;*/
  padding: 5px;
  color: black;
  min-height: 34px;
  word-break: break-all;
  height: 75px;
}
.badge-icon {
    position: absolute;
    top: 0px;
    right: 10px;
    width: 18px;
    height: 18px;
    line-height: 18px;
    text-align: center;
    background: #84f78e;
    border-radius: 5px;
    font-size: 14px;
}
</style>