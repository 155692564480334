<template>
    <div>
        <div class="md-layout">
            <div class="md-layout-item md-size-100">
                <div style="float: left">
                    <h4>
                        <p class="text-primary" v-if="profileData"> {{ profileData.name }} </p>
                    </h4>
                </div>
                <div style="float: right">
                    <md-button class="md-success mr-2" :disabled="isDisabled" @click="applyPolicy()">
                        Apply<span v-if="isDisabled"><i class="fa fa-spinner fa-spin"></i></span>
                    </md-button>
                </div>
                <tabs :tab-name="['URL', 'File', 'Devices']" color-button="warning">
                    <template slot="tab-pane-1">
                        <ProfileUrlTab  :profileId="profileId" />
                    </template>
                    <template slot="tab-pane-2">
                        <md-card>
                          <md-card-content>
                            <div class="md-layout">
                                <div class="md-layout-item md-size-50">
                                    <md-field>
                                        <md-input type="text" placeholder="Enter File URL" v-model="blockFileUrl"></md-input>
                                    </md-field>
                                </div>
                                <div class="md-layout-item md-size-30">
                                    <md-button class="md-primary" :disabled="isDisabled" @click="updateProfile()">Update</md-button> 
                                </div>
                            </div>
                          </md-card-content>
                        </md-card>
                    </template>
                    <template slot="tab-pane-3">
                        <DeviceTab  :profileId="profileId" />
                    </template>  
                </tabs>
            </div>
        </div>
    </div>
</template>
<script>
import ProfileService from "../../../services/Customer/ProfileService";
import { Tabs } from "@/components";
import Swal from "sweetalert2";
import ProfileUrlTab  from '@/components/Custom/ProfileUrlTab';
import DeviceTab  from '@/components/Custom/DeviceTab';

export default {
  components: {
    Tabs,
    ProfileUrlTab,
    DeviceTab
  },
  data() {
    return {
      isDisabled: false,
      profileId: this.$route.query.profile_id,
      profileData: {},
      deviceDetail: null,
      blockFileUrl: "",
    };
  },
  methods: {
      profileDetail() {
        let data = { profileId: this.profileId }
        ProfileService.profileDetail(data)
          .then((response) => {
            this.profileData = response.data.data;
            this.blockFileUrl = this.profileData.blockFileUrl;
            //this.$toast.success(response.data.respones_msg);
          })
          .catch((e) => {
            this.$toast.error(e.response.data.respones_msg);
          });
      },
      profileDeviceDetail() {
        let data = { profileId: this.profileId }
        ProfileService.profileDeviceDetail(data)
          .then((response) => {
            this.deviceDetail = response.data.data;
            //this.$toast.success(response.data.respones_msg);
          })
          .catch((e) => {
            this.$toast.error(e.response.data.respones_msg);
          });
      },
      applyPolicy() {
        this.isDisabled = true;
        let data = { 
          profileId: this.profileId,
        };
        ProfileService.applyPolicy(data)
          .then((response) => {
            this.isDisabled = false;
            this.profileDetail();
            this.$toast.success(response.data.respones_msg);
          })
          .catch((e) => {
            this.isDisabled = false;
            this.$toast.error(e.response.data.respones_msg);
            if (e.response.data.redirectToPaymentPage == true) {
              this.$router.push("plans");
            }
          });
      },

      updateProfile() {
        let data = { 
          profileId: this.profileId,
          blockFileUrl: this.blockFileUrl,
        };
        ProfileService.updateProfile(data)
          .then((response) => {
            this.$toast.success(response.data.respones_msg);
          })
          .catch((e) => {
            this.$toast.error(e.response.data.respones_msg);
            if (e.response.data.redirectToPaymentPage == true) {
              this.$router.push("plans");
            }
          });
      },
  },
  beforeMount() {
    this.profileDetail();
    //this.profileDeviceDetail();
  },
};
</script>
<style>
.tab-content {
  display: unset !important;
}
.md-switch .md-switch-label {
  color: unset !important;
}
#product-directory {
  padding: unset;
  box-sizing: border-box;
}
#product-directory ul {
  padding-left: 0;
  box-sizing: border-box;
}
#product-directory li {
  list-style: none;
  box-sizing: border-box;
  display: inline-block;
  width: 140px;
  margin: 5px;
  position: relative;            
  border: 1px solid #d3d3d3;
  align-content: center;
  display: inline-block; 
  vertical-align: top;
}
#product-directory li img {
  max-width: 65%;
  height: 85px;
  margin: 0 auto;
  position: relative;
  align-self: center;
  margin:0 auto;
  display:block;
}
#product-directory li span {
  display: block;
  text-align: center;
  font-size: 12px;
  font-weight: bold;
  /*background-color: #6a6969;*/
  padding: 5px;
  color: black;
  min-height: 34px;
  word-break: break-all;
  height: 75px;
}
.badge-icon {
    position: absolute;
    top: 0px;
    right: 10px;
    width: 18px;
    height: 18px;
    line-height: 18px;
    text-align: center;
    background: #84f78e;
    border-radius: 5px;
    font-size: 14px;
}
</style>