<template>
    <div>
        <div style="float: right">
          <md-button class="md-success" :disabled="isDisabled" @click="createCustomer()">Save
            <span v-if="isDisabled"><i class="fa fa-spinner fa-spin"></i></span>
          </md-button>
        </div>
        <md-card>
            <md-card-content>
                <form ref="createCustomerForm">
                    <div class="row">
                        <div class="col">
                            <md-field>
                                <label>Email</label>
                                <md-input v-model="email" type="text"></md-input>
                            </md-field>
                        </div>
                        <div class="col">
                            <md-field>
                                <label>Password</label>
                                <md-input v-model="password" type="password"></md-input>
                            </md-field>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <md-field>
                                <label>Phone Number</label>
                                <md-input v-model="phoneNumber" type="text"></md-input>
                            </md-field>
                        </div>
                    </div>
                </form>
            </md-card-content>
        </md-card>
    </div>
</template>
<script>
import CustomerService from "../../../services/SuperAdmin/CustomerService";

export default {
  components: {
  },
  data() {
    return {
      isDisabled: false,
      email: "",
      password: "",
      phoneNumber: "",
    };
  },
  methods: {
      createCustomer() {
        this.isDisabled = true;
        let data = {
            email: this.email,
            password: this.password,
            phoneNumber: this.phoneNumber
        }
        CustomerService.createCustomer(data)
        .then((response) => {
            this.isDisabled = false;
            this.$toast.success(response.data.respones_msg);
            this.$router.push("admin-customer-list");
        })
        .catch((e) => {
            this.isDisabled = false;
            this.$toast.error(e.response.data.respones_msg);
        });
    },
  },
  beforeMount() {
  },
};
</script>
