import http from "../../http-common-super-admin";

class VideoDataService {
    addVideo(data) {
        return http.post("video/create", data);
    }
    videoList() {
        return http.get("video/list");
    }
    deleteVideo(data) {
        return http.delete("video/delete", { params: data });
    }
}

export default new VideoDataService();