<template>
    <div>
        <div class="row">
            <div class="col-md-1"></div>
            <div class="col-md-5 wrapper_1">                
                <h4 class="color-white">Summary</h4>
                <hr>
                <p>
                    <span class="color-white">Product</span> 
                    <span class="float-right color-black">
                        <b>{{ planDetail.name }}</b>
                    </span>
                </p>
                <p>
                    <span class="color-white">Plan Price</span> 
                    <span class="float-right color-black">
                        <b>{{ formatCurrency(planDetail.amount) }}</b>
                    </span>
                </p>
                <p>
                    <span class="color-white">Current Devices</span> 
                    <span class="float-right color-black">
                        <b>{{ planDetail.totalPurchasedDevices }}</b>
                    </span>
                </p>
                <p>
                    <span class="color-white">Devices After Purchase</span> 
                    <span class="float-right color-black">
                        <b>{{ deviceAfterPurchase }}</b>
                    </span>
                </p>
                <p>
                    <span class="color-white">Discount</span> 
                    <span class="float-right color-black">
                        <b>{{ formatCurrency(discount) }}</b>
                    </span>
                </p>
                <hr>
                <p class="color-white">Total <span class="float-right" style="color:black">
                <b>{{ formatCurrency(this.total - this.discount) }}</b></span></p>
            </div>
            <div class="col-md-5 wrapper_2">
                <div v-if="!paymentSuccess">
                    <md-label v-if="planDetail.identifier == 'PCHAT'"><b>Enter Number Of Users</b></md-label>
                    <md-label v-else><b>Enter Number Of Devices</b></md-label>
                    <md-field>
                        <md-input
                        placeholder="Qty" type="number" min="1" name="qty" v-model="qty"
                        style="box-sizing: border-box;height: 40px;padding: 10px 12px;border: 1px solid #ddd;border-radius: 4px;box-shadow: 0 1px 3px 0 #e6ebf1;"
                        @change="changePayableAmount()"
                        ></md-input>
                    </md-field>
                    <small class="text-danger" v-if="planDetail.identifier != 'PCHAT'"><b>If you want to add more than 1 device then buy in qty</b></small>
                    <div class="form-group row mb-2">
                          <div class="col-sm-12">
                              <div class="input-group w-auto">
                                  <input type="text" style="box-sizing: border-box;height: 40px;padding: 10px 12px;border: 1px solid #ddd;border-radius: 4px;box-shadow: 0 1px 3px 0 #e6ebf1;" v-model="coupon" class="form-control input-sm" placeholder="Enter Coupon Code" />
                                  <button type="button" :disabled="isDisabled" class="btn btn-dark input-sm" data-mdb-ripple-color="dark" @click="applyCoupon()">
                                      Apply
                                  </button>
                              </div>
                              <output class="badge badge-success" v-if="discount > 0">{{ coupon }}</output>
                          </div>
                      </div>
                    <div ref="card"></div>
                    
                    <div>
                        <vue-recaptcha ref="recaptcha" 
                            :sitekey="siteKey"
                            @verify="verifyCaptchaMethod"
                            >
                        </vue-recaptcha>
                    </div>
                    
                    <div style="float: right">
                        <md-button class="md-success" type="submit" :disabled="isDisabled" @click="makePayment()">Pay <span v-if="isDisabled"><i class="fa fa-spinner fa-spin"></i></span></md-button>
                    </div>
                </div>
                <div v-if="paymentSuccess">
                    <h3 class="text-center">Thank you for your payment, it’s processing</h3>
                    <!--<p class="text-center">Your order # is: 100000023</p>-->
                    <p class="text-center">You will receive an order confirmation email with details of your subscription.</p>
                    <div class="mb-3 text-center">
                        <button type="button" class="btn btn-success" @click="$router.push({ path: '/plans' })">Go Back</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import PaymentService from "../../../services/Customer/PaymentService";
import { VueRecaptcha } from 'vue-recaptcha';

const appearance = {
  theme: "night",
};
let stripe = Stripe(process.env.VUE_APP_STRIPE_PK),
  elements = stripe.elements(appearance),
  card = undefined;

export default {
  components: {
    VueRecaptcha
  },
  data() {
    return {
      isDisabled: false,
      planDetail: [],
      priceId: this.$route.query.id,
      total: 0,
      qty: (this.$route.query.qty) ? this.$route.query.qty : 1,
      deviceAfterPurchase: 0,
      paymentSuccess: false,
      email: "",
      coupon: "",
      discount: 0,
      siteKey: process.env.VUE_APP_NOCAPTCHA_SITEKEY,
      captcha: "",
    };
  },
  methods: {
      getPlanDetail() {
        let data = {
            id: this.priceId
        }
        PaymentService.getPlanDetail(data)
          .then((response) => {
            this.planDetail = response.data.data;
            if (this.planDetail.identifier == "PCHAT") {
                this.qty = 2
            }
            this.email = response.data.userDetail.email;
            this.total = this.planDetail.amount * this.qty;
            this.deviceAfterPurchase = this.planDetail.totalPurchasedDevices + this.qty;
            this.$toast.success(response.data.respones_msg);
          })
          .catch((e) => {
            this.$toast.error(e.response.data.respones_msg);
          });
      },
      changePayableAmount() {
        if (this.qty == 0) {
            this.qty = 1
        }
        this.total = this.planDetail.amount * this.qty
        this.deviceAfterPurchase = parseInt(this.planDetail.totalPurchasedDevices) + parseInt(this.qty);
      },

      makePayment() {
        if (this.planDetail.identifier == "PCHAT" && this.qty < 2) {
            this.$toast.error("Please purchase minimum 2 license for 2 users.");
            return;  
        }
        if (!this.captcha) {
            this.$toast.error("Please verify captcha.");
            return;  
        }
        
        let context = this;
        context.isDisabled = true;
        stripe.createToken(card).then(function (result) {
            if (result.error) {
                context.$refs.recaptcha.reset();
                self.hasCardErrors = true;
                context.isDisabled = false;
                //self.$forceUpdate(); // Forcing the DOM to update so the Stripe Element can update.
                $toast.error(result.error.message);
                return;
            }
            let token = result.token;
            stripe.createPaymentMethod({
                type: "card",
                card: card,
                billing_details: {
                name: context.email,
                email:context.email,
                },
            })
            .then(function (result) {
                let data = {
                    token: token,
                    qty: context.qty,
                    coupon_code: context.coupon_code,
                    email: context.email,
                    priceId: context.priceId,
                    payment_method_id: result.paymentMethod.id,
                    couponCode: context.coupon,
                    captcha: context.captcha,
                };

                PaymentService.makePayment(data)
                .then((response) => {
                    stripe
                    .confirmCardPayment(response.data.data, {
                        payment_method: {
                        card: card,
                        billing_details: {
                            name: context.email,
                        },
                        },
                    })
                    .then(function (result) {
                        if (result.error) {
                            context.$refs.recaptcha.reset();
                            context.isDisabled = false;
                            context.$toast.error(result.error.message);
                        } else {
                            context.isDisabled = false;
                            // The payment has been processed!
                            if (result.paymentIntent.status === "succeeded") {
                                //console.log(result);
                                context.$toast.success(response.data.respones_msg);
                                card.clear();
                                context.paymentSuccess = true;
                            }
                        }
                    });
                })
                .catch((e) => {
                    context.$refs.recaptcha.reset();
                    context.isDisabled = false;
                    if (e.response) {
                        context.$toast.error(e.response.data.respones_msg);
                    } else {
                        context.$toast.error(e);
                    }
                });
            });
      });
      },

      applyCoupon() {
          this.discount = 0;
          this.isDisabled = true;
          let data = { couponCode: this.coupon, priceId: this.priceId, qty: this.qty }
          PaymentService.applyCoupon(data)
          .then((response) => {
              this.isDisabled = false;
              this.discount = response.data.data;
              this.$toast.success(response.data.respones_msg);
          })
          .catch((e) => {
              this.isDisabled = false;
              this.$toast.error(e.response.data.respones_msg);
          });
      },
      verifyCaptchaMethod(recaptchaToken) {
        this.captcha = recaptchaToken;
      }
  },
  mounted: function () {
    if (!card) {
        card = elements.create("card");
        card.mount(this.$refs.card);
    } else {
        card.mount(this.$refs.card);
    }
  },
  beforeMount() {
    this.getPlanDetail();
  },
};
</script>

<style scoped>
.wrapper_1 {
  background-color:#527eee;
  padding: 50px;
}
.wrapper_2 {
  background-color:var(--md-theme-default-background, #fff);
  padding: 50px;
}
.float-right{
  float: right;
}
.color-white{
  color: var(--md-theme-default-background, #fff);
}
.color-black{
  color: black;
}
.coupon{
    border-radius: 1px
}

/*streip css*/
.StripeElement {
  box-sizing: border-box;
  height: 40px;
  padding: 10px 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: white;
  box-shadow: 0 1px 3px 0 #e6ebf1;
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
}

.StripeElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df;
}

.StripeElement--invalid {
  border-color: #fa755a;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}

/*stripe css end*/
</style>